/* eslint-disable react/require-default-props */

import React, { Children, useMemo } from "react";

interface ToolbarGroupProps {
  children: React.ReactNode;
  size?: "large" | "small";
  className?: string;
}

export const ToolbarContext = React.createContext<ToolbarGroupProps | null>(
  null
);

function Toolbar({ className, size, ...props }: ToolbarGroupProps) {
  const toolbarProps = useMemo(() => ({ ...props }), [props]);
  const arrayChildren = Children.toArray(props.children);
  return (
    <div className={className}>
      <ToolbarContext.Provider value={toolbarProps}>
        <div
          className={`toolbar-wrapper ${
            size === "large" ? "h-[65px]" : "h-[50px]"
          }`}
        >
          {arrayChildren.map((child, index) => (
            <div
              key={index}
              className="toolbar-child flex flex-row items-center h-full min-h-full"
            >
              {child}
              {index !== arrayChildren.length - 1 && (
                <div className="h-3/4 border-r border-gray-200 pl-2 mr-2" />
              )}
            </div>
          ))}
        </div>
      </ToolbarContext.Provider>
    </div>
  );
}

export default Toolbar;
