/* eslint-disable no-underscore-dangle */
import React from "react";
import { Simulation, TimestepDetails } from "@rodel-futures-simulator/types";
import { useParams } from "react-router-dom";
import { useGetSimulation, useGetSimulationDetails } from "../api/queries";
import LoadingPage from "../loadingPage/LoadingPage";
import ErrorPage from "../errorPage/ErrorPage";
import EconomicReport from "./components/EconomicReport";
import SimulationHistory from "./components/SimulationHistory";

function SimResultsPage({
  simDetails,
  timestepDetails,
}: {
  simDetails: Simulation;
  timestepDetails: TimestepDetails[];
}) {
  return (
    <div className="grid grid-cols-12 grid-rows-8 h-screen overflow-auto">
      <div className="col-span-3 row-span-8">
        <SimulationHistory simulationId={simDetails._id} />
      </div>
      <div className="flex flex-col p-8 col-span-9 row-span-7">
        <h1 className="capitalize">{simDetails.name} Simulation</h1>
        <p className="text-base-gray-100">Overview of the simulation results</p>
        <hr className="text-base-gray-80 my-1" />
        <EconomicReport timestepDetails={timestepDetails} />
      </div>
    </div>
  );
}

function SimResultsPageWrapper() {
  const { id: simulationId } = useParams();
  const {
    data: timestepDetails,
    isLoading: timestepDetailsLoading,
    isError: timestepDetailsError,
  } = useGetSimulationDetails(simulationId ?? "");

  const {
    data: simDetails,
    isLoading: simDetailsLoading,
    isError: simDetailsError,
  } = useGetSimulation(simulationId ?? "");

  if (timestepDetailsLoading || simDetailsLoading) {
    return <LoadingPage text="Generating results page..." />;
  }
  if (
    timestepDetailsError ||
    !timestepDetails ||
    simDetailsError ||
    !simDetails
  ) {
    return <ErrorPage />;
  }
  return (
    <SimResultsPage
      simDetails={simDetails.data}
      timestepDetails={timestepDetails.data}
    />
  );
}

export default SimResultsPageWrapper;
