/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { Budget, CreateSimReqBody } from "@rodel-futures-simulator/types";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  BookOpenIcon,
  BuildingLibraryIcon,
  // CurrencyDollarIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { useCreateSimulation } from "../api/mutations";
import { formatPercentage } from "../common/utils/formatUtility";
import { colors } from "../common/constants";
import EditBudget from "./components/EditBudget";
import Button from "../common/components/Button";
import Slider from "../common/components/Slider";
import Input from "../common/components/Input";
import ProgressBar from "../common/components/ProgressBar";
import DashboardCards from "./components/DashboardCards";

function SimulatorDashboard() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [step, setStep] = useState<
    "Name" | "Budget" | "Policy" | "Confirm" | undefined
  >();
  const [newSim, setNewSim] = useState<CreateSimReqBody>({
    name: "",
    budget: {
      categories: [
        {
          name: "Education",
          amountInMillions: 3994901518,
        },
        {
          name: "Public Safety",
          amountInMillions: 23320134105,
        },
        {
          name: "Health and Welfare",
          amountInMillions: 7300243049,
        },
        {
          name: "Business/Enterprise",
          amountInMillions: 838719404.4,
        },
        {
          name: "Natural Resources",
          amountInMillions: 601987349.7,
        },
        {
          name: "Government",
          amountInMillions: 22912259188,
        },
      ],
      totalInMillions: 58968244614.1,
    },
    policies: {
      salesTax: 0.056,
      // Schools offering dual enrollment initial value was calculated based off of 708 public schools and the information that ~200 do not offer DE
      schoolsOfferingDE: (708 - 200) / 708,
      studentsParticipationDE: 0.24,
      thirdGradLit: 0.41,
    },
  });
  const queryClient = useQueryClient();

  const mutation = useCreateSimulation({
    onMutate: async () => {
      setIsLoading(true);
      setMessage(undefined);
      await queryClient.invalidateQueries(["simulations"]);
    },
    onSuccess: async ({ data }) => {
      navigate(`/simulation/${data.simulationID}`);
    },
    onError: () => {
      setIsLoading(false);
      setMessage(
        "There was an error submitting your entries. Please try again."
      );
    },
  });

  function getDashboardContent() {
    switch (step) {
      case "Name":
        return (
          <div className="h-[540px] py-8 px-16">
            <div className="relative h-full">
              <h2>Start by naming your simulation</h2>
              <p className="text-base-gray-100 mb-4">
                Simulation name must be between four and twelve characters long.
              </p>
              <Input
                autoFocus
                value={newSim.name}
                placeholder="Simulation Name"
                className="my-3"
                onChange={(event) => {
                  setMessage(undefined);
                  setNewSim((prevSim) => ({
                    ...prevSim,
                    name: event.target.value,
                  }));
                }}
              />
              {message && <div className="text-base-red-100">{message}</div>}
              <div className="absolute bottom-0 right-0">
                <Button
                  color="secondary"
                  className="w-fit mr-2"
                  shadow={false}
                  onClick={() => {
                    setMessage(undefined);
                    setStep(undefined);
                  }}
                >
                  Back
                </Button>
                <Button
                  disabled={newSim.name === ""}
                  onClick={() => {
                    if (newSim.name.length < 4) {
                      setMessage("Name must be four or more characters long");
                    } else if (newSim.name.length > 12) {
                      setMessage(
                        "Name cannot be more than twelve characters long"
                      );
                    } else {
                      setMessage(undefined);
                      setStep("Budget");
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "Budget":
        return (
          <div className="h-[540px] py-8 px-16">
            <div className="relative h-full">
              <h2>Allocate the state budget</h2>
              <p className="text-base-gray-100 mb-4">
                Distribute your simulation budget across various categories. You
                can always adjust these numbers later.
              </p>
              <EditBudget
                budget={newSim.budget}
                handleSubmit={(budget: Budget) => {
                  setNewSim((prevSim) => ({ ...prevSim, budget }));
                  setStep("Policy");
                }}
                handleBack={() => setStep("Name")}
              />
            </div>
          </div>
        );
      case "Policy":
        return (
          <div className="h-[540px] py-8 px-16">
            <div className="relative h-full">
              <h2>Set your statewide adjustments</h2>
              <p className="text-base-gray-100 mb-4">
                Assign a value to the statewide values. These statewide values
                will have an impact on the outcome of your simulation.
              </p>
              <div className="flex flex-row mb-3">
                <div className="flex h-16 w-16 mr-2 p-4 rounded-full bg-base-gray-60">
                  <BuildingLibraryIcon
                    className="h-8 w-8 align-middle"
                    color="red"
                  />
                </div>
                <Slider
                  label="Public high schools offering dual enrollment (%)"
                  min={0}
                  max={1}
                  step={0.01}
                  value={newSim.policies.schoolsOfferingDE}
                  units="percent"
                  onChange={(val) =>
                    setNewSim((prevSim) => ({
                      ...prevSim,
                      policies: {
                        ...prevSim.policies,
                        schoolsOfferingDE: val,
                      },
                    }))
                  }
                />
              </div>
              <div className="flex flex-row mb-3">
                <div className="flex h-16 w-16 mr-2 p-4 rounded-full bg-base-gray-60">
                  <UsersIcon
                    className="h-8 w-8 align-middle"
                    color="dodgerblue"
                  />
                </div>
                <Slider
                  label="Public high school students participating in dual enrollment (%)"
                  min={0}
                  max={1}
                  step={0.01}
                  value={newSim.policies.studentsParticipationDE}
                  units="percent"
                  onChange={(val) =>
                    setNewSim((prevSim) => ({
                      ...prevSim,
                      policies: {
                        ...prevSim.policies,
                        studentsParticipationDE: val,
                      },
                    }))
                  }
                />
              </div>
              <div className="flex flex-row mb-3">
                <div className="flex h-16 w-16 mr-2 p-4 rounded-full bg-base-gray-60">
                  <BookOpenIcon color="gold" className="h-8 w-8 align-middle" />
                </div>
                <Slider
                  label="Third graders reading at a third grade level (%)"
                  min={0}
                  max={1}
                  step={0.01}
                  value={newSim.policies.thirdGradLit}
                  units="percent"
                  onChange={(val) =>
                    setNewSim((prevSim) => ({
                      ...prevSim,
                      policies: {
                        ...prevSim.policies,
                        thirdGradLit: val,
                      },
                    }))
                  }
                />
              </div>
              <div className="absolute bottom-0 right-0">
                <Button
                  color="secondary"
                  className="w-fit mr-2"
                  shadow={false}
                  onClick={() => setStep("Budget")}
                >
                  Back
                </Button>
                <Button onClick={() => setStep("Confirm")}>Next</Button>
              </div>
            </div>
          </div>
        );
      case "Confirm":
        return (
          <div className="h-[540px] py-8 px-16">
            <div className="relative h-full">
              <h2 className="mb-4">Your simulation is almost ready!</h2>
              <div>
                <h2 className="capitalize text-lg">{newSim.name} Simulation</h2>
              </div>
              <div className="grid grid-cols-8 mt-3">
                <div className="col-span-4">
                  <div className="text-base-gray-100 mb-3">
                    Statewide Adjustments
                  </div>
                  <div className="flex flex-row mb-3">
                    <div className="flex h-10 w-10 mr-2 p-2 rounded-full bg-base-gray-60">
                      <BuildingLibraryIcon
                        color="red"
                        className="h-6 w-6 align-middle"
                      />
                    </div>
                    <div className="flex flex-col">
                      <p>High schools offering DE (%)</p>
                      <p className="text-lg font-bold">
                        {formatPercentage(newSim.policies.schoolsOfferingDE, 0)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-3">
                    <div className="flex h-10 w-10 mr-2 p-2 rounded-full bg-base-gray-60">
                      <UsersIcon
                        color="dodgerblue"
                        className="h-6 w-6 align-middle"
                      />
                    </div>
                    <div className="flex flex-col">
                      <p>High school students participating in DE (%)</p>
                      <p className="text-lg font-bold">
                        {formatPercentage(
                          newSim.policies.studentsParticipationDE,
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-3">
                    <div className="flex h-10 w-10 mr-2 p-2 rounded-full bg-base-gray-60">
                      <BookOpenIcon
                        color="gold"
                        className="h-6 w-6 align-middle"
                      />
                    </div>
                    <div className="flex flex-col">
                      <p>Third grader literacy (%)</p>
                      <p className="text-lg font-bold">
                        {formatPercentage(newSim.policies.thirdGradLit, 0)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 ml-3">
                  <div className="text-base-gray-100 mb-3">State Budget</div>
                  {newSim.budget.categories.map((category, index) => (
                    <div
                      key={category.name}
                      className="flex flex place-content-between pb-3"
                    >
                      <div className="flex items-center space-x-2">
                        <div
                          className="h-3 w-3 rounded-full"
                          style={{
                            backgroundColor: colors[index % colors.length],
                          }}
                        />
                        <p>{category.name}</p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <p className="text-gray-500 flex justify-end ml-10">
                          {formatPercentage(
                            category.amountInMillions /
                              newSim.budget.totalInMillions!,
                            1
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="flex flex place-content-between pb-3">
                    <div className="flex items-center space-x-2">
                      <p>Total</p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="text-gray-500 flex justify-end ml-10">
                        {formatPercentage(1, 1)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {message && <div className="text-base-red-100">{message}</div>}
              <div className="absolute bottom-0 right-0">
                <Button
                  color="secondary"
                  className="w-fit mr-2"
                  shadow={false}
                  onClick={() => {
                    setMessage(undefined);
                    setStep("Policy");
                  }}
                >
                  Back
                </Button>
                <Button
                  loading={isLoading}
                  onClick={() => {
                    mutation.mutate(newSim);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        );
      default:
        return <DashboardCards handleCreateSim={() => setStep("Name")} />;
    }
  }

  return (
    <div className="gradient w-screen h-screen grid grid-rows-4">
      <div className="w-[680px] mx-auto self-end">
        <h1 className="text-[56px] text-white">Rodel Futures Simulator</h1>
        <h2 className="text-white font-medium mb-4">
          Your gateway to the simulation of Arizona's future
        </h2>
      </div>
      <div>
        <div className="w-[680px] h-fit row-start-2 mx-auto bg-base-white rounded-lg shadow-lg">
          {getDashboardContent()}
        </div>
        {step && (
          <ProgressBar
            color="white"
            backgroundColor="#94B8B6"
            className="mt-14 w-[388px] h-20"
            currentStep={step}
            steps={[
              {
                name: "Name",
                percent: 0,
              },
              {
                name: "Budget",
                percent: 0.333,
              },
              {
                name: "Policy",
                percent: 0.667,
              },
              {
                name: "Confirm",
                percent: 1,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}

export default SimulatorDashboard;
