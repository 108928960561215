import React, { useMemo, useState } from "react";
import { Budget } from "@rodel-futures-simulator/types";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { formatPercentage } from "../../common/utils/formatUtility";
import { colors } from "../../common/constants";
import DoughnutChart from "../../common/components/DoughnutChart";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";

export default function EditBudget({
  budget,
  handleSubmit,
  handleBack,
}: {
  budget: Budget;
  handleSubmit: (budget: Budget) => void;
  handleBack: () => void;
}) {
  const defaultPercentages: number[] = useMemo(
    () =>
      budget.categories.map(
        (category) => category.amountInMillions / budget.totalInMillions!
      ),
    [budget.categories, budget.totalInMillions]
  );

  const [percentages, setPercentages] = useState(defaultPercentages);
  const [totalAllocPercentage, setTotalAllocPercentage] = useState(1);
  const [message, setMessage] = useState("");

  const budgetData = useMemo(() => {
    let d = budget.categories.map(({ name }, index) => ({
      label: name,
      value: percentages[index] * budget.totalInMillions!,
      color: colors[index % colors.length],
    }));
    if (parseFloat((totalAllocPercentage * 100).toFixed(1)) < 100.0) {
      d.push({
        label: "Not allocated correctly",
        value: Math.abs(totalAllocPercentage - 1) * budget.totalInMillions!,
        color: "#F1363E",
      });
    } else if (parseFloat((totalAllocPercentage * 100).toFixed(1)) > 100.0) {
      d = [
        {
          label: "Not allocated correctly",
          value: budget.totalInMillions!,
          color: "#CFCFCF",
        },
      ];
    }

    return d;
  }, [
    budget.categories,
    budget.totalInMillions,
    percentages,
    totalAllocPercentage,
  ]);

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Remove the percent sign and convert string to float
    let newValue = parseFloat(e.target.value.replace(/%$/, "")) / 100;

    if (Number.isNaN(newValue) || newValue < 0) {
      newValue = 0;
      e.target.value = formatPercentage(0, 1);
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length - 1;
    }

    const updatedPercentages = percentages.map((category, i) =>
      i === index ? newValue : category
    );

    const sum = updatedPercentages.reduce((acc, val) => acc + val, 0);

    if (parseFloat((sum * 100).toFixed(1)) > 100.0) {
      setMessage(" You may not exceed 100% of your budget");
    } else if (parseFloat((sum * 100).toFixed(1)) < 100.0) {
      setMessage(" You must allocate 100% of your budget");
    } else {
      setMessage("");
    }
    setPercentages(updatedPercentages);
    setTotalAllocPercentage(sum);
  };

  return (
    <>
      <div className="flex flex-row place-content-between">
        <div className="w-full mr-8 space-y-2">
          {budget.categories.map((category, index) => {
            return (
              <div key={category.name} className="flex justify-between">
                <div className="flex items-center space-x-3">
                  <div
                    className="h-3 w-3 rounded-full"
                    style={{
                      backgroundColor: colors[index % colors.length],
                    }}
                  />
                  <p className="text-sm text-gray-500 font-medium">
                    {category.name}
                  </p>
                </div>
                <div className="flex items-center space-x-3">
                  <Input
                    type="text"
                    className="w-[60px] text-sm text-gray-500"
                    defaultValue={formatPercentage(percentages[index], 1)}
                    onChange={(e) => handleInputChange(index, e)}
                    onBlur={(e) => {
                      const newValue =
                        parseFloat(e.target.value.replace(/%$/, "")) / 100;
                      e.target.value = formatPercentage(newValue, 1);
                    }}
                  />
                </div>
              </div>
            );
          })}
          {message && (
            <p className="text-left text-sm font-light text-red-500">
              <ExclamationCircleIcon className="inline-block h-4 w-4 mr-1" />
              {message}
            </p>
          )}
        </div>
        <DoughnutChart
          data={budgetData}
          label="Allocated Budget"
          centerValue={formatPercentage(totalAllocPercentage, 1)}
          animate={false}
        />
      </div>
      <div className="absolute bottom-0 right-0">
        <Button
          color="secondary"
          className="w-fit mr-2"
          shadow={false}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            const newBudget: Budget = {
              categories: budgetData.map((category, i) => ({
                name: category.label,
                amountInMillions: budget.totalInMillions! * percentages[i],
              })),
              totalInMillions: budget.totalInMillions!,
            };
            handleSubmit(newBudget);
          }}
          disabled={
            parseFloat((totalAllocPercentage * 100).toFixed(1)) !== 100.0
          }
        >
          Next
        </Button>
      </div>
    </>
  );
}
