import React, { useContext } from "react";
import SimulatorContext from "../../context/SimulatorContext";

import {
  INIT_AZIMUTHAL_ANGLE,
  INIT_POLAR_ANGLE,
} from "../../../common/constants";

function Compass() {
  const { compassRef, mapControlsRef } = useContext(SimulatorContext);

  const handleOnClick = () => {
    if (mapControlsRef) {
      mapControlsRef.current.setAzimuthalAngle(INIT_AZIMUTHAL_ANGLE);
      mapControlsRef.current.setPolarAngle(INIT_POLAR_ANGLE);
      mapControlsRef.current.update();
    }
  };

  return (
    <button
      ref={compassRef}
      onClick={handleOnClick}
      className="mx-0 my-0 w-[50px] h-[50px] text-base-black bg-white text-base flex flex-col justify-center items-center rounded-full"
      type="button"
    >
      <div className="flex h-12 w-12">
        <img src="/images/Compass.svg" alt="Compass" />
      </div>
    </button>
  );
}

export default Compass;
