/* eslint-disable react/no-unknown-property */
import React from "react";
import * as THREE from "three";
import { SCALE } from "../../../common/constants";

function Ground() {
  return (
    <mesh name="ground" position={[0, -0.1, 0]} rotation={[Math.PI / 2, 0, 0]}>
      <planeBufferGeometry args={[1800 * SCALE, 1800 * SCALE]} />
      <meshBasicMaterial color="#f5e6c9" side={THREE.DoubleSide} />
    </mesh>
  );
}

export default Ground;
