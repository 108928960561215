import { useThree } from "@react-three/fiber";
import { GridItemSize } from "@rodel-futures-simulator/types";
import { useEffect } from "react";
import * as THREE from "three";
import { transformGridToThreeCoords } from "../utils/transformCoords";

const border = new THREE.Group();

const selectGridItemAt = ({
  pos,
  size,
  thickness = 0.15,
}: {
  pos: [number, number];
  size: GridItemSize;
  thickness?: number;
}) => {
  border.clear();

  let itemSideLength;
  switch (size) {
    case GridItemSize.QUARTER:
      [itemSideLength] = transformGridToThreeCoords([1, 0, 0]);
      break;

    case GridItemSize.HALF:
      [itemSideLength] = transformGridToThreeCoords([2, 0, 0]);
      break;

    case GridItemSize.FULL:
      [itemSideLength] = transformGridToThreeCoords([4, 0, 0]);
      break;

    default:
      [itemSideLength] = transformGridToThreeCoords([1, 0, 0]);
      break;
  }

  const geometry1 = new THREE.BoxGeometry(
    itemSideLength + thickness,
    0.01,
    thickness
  );
  const geometry2 = new THREE.BoxGeometry(thickness, 0.01, itemSideLength);

  const borderMaterial = new THREE.MeshBasicMaterial({
    color: "#ffbc03",
  });

  const border1 = new THREE.Mesh(geometry1, borderMaterial);
  const border2 = new THREE.Mesh(geometry1, borderMaterial);
  const border3 = new THREE.Mesh(geometry2, borderMaterial);
  const border4 = new THREE.Mesh(geometry2, borderMaterial);

  border1.position.set(0 + itemSideLength / 2, 0, 0);
  border2.position.set(0 + itemSideLength / 2, 0, 0 + itemSideLength);
  border3.position.set(0, 0, 0 + +itemSideLength / 2);
  border4.position.set(0 + itemSideLength, 0, 0 + itemSideLength / 2);

  border.add(border1);
  border.add(border2);
  border.add(border3);
  border.add(border4);

  const [x, y, z] = transformGridToThreeCoords([pos[0], 0, pos[1]]);

  border.visible = true;
  border.position.set(x, y, z);
};

const removeGridItemSelection = () => {
  border.clear();
  border.visible = false;
};

const useGridItemSelection = (): [
  ({
    pos,
    size,
    thickness,
  }: {
    pos: [number, number];
    size: GridItemSize;
    thickness?: number | undefined;
  }) => void,
  () => void
] => {
  const { scene } = useThree();

  useEffect(() => {
    border.visible = false;
    scene.add(border);
    return () => {
      scene.remove(border);
    };
  }, [scene]);

  return [selectGridItemAt, removeGridItemSelection];
};

export default useGridItemSelection;
