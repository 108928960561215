import { useMemo } from "react";
import { ISimulatorContext } from "../../common/types/types";

const useMemoContext = ({
  loadingMap,
  mapState,
  counties,
  mapControlsRef,
  playing,
  play,
  stop,
  reset,
  speed,
  setSpeed,
  // networkPause,
  simulationId,
  simStatus,
  name,
  setTimestep,
  // setTimestepRangeMin,
  finalTimestep,
  buildItem,
  setBuildItem,
  interventions,
  setInterventions,
  gridItemModal,
  setGridItemModal,
  compassRef,
  selectRef,
  selectedLayer,
  setSelectedLayer,
  autoRotation,
  setAutoRotation,
  cameraType,
  setCameraType,
  loadingScene,
  setLoadingScene,
}: ISimulatorContext) => {
  const context: ISimulatorContext = useMemo(() => {
    return {
      loadingMap,
      mapState,
      counties,
      mapControlsRef,
      playing,
      play,
      stop,
      reset,
      speed,
      setSpeed,
      // networkPause,
      name,
      simulationId,
      simStatus,
      setTimestep,
      // setTimestepRangeMin,
      finalTimestep,
      buildItem,
      setBuildItem,
      interventions,
      setInterventions,
      gridItemModal,
      setGridItemModal,
      compassRef,
      selectRef,
      selectedLayer,
      setSelectedLayer,
      autoRotation,
      setAutoRotation,
      cameraType,
      setCameraType,
      loadingScene,
      setLoadingScene,
    };
  }, [
    loadingMap,
    mapState,
    counties,
    mapControlsRef,
    playing,
    play,
    stop,
    reset,
    speed,
    setSpeed,
    // networkPause,
    simulationId,
    simStatus,
    name,
    setTimestep,
    // setTimestepRangeMin,
    finalTimestep,
    buildItem,
    setBuildItem,
    interventions,
    setInterventions,
    gridItemModal,
    setGridItemModal,
    compassRef,
    selectedLayer,
    setSelectedLayer,
    selectRef,
    autoRotation,
    setAutoRotation,
    cameraType,
    setCameraType,
    loadingScene,
    setLoadingScene,
  ]);
  return context;
};

export default useMemoContext;
