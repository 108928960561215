import {
  ClientRes,
  TimestepRangeReqBody,
  CreateSimReqBody,
  Timestep,
  Simulation,
  County,
  GridItem,
  TimestepDetails,
  ModalDetailsRes,
  GridItemType,
  UpsertGridItemReqBody,
  GridItemReqQuery,
  BudgetUpdateReqBody,
  InterventionReqBody,
  ApiRes,
  ResMessage,
} from "@rodel-futures-simulator/types";

const defaultHeaders = {
  "Content-Type": "application/json",
};

async function get<T>(url: string): Promise<T> {
  const response = await fetch(url, {
    headers: defaultHeaders,
  });
  if (response.ok) {
    return response.json() as Promise<T>;
  }
  throw Error(`Error fetching ${url}`);
}

export async function post<T, U>(url: string, body: T): Promise<U> {
  const response = await fetch(url, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(body),
  });
  if (response.ok) {
    return response.json() as Promise<U>;
  }
  throw Error(`Error fetching ${url}`);
}

export async function put<T, U>(url: string, body: T): Promise<U> {
  const response = await fetch(url, {
    method: "PUT",
    headers: defaultHeaders,
    body: JSON.stringify(body),
  });
  if (response.ok) {
    return response.json() as Promise<U>;
  }
  throw Error(`Error fetching ${url}`);
}

export async function getSimulations() {
  return get<ClientRes<Simulation[]>>("/api/simulation");
}

export async function getSimulation(id: string) {
  return get<ClientRes<Simulation>>(`/api/simulation/${id}`);
}

export async function createSimulation(body: CreateSimReqBody) {
  return post<
    CreateSimReqBody,
    ClientRes<{ simulationID: string; success: boolean }>
  >("/api/simulation", body);
}

export async function getTimestepRange(body: TimestepRangeReqBody) {
  return post<TimestepRangeReqBody, ClientRes<Timestep[]>>(
    "/api/timestep/range",
    body
  );
}

export async function getSimulationDetails(simId: string) {
  return get<ClientRes<TimestepDetails[]>>(`/api/simulation/details/${simId}`);
}

export async function getGridItem(
  query: GridItemReqQuery,
  gridItemType: GridItemType
) {
  let urlGridItemType = "school";

  const { simulationId, xCoord, yCoord } = query;

  switch (gridItemType) {
    case GridItemType.SCHOOL:
      urlGridItemType = "school";
      break;
    case GridItemType.BUSINESS:
      urlGridItemType = "business";
      break;
    case GridItemType.NEIGHBORHOOD:
      urlGridItemType = "neighborhood";
      break;
    case GridItemType.POWER_PLANT:
      urlGridItemType = "powerPlant";
      break;
    default:
      throw new Error("invalid grid item type");
  }
  let url = `/api/timestep/${urlGridItemType}?simulationId=${simulationId}&xCoord=${xCoord}&yCoord=${yCoord}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (query.name) url += `&name=${query.name}`;

  return get<ClientRes<ModalDetailsRes<GridItem>[]>>(url);
}

export async function upsertGridItem(
  action: string,
  body: UpsertGridItemReqBody,
  update: boolean
) {
  let response;
  if (update) {
    response = put<UpsertGridItemReqBody, ClientRes<Timestep[]>>(
      `/api/timestep/${action}`,
      body
    );
  } else {
    response = post<UpsertGridItemReqBody, ClientRes<Timestep[]>>(
      `/api/timestep/${action}`,
      body
    );
  }
  return response;
}

export async function postInvervention(body: InterventionReqBody) {
  return post<InterventionReqBody, ApiRes<string>>(
    "/api/timestep/intervention",
    body
  );
}

export async function updateBudget(body: BudgetUpdateReqBody) {
  return put<BudgetUpdateReqBody, ApiRes<ResMessage>>(
    "/api/timestep/budget",
    body
  );
}

export async function getCounties() {
  return get<ClientRes<County[]>>("/api/counties");
}
