/* eslint-disable react/require-default-props */
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import React, { useState, useMemo, useContext } from "react";
import { useGetSimulationDetails } from "../../../api/queries";
import Button from "../../../common/components/Button";
import {
  formatDollarValue,
  formatNumber,
  formatPercentage,
} from "../../../common/utils/formatUtility";
import SimulatorContext from "../../context/SimulatorContext";
import Toolbar from "../../../common/components/Toolbar";
import METRICS from "../../../common/constants/MetricConstants";

type TopLevelMetricProps = {
  title: string;
  icon: React.ReactNode;
  value: string;
  units?: string;
};

function TopLevelMetric({ title, icon, value, units }: TopLevelMetricProps) {
  return (
    <div className="flex flex-col justify-start px-2 py-2">
      <div className="flex flex-row space-x-2 text-sm items-center">
        <span className="text-base-black">{icon}</span>
        <div>{title}</div>
      </div>
      <div className="text-base font-semibold">
        {value} {units ?? units}
      </div>
    </div>
  );
}

function TopLevelMetrics() {
  const { mapState, simulationId } = useContext(SimulatorContext);
  const { data } = useGetSimulationDetails(simulationId);
  const [metricsIndex, setMetricsIndex] = useState(0);

  const metrics = useMemo(() => {
    const timestep = data?.data[mapState.step];
    return [
      [
        {
          ...METRICS.State.population,
          value: timestep?.annualPopulation
            ? formatNumber(Math.round(timestep.annualPopulation))
            : "-",
        },
        {
          ...METRICS.Schools.numSchools,
          value: timestep?.totalNumSchools
            ? formatNumber(timestep.totalNumSchools)
            : "-",
        },
        {
          ...METRICS.Schools.numStudents,
          value: timestep?.totalNumStudents
            ? formatNumber(timestep.totalNumStudents)
            : "-",
        },
        {
          ...METRICS.Schools.gradRate,
          value: timestep?.annualGradRate
            ? formatPercentage(timestep.annualGradRate / 100)
            : "-",
        },
      ],
      [
        {
          ...METRICS.State.budget,
          value: `${formatDollarValue(
            mapState.budget?.totalInMillions
              ? mapState.budget.totalInMillions / 1000000
              : 0
          )}`,
        },
        {
          ...METRICS.State.newBusinesses,
          value: "-",
        },
        {
          ...METRICS.State.migratedBusinesses,
          value: "-",
        },
      ],
      [
        {
          ...METRICS.Resources.powerGen,
          value: "-",
        },
        {
          ...METRICS.Resources.powerUsage,
          value: "-",
        },
        {
          ...METRICS.Resources.waterUsage,
          value: "-",
        },
        {
          ...METRICS.Resources.waterProcessed,
          value: "-",
        },
      ],
      [
        {
          ...METRICS.Sustainability.emissions,
          value: timestep?.totalEmissions
            ? formatNumber(timestep.totalEmissions)
            : "-",
        },
        {
          ...METRICS.Sustainability.vehicleMilesTraveled,
          value: "-",
        },
        {
          ...METRICS.Sustainability.elecVehicles,
          value: "-",
        },
        {
          ...METRICS.Sustainability.solarHomes,
          value: "-",
        },
      ],
    ];
  }, [data, mapState]);

  function onMetrcisCycle(count: number) {
    setMetricsIndex((index) => (index === count - 1 ? 0 : index + 1));
  }

  return (
    <Toolbar className="ml-auto" size="large">
      {metrics[metricsIndex].map((metric) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TopLevelMetric key={`Metric-${metric.title}`} {...metric} />
      ))}
      <Button
        color="secondary"
        icon={
          <ArrowsRightLeftIcon
            className="w-4 h-4"
            onClick={() => onMetrcisCycle(metrics.length)}
          />
        }
      />
    </Toolbar>
  );
}

export default TopLevelMetrics;
