import React from "react";
import Skeleton from "../../common/components/Skeleton";

function LoadingSimTable() {
  return (
    <div className="w-11/12 mx-auto px-12 py-8 h-[468px]">
      <Skeleton rows={1} size="large" />
      <Skeleton rows={1} size="small" />
      <div className="w-11/12 mx-auto">
        <div className="w-full pb-4">
          <div className="flex w-full justify-between bg-light-gray px-4 py-2">
            <Skeleton rows={1} size="large" />
          </div>
        </div>
        <div className="w-full pb-4">
          <div className="flex w-full justify-between bg-light-gray px-4 py-2">
            <Skeleton rows={1} size="large" />
          </div>
        </div>
        <div className="w-full pb-4">
          <div className="flex w-full justify-between bg-light-gray px-4 py-2">
            <Skeleton rows={1} size="large" />
          </div>
        </div>
        <div className="w-full pb-4">
          <div className="flex w-full justify-between bg-light-gray px-4 py-2">
            <Skeleton rows={1} size="large" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingSimTable;
