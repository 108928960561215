/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useContext, useState } from "react";
import { GridItemSize, GridItemType } from "@rodel-futures-simulator/types";
import {
  AcademicCapIcon,
  BoltIcon,
  BuildingStorefrontIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { formatDollarValue } from "../../../common/utils/formatUtility";
import { calcAvailableFunds, getBuildAmount } from "../../utils/investUtils";
import SimulatorContext from "../../context/SimulatorContext";
import TabGroup from "../../../common/components/TabGroup";
import Select from "../../../common/components/Select";

export default function InterventionModal() {
  const { interventions } = useContext(SimulatorContext);

  const [selectedInvestmentType, setSelectedInvestmentType] = useState<
    string | null
  >(null);

  const [selectedBuildType, setSelectedBuildType] = useState<string | null>(
    null
  );

  const remainingFunds = calcAvailableFunds(interventions);
  const totalSpent = 1000000000 - remainingFunds;

  const buildMade = interventions.builds.length > 0;

  const investMade =
    interventions.investments.length + interventions.schoolInvestments.length >
    0;

  const totalSchoolSpent =
    interventions.schoolInvestments.reduce(
      (acc, investment) => acc + investment.amount,
      0
    ) +
    interventions.builds.reduce((accumulator, build) => {
      if (build.type === GridItemType.SCHOOL) {
        return accumulator + getBuildAmount(build.type, build.size);
      }
      return accumulator;
    }, 0);

  const totalPowerPlant =
    interventions.investments.reduce((accumulator, investment) => {
      if (investment.type === GridItemType.POWER_PLANT) {
        return accumulator + investment.amount;
      }
      return accumulator;
    }, 0) +
    interventions.builds.reduce((accumulator, build) => {
      if (build.type === GridItemType.POWER_PLANT) {
        return accumulator + getBuildAmount(build.type, build.size);
      }
      return accumulator;
    }, 0);

  const totalBusiness =
    interventions.investments.reduce((accumulator, investment) => {
      if (investment.type === GridItemType.BUSINESS) {
        return accumulator + investment.amount;
      }
      return accumulator;
    }, 0) +
    interventions.builds.reduce((accumulator, build) => {
      if (build.type === GridItemType.BUSINESS) {
        return accumulator + getBuildAmount(build.type, build.size);
      }
      return accumulator;
    }, 0);

  const totalNeighborhood =
    interventions.investments.reduce((accumulator, investment) => {
      if (investment.type === GridItemType.NEIGHBORHOOD) {
        return accumulator + investment.amount;
      }
      return accumulator;
    }, 0) +
    interventions.builds.reduce((accumulator, build) => {
      if (build.type === GridItemType.NEIGHBORHOOD) {
        return accumulator + getBuildAmount(build.type, build.size);
      }
      return accumulator;
    }, 0);

  const getSizeLabel = (size: GridItemSize) => {
    switch (size) {
      case "025":
        return "Small";
      case "050":
        return "Medium";
      case "100":
        return "Large";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="h-full">
      <div className="text-base text-black font-bold mb-2">
        Total Spent on Interventions
      </div>
      <div className="text-2xl text-base-rodel-100 mb-2">
        {formatDollarValue(totalSpent, false)}
      </div>
      <div className="text-base text-gray-500 mb-4">
        with {formatDollarValue(remainingFunds, false)} left to spend
      </div>
      <TabGroup tabs={["Overview", "Investments", "Builds"]}>
        <TabGroup.Panel>
          <div className="h-[390px]">
            <div className="border border-gray-300 rounded px-4">
              <div className="flex flex place-content-between pb-3 border-b border-gray-300 my-2">
                <div className="flex items-center space-x-2 my-2">
                  <AcademicCapIcon className="w-4 h-4" color="red" />
                  <p>School</p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="flex justify-end ml-10">
                    {formatDollarValue(totalSchoolSpent, false)}
                  </p>
                </div>
              </div>
              <div className="flex flex place-content-between pb-3 border-b border-gray-300 my-2">
                <div className="flex items-center space-x-2 my-2">
                  <BoltIcon className="w-4 h-4" color="gold" />
                  <p>Power Plant</p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="flex justify-end ml-10">
                    {formatDollarValue(totalPowerPlant, false)}
                  </p>
                </div>
              </div>
              <div className="flex flex place-content-between pb-3 border-b border-gray-300 my-2">
                <div className="flex items-center space-x-2 my-2">
                  <BuildingStorefrontIcon className="w-4 h-4" color="green" />
                  <p>Business</p>
                </div>
                <div className="flex items-center space-x-2 my-2">
                  <p className="flex justify-end ml-10">
                    {formatDollarValue(totalBusiness, false)}
                  </p>
                </div>
              </div>
              <div className="flex flex place-content-between my-2">
                <div className="flex items-center space-x-2 my-2">
                  <HomeIcon className="w-4 h-4" color="red" />
                  <p>Neighborhood</p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="flex justify-end ml-10">
                    {formatDollarValue(totalNeighborhood, false)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </TabGroup.Panel>
        <TabGroup.Panel>
          <div className="h-[390px] overflow-auto border border-gray-300 rounded px-4">
            <div className="flex flex place-content-between pb-3 border-gray-300 mb-4 mt-4">
              <div className="flex items-center space-x-2">
                <Select
                  placeholder="All"
                  className="w-[100px]"
                  options={[
                    { value: null, label: "All" },
                    { value: GridItemType.SCHOOL, label: "School" },
                    { value: GridItemType.BUSINESS, label: "Business" },
                    { value: GridItemType.NEIGHBORHOOD, label: "Neighborhood" },
                    { value: GridItemType.POWER_PLANT, label: "Power Plant" },
                  ]}
                  onChange={(event) => {
                    setSelectedInvestmentType(event.value);
                  }}
                  value={
                    selectedInvestmentType !== null
                      ? {
                          value: selectedInvestmentType,
                          label: selectedInvestmentType,
                        }
                      : { value: null, label: "All" }
                  }
                />
              </div>
            </div>
            {interventions.investments
              .filter((investment) =>
                selectedInvestmentType === null
                  ? true
                  : investment.type === selectedInvestmentType
              )
              .map((investment, index) => (
                <div
                  key={index}
                  className="flex justify-between pb-3 border-b border-gray-300 mb-4"
                >
                  <div className="flex items-center space-x-2 my-2">
                    <p>
                      {getSizeLabel(investment.size)} {investment.type}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="flex justify-end ml-10">
                      {formatDollarValue(investment.amount, false)}
                    </p>
                  </div>
                </div>
              ))}
            {interventions.schoolInvestments
              .filter((schoolInvestment) =>
                selectedInvestmentType === null
                  ? true
                  : schoolInvestment.type === selectedInvestmentType
              )
              .map((schoolInvestment, index) => (
                <div
                  key={index}
                  className="flex justify-between pb-3 border-b border-gray-300 mb-4"
                >
                  <div className="flex flex-col my-2">
                    <div className="flex items-center space-x-2">
                      <p>{schoolInvestment.name}</p>
                    </div>
                    <div className="flex items-center text-xs text-gray-500 space-x-2">
                      <p>
                        {getSizeLabel(schoolInvestment.size)}{" "}
                        {schoolInvestment.type}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="flex justify-end ml-10">
                      {formatDollarValue(schoolInvestment.amount, false)}
                    </p>
                  </div>
                </div>
              ))}

            {investMade ? (
              <div className="flex justify-between pb-3 border-gray-300 mb-4">
                <div className="flex items-center text-gray-500 space-x-2 my-2">
                  <p>Total Spent:</p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="text-green-600 flex justify-end ml-10">
                    {formatDollarValue(
                      [
                        ...interventions.investments,
                        ...interventions.schoolInvestments,
                      ].reduce(
                        (total, investment) => total + investment.amount,
                        0
                      ),
                      false
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="text-base text-black font-bold text-center">
                You have not made any investments yet.
              </div>
            )}
          </div>
        </TabGroup.Panel>

        <TabGroup.Panel>
          <div className="h-[390px] overflow-auto border border-gray-300 rounded px-4">
            <div className="flex flex place-content-between pb-3 border-gray-300 mb-4 mt-4">
              <div className="flex items-center space-x-2">
                <Select
                  placeholder="All"
                  className="w-[100px]"
                  options={[
                    { value: null, label: "All" },
                    { value: GridItemType.SCHOOL, label: "School" },
                    { value: GridItemType.BUSINESS, label: "Business" },
                    { value: GridItemType.NEIGHBORHOOD, label: "Neighborhood" },
                    { value: GridItemType.POWER_PLANT, label: "Power Plant" },
                  ]}
                  onChange={(event) => {
                    setSelectedBuildType(event.value);
                  }}
                  value={
                    selectedBuildType !== null
                      ? {
                          value: selectedBuildType,
                          label: selectedBuildType,
                        }
                      : { value: null, label: "All" }
                  }
                />
              </div>
            </div>
            {interventions.builds
              .filter((build) =>
                selectedBuildType === null
                  ? true
                  : build.type === selectedBuildType
              )
              .map((build, index) => (
                <div
                  key={index}
                  className="flex justify-between pb-3 border-b border-gray-300 mb-4"
                >
                  <div className="flex items-center space-x-2 my-2">
                    <p>
                      {getSizeLabel(build.size)} {build.type}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="flex justify-end ml-10">
                      {formatDollarValue(
                        getBuildAmount(build.type, build.size),
                        false
                      )}
                    </p>
                  </div>
                </div>
              ))}

            {buildMade ? (
              <div className="flex justify-between pb-3 border-gray-300 mb-4">
                <div className="flex items-center text-gray-500 space-x-2 my-2">
                  <p>Total Spent:</p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="text-green-600 flex justify-end ml-10">
                    {formatDollarValue(
                      interventions.builds.reduce(
                        (total, build) =>
                          total + getBuildAmount(build.type, build.size),
                        0
                      ),
                      false
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="text-base text-black font-bold text-center">
                You have not made any builds yet.
              </div>
            )}
          </div>
        </TabGroup.Panel>
      </TabGroup>
    </div>
  );
}
