/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";

interface TooltipProps {
  icon: React.ReactNode;
  text: string;
  position?: "top" | "bottom" | "right" | "left";
  isActive?: boolean;
  onClick?: () => void;
}

export default function Tooltip({
  icon,
  text,
  position = "top",
  isActive,
  onClick,
}: TooltipProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex justify-center relative">
      <button
        type="button"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={isActive ? "text-base-blue-100" : "hover:text-base-gray-100"}
        onClick={onClick || (() => {})}
      >
        {icon}
      </button>
      {isOpen && (
        <>
          <div
            className={`absolute ${
              position === "top"
                ? "-top-5 w-max"
                : position === "bottom"
                ? "-bottom-5 w-max"
                : position === "right"
                ? "left-8 w-24"
                : "right-8 w-24"
            } text-center text-white font-normal w-max z-10 bg-gray-800 rounded-sm text-xs px-1 py-0.5`}
          >
            {text}
          </div>
          <div
            className={`absolute ${
              position === "top"
                ? "-top-2"
                : position === "bottom"
                ? "-bottom-2"
                : position === "right"
                ? "left-[30px] top-3"
                : "right-[30px] top-3"
            } origin-center rotate-45 w-2.5 h-2.5 bg-gray-800`}
          />
        </>
      )}
    </div>
  );
}
