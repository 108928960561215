/* eslint-disable react/no-unknown-property */
import { Coords } from "@rodel-futures-simulator/types";
import React, { useRef, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Extrude, Text } from "@react-three/drei";
import { transformGridToThreeCoords } from "../../utils/transformCoords";

interface CityProps {
  name: string;
  borderCoords: Coords[];
  centerCoords: Coords;
}

const extrudeSettings = { steps: 1, depth: 4, bevelEnabled: false };

function City({ name, borderCoords, centerCoords }: CityProps) {
  const textMeshRef = useRef<THREE.Mesh>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const textMaterialRef = useRef<THREE.MeshStandardMaterial>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const cityCenterCoords = transformGridToThreeCoords(centerCoords);
  const cityBorderCoords = borderCoords.map((coords: Coords) =>
    transformGridToThreeCoords(coords)
  );

  const initPos = cityBorderCoords[0];
  const shape = useMemo(() => {
    const extrudeShape = new THREE.Shape();
    extrudeShape.moveTo(0, 0);
    for (let i = 1; i < cityBorderCoords.length; i++) {
      const [x, , y] = cityBorderCoords[i];
      extrudeShape.lineTo(x - initPos[0], y - initPos[2]);
    }

    return extrudeShape;
  }, [initPos, cityBorderCoords]);

  useFrame(({ camera }) => {
    textMeshRef.current.quaternion.copy(camera.quaternion);
    if (
      camera.zoom < 15 &&
      camera.zoom >= 3 &&
      !textMaterialRef.current.visible
    ) {
      textMaterialRef.current.visible = true;
    } else if (
      (camera.zoom < 3 || camera.zoom >= 15) &&
      textMaterialRef.current.visible
    ) {
      textMaterialRef.current.visible = false;
    }
  });

  return (
    <>
      <mesh
        ref={textMeshRef}
        rotation={[-Math.PI / 2, 0, 0]}
        position={[cityCenterCoords[0], 5, cityCenterCoords[2]]}
        scale={1}
      >
        <Text fontSize={4} name={name} color="black">
          {name}
          <meshStandardMaterial ref={textMaterialRef} opacity={0.7} visible />
        </Text>
      </mesh>
      <Extrude
        name="city"
        userData={{
          value: `${name}_City`,
        }}
        args={[shape, extrudeSettings]}
        position={[initPos[0], -5, initPos[2]]}
        rotation={[-Math.PI / 2, Math.PI, -Math.PI]}
      >
        <meshPhysicalMaterial opacity={0} transparent />
      </Extrude>
    </>
  );
}

export default City;
