/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useContext } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import InteractionModal from "../../../common/components/InteractionModal";
import BuildControls from "./BuildControls";
import CommandControls from "./CommandControls";
import SimulatorContext from "../../context/SimulatorContext";
import { BuildItem, ToolType } from "../../../common/types/types";
import Button from "../../../common/components/Button";
import LayersModal from "./LayersModal";
import InterventionModal from "./InterventionModal";

interface ToolsModalProps {
  toolName: ToolType;
  onClose: () => void;
}

function getModalTitle(toolName: ToolType, buildItem: BuildItem) {
  if (toolName === "Build Mode" && buildItem.state === "submit")
    return "Confirm your build.";
  if (toolName === "Build Mode" && buildItem.state === "drag") return undefined;
  return toolName;
}

function getModalSubtitle(toolName: ToolType, buildItem: BuildItem) {
  if (toolName === "Layers") {
    return "Select a layer to display area details by color";
  }
  if (toolName === "Build Mode" && buildItem.state === "form") {
    return "Add your own schools, businesses, neighborhoods, or power plants.";
  }
  if (toolName === "Command Center") {
    return "Create state wide adjustments";
  }
  return "";
}

function getModalHeader(
  buildItem: BuildItem,
  setBuildItem: Dispatch<SetStateAction<BuildItem>>
) {
  if (buildItem.state !== "form")
    return (
      <Button
        color="secondary"
        icon={<ArrowLeftIcon />}
        className={`w-fit h-4 ${
          buildItem.state === "submit" ? "text-red-500" : ""
        }`}
        shadow={false}
        onClick={() =>
          setBuildItem({
            ...buildItem,
            state: "form",
          })
        }
      >
        {buildItem.state === "drag" ? "Back" : "Cancel"}
      </Button>
    );
  return undefined;
}

function getModalContent(toolName: ToolType) {
  if (toolName === "Command Center") {
    return <CommandControls />;
  }

  if (toolName === "Build Mode") {
    return <BuildControls />;
  }

  if (toolName === "Layers") {
    return <LayersModal />;
  }

  if (toolName === "Interventions Overview") {
    return <InterventionModal />;
  }

  return <div>No Tool Selected</div>;
}

export default function ToolsModal({ toolName, onClose }: ToolsModalProps) {
  const { buildItem, setBuildItem } = useContext(SimulatorContext);
  return (
    <InteractionModal
      title={getModalTitle(toolName, buildItem)}
      subtitle={getModalSubtitle(toolName, buildItem)}
      header={getModalHeader(buildItem, setBuildItem)}
      onClose={onClose}
    >
      {getModalContent(toolName)}
    </InteractionModal>
  );
}
