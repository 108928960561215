/* eslint-disable react/require-default-props */
import React from "react";

interface SkeletonProps {
  rows: number;
  size: "small" | "large";
  className?: string;
  avatar?: boolean;
}

export default function Skeleton({
  rows,
  size,
  avatar = false,
  className = "",
}: SkeletonProps) {
  const sizeClass = size === "large" ? "h-6 w-44" : "h-4 w-32";

  return (
    <div className={className}>
      <div className="my-2 flex animate-pulse flex-row items-center space-x-5">
        {avatar && <div className="w-12 bg-gray-300 h-12 rounded-full" />}
        <div className="flex flex-col space-y-3">
          {Array.from({ length: rows }, (row, i) => (
            <div key={i} className={`${sizeClass} bg-gray-300 rounded-md`} />
          ))}
        </div>
      </div>
    </div>
  );
}
