/* eslint-disable react/require-default-props */
import React, { ReactNode } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "./Button";
import Skeleton from "./Skeleton";

interface InteractionModalProps {
  title?: ReactNode;
  subtitle?: string;
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  isLoading?: boolean;
  onClose: () => void;
}

export default function InteractionModal({
  title,
  subtitle,
  children,
  header,
  footer,
  className,
  onClose,
  isLoading = false,
}: InteractionModalProps) {
  return (
    <div className={className}>
      <Transition
        appear
        show
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="ui-container p-6 w-[460px] transform bg-white transition-all">
          <div className="flex flex-row justify-between">
            <div>
              {header}
              {isLoading ? (
                <Skeleton rows={1} size="large" />
              ) : (
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </h3>
              )}
            </div>
            <Button
              color="secondary"
              onClick={onClose}
              icon={<XMarkIcon />}
              className="w-4 h-4"
              variant="standard"
              shadow={false}
            />
          </div>
          {subtitle && (
            <div className="mt-1">
              <div className="text-sm text-gray-500 text-wrap">
                {isLoading ? <Skeleton rows={1} size="small" /> : subtitle}
              </div>
            </div>
          )}
          <div className="pt-3">
            {isLoading ? <Skeleton rows={6} size="small" /> : children}
          </div>
          {footer && (
            <div>
              <hr className="text-base-gray-80 my-1" />
              <div className="mt-4">{footer}</div>
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
}
