/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { offset, useFloating } from "@floating-ui/react";
import { HomeIcon } from "@heroicons/react/24/solid";
import { Transition } from "@headlessui/react";
import { School } from "@rodel-futures-simulator/types";
import SimulatorContext from "../../context/SimulatorContext";
import Toolbar from "../../../common/components/Toolbar";
import Input from "../../../common/components/Input";
import { transformGridToThreeCoords } from "../../utils/transformCoords";
import * as constants from "../../../common/constants";
import FixedDetailModal from "./FixedDetailModal";
import Tooltip from "../../../common/components/Tooltip";

function SearchControls() {
  const {
    gridItemModal,
    name,
    mapState,
    setGridItemModal,
    setAutoRotation,
    mapControlsRef,
  } = useContext(SimulatorContext);

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const filteredSchools = useMemo(() => {
    if (search.trim() === "") {
      return [];
    }

    return mapState.schoolGridItems
      .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      .slice(0, 100);
  }, [mapState, search]);

  const {
    x: floatingX,
    y: floatingY,
    reference,
    floating,
    strategy,
  } = useFloating({
    placement: "bottom-start",
    middleware: [offset(20)],
  });

  const onSelectSchool = (school: School) => {
    setSearch(school.name);
    const [x, y, z] = transformGridToThreeCoords([
      school.xCoord,
      0,
      school.yCoord,
    ]);
    setGridItemModal({
      xCoord: school.xCoord,
      yCoord: school.yCoord,
      type: school.type,
      size: school.size,
      name: school.name,
    });
    setAutoRotation(true);
    mapControlsRef.current.target.x = x;
    mapControlsRef.current.target.y = y;
    mapControlsRef.current.target.z = z;
    mapControlsRef.current.update();
    mapControlsRef.current.setAzimuthalAngle(constants.INIT_AZIMUTHAL_ANGLE);
    mapControlsRef.current.setPolarAngle(constants.INIT_POLAR_ANGLE);
    mapControlsRef.current.update();
  };

  return (
    <>
      <Toolbar size="large">
        <Tooltip
          icon={<HomeIcon className="w-4 h-4 mx-3 my-2" />}
          text="Dashboard"
          onClick={() => {
            navigate("/");
          }}
        />
        <h3 className="capitalize font-medium leading-tight text-lg m-2 whitespace-nowrap">
          {name} Simulation
        </h3>
        <Input
          ref={reference}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => {
            setGridItemModal(undefined);
            setAutoRotation(false);
            setIsSearchFocused(true);
          }}
          onBlur={() => setIsSearchFocused(false)}
          name="search"
          placeholder="Search for a school..."
          className="min-w-[270px]"
        />
      </Toolbar>
      <Transition
        show={isSearchFocused}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={floating}
          style={{
            position: strategy,
            top: floatingY ?? 0,
            left: floatingX ?? 0,
            width: "max-content",
          }}
          className="bg-white p-2 rounded-md shadow-md overflow-auto max-h-96 min-w-max text-sm"
        >
          {filteredSchools.length === 0 && search.trim() === "" ? (
            <div className="p-2 w-full text-gray-600 text-center">
              Enter a school name to search the map...
            </div>
          ) : filteredSchools.length === 0 ? (
            <div className="p-2 w-full text-gray-600 text-center">
              No matching schools...
            </div>
          ) : (
            filteredSchools.map((school, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                key={index}
                className="p-2 w-full hover:bg-base-blue-80 cursor-pointer"
                onClick={() => onSelectSchool(school)}
              >
                {school.name}
              </div>
            ))
          )}
        </div>
      </Transition>
      {gridItemModal && !isSearchFocused && (
        <div className="ml-5 fixed top-[95px] left-0 items-end">
          <FixedDetailModal
            item={gridItemModal}
            onClose={() => {
              setGridItemModal(undefined);
              setAutoRotation(false);
            }}
          />
        </div>
      )}
    </>
  );
}

export default SearchControls;
