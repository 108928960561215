import {
  Coords,
  GridItemSize,
  Timestep,
  School,
  Business,
  Neighborhood,
  // PowerPlant,
} from "@rodel-futures-simulator/types";
import * as constants from "../../common/constants";

export function transformGridToThreeCoords(gridCoords: Coords) {
  const threeCoords: Coords = [
    gridCoords[0] * constants.SCALE,
    gridCoords[1],
    gridCoords[2] * constants.SCALE,
  ];

  return threeCoords;
}

export function transformThreeToGridCoords(threeCoords: Coords) {
  const gridCoords: Coords = [
    Math.floor(threeCoords[0] / constants.SCALE),
    threeCoords[1],
    Math.floor(threeCoords[2] / constants.SCALE),
  ];
  return gridCoords;
}

export function transformDragToGridCoords(
  threeCords: Coords,
  size: GridItemSize
) {
  let gridCoords: Coords;
  switch (size) {
    case GridItemSize.QUARTER:
      gridCoords = [
        Math.floor(threeCords[0] / constants.SCALE),
        threeCords[1],
        Math.floor(threeCords[2] / constants.SCALE),
      ];
      break;
    case GridItemSize.HALF:
      gridCoords = [
        Math.round(threeCords[0] / constants.SCALE) - 1,
        threeCords[1],
        Math.round(threeCords[2] / constants.SCALE) - 1,
      ];
      break;
    case GridItemSize.FULL:
      gridCoords = [
        Math.round(threeCords[0] / constants.SCALE) - 2,
        threeCords[1],
        Math.round(threeCords[2] / constants.SCALE) - 2,
      ];
      break;
    default:
      gridCoords = [
        Math.floor(threeCords[0] / constants.SCALE),
        threeCords[1],
        Math.floor(threeCords[2] / constants.SCALE),
      ];
      break;
  }
  return gridCoords;
}

export function transformGridToDragCoords(
  gridCoords: Coords,
  size: GridItemSize
) {
  let adjustment;
  switch (size) {
    case GridItemSize.QUARTER:
      adjustment = 0.5;
      break;
    case GridItemSize.HALF:
      adjustment = 1;
      break;
    case GridItemSize.FULL:
      adjustment = 2;
      break;
    default:
      adjustment = 0.5;
      break;
  }
  const threeCoords: Coords = [
    (gridCoords[0] + adjustment) * constants.SCALE,
    gridCoords[1],
    (gridCoords[2] + adjustment) * constants.SCALE,
  ];
  return threeCoords;
}

export function getBounds(
  coords: { x: number; y: number },
  size: GridItemSize
) {
  const xMin = coords.x;
  const yMin = coords.y;
  let xMax = xMin;
  let yMax = yMin;

  switch (size) {
    case GridItemSize.HALF:
      xMax += 1;
      yMax += 1;
      break;
    case GridItemSize.FULL:
      xMax += 3;
      yMax += 3;
      break;
    default:
      break;
  }

  return { xMin, xMax, yMin, yMax };
}

type Bounds = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

function doBoundsIntersect(bounds1: Bounds, bounds2: Bounds) {
  if (bounds1.xMin > bounds2.xMax || bounds2.xMin > bounds1.xMax) {
    return false;
  }
  if (bounds1.yMin > bounds2.yMax || bounds2.yMin > bounds1.yMax) {
    return false;
  }

  return true;
}

export function findGridItems(
  gridCoords: Coords,
  size: GridItemSize,
  timestep: Timestep
) {
  const dragBounds = getBounds({ x: gridCoords[0], y: gridCoords[2] }, size);

  const foundSchool = timestep.schoolGridItems.find((item: School) =>
    doBoundsIntersect(
      dragBounds,
      getBounds({ x: item.xCoord, y: item.yCoord }, item.size)
    )
  );
  const foundBusiness = timestep.businessGridItems.find((item: Business) =>
    doBoundsIntersect(
      dragBounds,
      getBounds({ x: item.xCoord, y: item.yCoord }, item.size)
    )
  );
  const foundNeighborhood = timestep.residentialGridItems.find(
    (item: Neighborhood) =>
      doBoundsIntersect(
        dragBounds,
        getBounds({ x: item.xCoord, y: item.yCoord }, item.size)
      )
  );
  // TODO: Make sure power plant is up to date with the others above
  // const foundPowerPlant = timestep.powerGridItems.find(
  //   (powerPlant: PowerPlant) =>
  //     doBoundsIntersect(
  // dragBounds,
  // getBounds({ x: item.xCoord, y: item.yCoord }, item.size)
  // )
  // );
  const foundPowerPlant = false;

  const foundItem =
    foundSchool || foundBusiness || foundNeighborhood || foundPowerPlant;

  return foundItem;
}
