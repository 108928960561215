import { Coords } from "@rodel-futures-simulator/types";
import React, { useContext } from "react";
import { Canvas } from "@react-three/fiber";
import { MapControls, useContextBridge } from "@react-three/drei";
import SimulatorContext from "../context/SimulatorContext";
import * as constants from "../../common/constants";
import Ground from "./components/Ground";
import Lights from "./components/Lights";
import Camera from "./components/Camera";
import Map from "./components/Map";
import { transformGridToThreeCoords } from "../utils/transformCoords";

function Scene() {
  const { mapControlsRef, autoRotation, loadingMap } =
    useContext(SimulatorContext);

  return (
    <>
      <Ground />
      {/* <Stats /> */}
      <Lights intensity={0.8} />
      <Camera />
      <MapControls
        ref={mapControlsRef}
        minZoom={constants.MIN_ZOOM}
        maxZoom={constants.MAX_ZOOM}
        target={transformGridToThreeCoords(constants.INIT_CAM_TARGET as Coords)}
        maxPolarAngle={Math.PI / 2.5}
        rotateSpeed={0.2}
        dampingFactor={1}
        autoRotate={autoRotation}
        autoRotateSpeed={0.5}
      />
      {!loadingMap && <Map />}
    </>
  );
}

function SceneWrapper() {
  const ContextBridge = useContextBridge(SimulatorContext);
  return (
    <Canvas dpr={[1, 2]}>
      <ContextBridge>
        <Scene />
      </ContextBridge>
    </Canvas>
  );
}

export default SceneWrapper;
