import { Raycaster, Scene, Vector2 } from "three";
import { Camera } from "@react-three/fiber";
import { Bounds, Coord } from "../../../../common/types/types";
import filterRaycasterIntersects from "../../../utils/filterRaycasterIntersects";
import { transformThreeToGridCoords } from "../../../utils/transformCoords";

export function isWithinBounds(coord: Coord, bounds: Bounds) {
  return (
    coord.x >= bounds.xMin &&
    coord.x <= bounds.xMax &&
    coord.y >= bounds.yMin &&
    coord.y <= bounds.yMax
  );
}

// Generates a loading boundary based on the focus point of the camera
// and a provided radius
export function getLoadingBoundary(
  scene: Scene,
  raycaster: Raycaster,
  camera: Camera,
  radius: number
): Bounds {
  const screenCenter = new Vector2(0, 0);
  // Set raycaster from the center of the camera
  raycaster.setFromCamera(screenCenter, camera);
  const intersectObjects = raycaster.intersectObjects(scene.children, false);
  const [groundIntersect] = filterRaycasterIntersects({
    intersectObjects,
    filter: "ground",
  });

  // This are the intersection points of our raycaster in THREE js coords
  const groundIntersectX = groundIntersect.point.x;
  const groundIntersectZ = groundIntersect.point.z;

  // These are the transformed THREE js coords to our map coords
  const [gridCenterX, , gridCenterZ] = transformThreeToGridCoords([
    groundIntersectX,
    0,
    groundIntersectZ,
  ]);

  // Create the loading boundary based on the raycaster intersection
  // and the provided radius
  const xMin = gridCenterX - radius;
  const xMax = gridCenterX + radius;
  const yMin = gridCenterZ - radius;
  const yMax = gridCenterZ + radius;

  return { xMin, xMax, yMin, yMax };
}
