import * as THREE from "three";
import {
  GridItem,
  GridItemSize,
  GridItemType,
} from "@rodel-futures-simulator/types";
import { MapLayer, ModelTypes } from "../../../common/types/types";
import getFilterHue from "./getFilterHue";

// For LOD 1

export function getFilterMaterial(
  layer: string,
  gridItem: GridItem,
  modelTypes: ModelTypes
): THREE.Material {
  let finalMaterial: THREE.MeshStandardMaterial;
  const filterMaterial =
    modelTypes.materialVariants[0].clone() as THREE.MeshStandardMaterial;
  const greyscaleMaterial = modelTypes.materialVariants[1]
    ? (modelTypes.materialVariants[1].clone() as THREE.MeshStandardMaterial)
    : filterMaterial;
  // Update the mesh material based on layer
  finalMaterial = greyscaleMaterial;
  finalMaterial.color = new THREE.Color("#3D3D3D");

  if (layer === MapLayer.GRAD_RATE && gridItem.type === GridItemType.SCHOOL) {
    finalMaterial.emissive = new THREE.Color(
      gridItem.graduationRate
        ? getFilterHue(70, 100, gridItem.graduationRate)
        : getFilterHue(70, 100, 0)
    );
    finalMaterial.color = new THREE.Color("#3D3D3D");
  }
  if (
    layer === MapLayer.INCOME &&
    gridItem.type === GridItemType.NEIGHBORHOOD
  ) {
    finalMaterial.emissive = new THREE.Color(
      getFilterHue(45000, 95000, gridItem.avgHouseholdSalary)
    );
    finalMaterial.color = new THREE.Color("#3D3D3D");
  }

  if (
    layer === MapLayer.POPULATION &&
    gridItem.type === GridItemType.NEIGHBORHOOD
  ) {
    // TODO: Remove nested ternary
    const mult =
      // eslint-disable-next-line no-nested-ternary
      gridItem.size === GridItemSize.FULL
        ? 1
        : gridItem.size === GridItemSize.HALF
        ? 4
        : 16;

    const peoplePerSqMile = gridItem.numberOfResidents * mult;
    finalMaterial.emissive = new THREE.Color(
      `hsl(255, 100%, ${Math.round(
        Math.max(Math.min((peoplePerSqMile / 8000) * 100, 80), 20)
      )}%)`
      // getFilterHue(50, 300, gridItem.numberOfResidents / denom)
    );
    finalMaterial.color = new THREE.Color("#3D3D3D");
  }

  if (
    layer === MapLayer.ENERGY &&
    (gridItem.type === GridItemType.BUSINESS ||
      gridItem.type === GridItemType.SCHOOL ||
      gridItem.type === GridItemType.NEIGHBORHOOD)
  ) {
    finalMaterial.emissive = new THREE.Color("#55abff");
    finalMaterial.color = new THREE.Color("#3D3D3D");
  }

  if (layer === MapLayer.DEFAULT) {
    finalMaterial = filterMaterial;
    finalMaterial.emissive = new THREE.Color("#ffffff");
    finalMaterial.color = new THREE.Color("#3b3b3b");
  }

  return finalMaterial;
}

// For LOD 4

export function getFilterColor(layer: string, gridItem: GridItem) {
  let layerColor = new THREE.Color("#ffffff");

  if (layer === MapLayer.GRAD_RATE && gridItem.type === GridItemType.SCHOOL) {
    layerColor = new THREE.Color(
      gridItem.graduationRate
        ? getFilterHue(70, 100, gridItem.graduationRate)
        : getFilterHue(70, 100, 0)
    );
  }
  if (
    layer === MapLayer.INCOME &&
    gridItem.type === GridItemType.NEIGHBORHOOD
  ) {
    layerColor = new THREE.Color(
      getFilterHue(50000, 95000, gridItem.avgHouseholdSalary)
    );
  }
  if (
    layer === MapLayer.POPULATION &&
    gridItem.type === GridItemType.NEIGHBORHOOD
  ) {
    const mult =
      // eslint-disable-next-line no-nested-ternary
      gridItem.size === GridItemSize.FULL
        ? 1
        : gridItem.size === GridItemSize.HALF
        ? 4
        : 16;

    const peoplePerSqMile = gridItem.numberOfResidents * mult;
    layerColor = new THREE.Color(
      `hsl(255, 100%, ${Math.round(
        Math.max(Math.min((peoplePerSqMile / 8000) * 100, 80), 20)
      )}%)`
    );
  }
  if (
    layer === MapLayer.ENERGY &&
    (gridItem.type === GridItemType.BUSINESS ||
      gridItem.type === GridItemType.SCHOOL ||
      gridItem.type === GridItemType.NEIGHBORHOOD)
  ) {
    layerColor = new THREE.Color("#55abff");
  }

  if (layer === MapLayer.DEFAULT) {
    layerColor = new THREE.Color("#ffffff");
  }

  return layerColor;
}
