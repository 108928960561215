import {
  GridItemType,
  School,
  SchoolInvestment,
} from "@rodel-futures-simulator/types";
import React, {
  useState,
  useEffect,
  useContext,
  FormEventHandler,
} from "react";
import {
  PencilIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { formatDollarValue } from "../../../common/utils/formatUtility";
import { calcAvailableFunds } from "../../utils/investUtils";
import SimulatorContext from "../../context/SimulatorContext";
import Button from "../../../common/components/Button";
import Input from "../../../common/components/Input";
import Slider from "../../../common/components/Slider";
import FormRow from "../../../common/components/FormRow";
import Tooltip from "../../../common/components/Tooltip";

interface SchoolFormProps {
  school: School;
}

function SchoolForm({ school }: SchoolFormProps) {
  const { interventions, setInterventions } = useContext(SimulatorContext);

  const [editInvestment, setEditInvestment] = useState<boolean>(true);
  const [schoolFormState, setSchoolFormState] = useState<SchoolInvestment>({
    type: GridItemType.SCHOOL,
    name: school.name,
    size: school.size,
    xCoord: school.xCoord,
    yCoord: school.yCoord,
    amount: 0,
    allocation: "",
    predictions: {
      gradRate: school.graduationRate ? school.graduationRate / 100 : 0,
      capacity: school.numberOfStudents,
    },
  });
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    // Look for a previously submitted investment with same school name, xCoord, and yCoord.
    const foundInvestment = interventions.schoolInvestments.find(
      (investment) =>
        investment.xCoord === school.xCoord &&
        investment.yCoord === school.yCoord &&
        investment.name === school.name
    );

    // If a matching investment is found set it as the FormState.
    if (foundInvestment) {
      setEditInvestment(false);
      setSchoolFormState({
        name: school.name,
        type: GridItemType.SCHOOL,
        size: school.size,
        xCoord: school.xCoord,
        yCoord: school.yCoord,
        amount: foundInvestment.amount,
        allocation: foundInvestment.allocation,
        predictions: {
          ...foundInvestment.predictions,
        },
      });
    }
  }, [interventions.schoolInvestments, school, school.xCoord, school.yCoord]);

  const submitAddGridItem: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const investAmount = schoolFormState.amount;
    const availableFunds = calcAvailableFunds(interventions);

    if (!investAmount) {
      setMessage("You must invest more than $0.");
    } else if (investAmount <= availableFunds) {
      setInterventions((prevInterventions) => {
        // Look for a matching investment that is already submitted.
        let investmentFound = false;
        const updatedInvestments = prevInterventions.schoolInvestments.map(
          (investment) => {
            if (
              investment.xCoord === school.xCoord &&
              investment.yCoord === school.yCoord &&
              investment.name === school.name
            ) {
              // Update the existing investment.
              investmentFound = true;
              return {
                ...schoolFormState,
                predictions: {
                  ...schoolFormState.predictions,
                },
              };
            }
            return investment;
          }
        );

        if (!investmentFound) {
          // No matching investment was found. Add it to school investments.
          updatedInvestments.push(schoolFormState);
        }

        return {
          ...prevInterventions,
          schoolInvestments: updatedInvestments,
        };
      });
      setEditInvestment(false);
    } else {
      setMessage("You do not have enough funds to make this investment");
    }
  };

  return (
    <div>
      {schoolFormState.amount && !editInvestment ? (
        <div>
          <div className="flex justify-end">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setEditInvestment(true);
              }}
              icon={
                editInvestment ? (
                  <XMarkIcon className="h-3 w-3 mr-0.5 mt-0.5 text-red-500" />
                ) : (
                  <PencilIcon className="h-3 w-3 mr-0.5 mt-0.5 text-blue-500" />
                )
              }
            >
              <div className="text-xs text-blue-500">Edit</div>
            </Button>
          </div>
          <div>
            You have invested{" "}
            <span className="font-bold text-base-rodel-100">
              {formatDollarValue(schoolFormState.amount)}
            </span>{" "}
            in <span className="font-bold">{schoolFormState.name}</span>
          </div>
        </div>
      ) : (
        <>
          <form onSubmit={submitAddGridItem}>
            <div className="flex flex-col gap">
              <FormRow label="Investment ($)">
                <Input
                  id="investment"
                  type="number"
                  min={0}
                  step="any"
                  name="investment"
                  value={schoolFormState.amount ?? 0}
                  onChange={(event) => {
                    setMessage("");
                    const amount = parseFloat(event.target.value);
                    setSchoolFormState({ ...schoolFormState, amount });
                  }}
                />
              </FormRow>
              <FormRow label="Allocation">
                <Input
                  id="allocation"
                  type="text"
                  name="investment"
                  value={schoolFormState.allocation ?? ""}
                  onChange={(event) => {
                    setMessage("");
                    const allocation = event.target.value;
                    setSchoolFormState({ ...schoolFormState, allocation });
                  }}
                />
              </FormRow>
              <div className="flex flex-col w-full">
                <div className="text-sm font-medium mb-4 flex flex-row">
                  <div className="pt-2">Predictions</div>
                  <Tooltip
                    position="right"
                    icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
                    text="Enter the investments predicted impact on graduation rate and capacity."
                  />
                </div>
                <div className="my-1 mb-2 flex flex-colgap-6 items-center">
                  <div className="w-full">
                    <Slider
                      label="Graduation Rate"
                      value={schoolFormState.predictions.gradRate}
                      min={0}
                      max={1}
                      step={0.001}
                      onChange={(val) => {
                        const graduationRate = val;
                        setSchoolFormState({
                          ...schoolFormState,
                          predictions: {
                            ...schoolFormState.predictions,
                            gradRate: graduationRate,
                          },
                        });
                      }}
                      className="mb-5"
                      units="percent"
                    />
                    <Slider
                      label="Capacity"
                      value={schoolFormState.predictions.capacity}
                      min={0}
                      max={5000}
                      onChange={(val) => {
                        const numberOfStudents = val;
                        setSchoolFormState({
                          ...schoolFormState,
                          predictions: {
                            ...schoolFormState.predictions,
                            capacity: numberOfStudents,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <hr className="text-base-gray-80 my-1" />
              </div>
              <Button type="submit">Submit</Button>
            </div>
          </form>
          {message && <div className="text-base-red-100 mt-3">{message}</div>}
        </>
      )}
    </div>
  );
}

export default SchoolForm;
