/* eslint-disable react/require-default-props */
import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { ChartData } from "../types/types";

interface ChartProps {
  data: ChartData[];
  label?: string;
  centerValue?: string;
  animate?: boolean;
  centerValueColor?: string;
}

function DoughnutChart({
  data,
  label,
  centerValue,
  animate = false,
  centerValueColor = "black",
}: ChartProps) {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);

  useEffect(() => {
    if (chartRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const myChartRef = chartRef.current.getContext("2d")!;
      const colors = data.map((d) => d.color);
      if (myChartRef) {
        const myChart = new Chart(myChartRef, {
          type: "doughnut",
          data: {
            labels: data.map((d) => d.label),
            datasets: [
              {
                data: data.map((d) => d.value),
                backgroundColor: colors,
                borderWidth: 0,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
            },
            cutout: "80%",
            animation: {
              duration: animate ? 1000 : 0,
            },
          },
        });
        return () => {
          myChart.destroy();
        };
      }
    }

    return () => {};
  }, [animate, data]);

  return (
    <div className="relative h-fit" style={{ width: "12rem" }}>
      <canvas ref={chartRef} />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="text-base-black text-center">
          <div
            className="text-[36px] h-[42px] font-medium mx-auto"
            style={{ color: centerValueColor }}
          >
            {centerValue ?? totalValue}
          </div>
          {label && (
            <div className="text-sm text-black w-max mx-auto">{label}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DoughnutChart;
