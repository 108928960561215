import React, { useContext } from "react";
import { Transition } from "@headlessui/react";
import { MapLayer } from "../../../common/types/types";
import SimulatorContext from "../../context/SimulatorContext";

const layerValues = {
  [MapLayer.GRAD_RATE]: {
    label: MapLayer.GRAD_RATE,
    min: "<70%",
    max: "100%",
    gradient:
      "linear-gradient(to right, hsl(10, 100%, 50%), hsl(100, 100%, 50%))",
  },
  [MapLayer.INCOME]: {
    label: MapLayer.INCOME,
    min: "<$45k",
    max: "$95k+",
    gradient:
      "linear-gradient(to right, hsl(10, 100%, 50%), hsl(100, 100%, 50%))",
  },
  [MapLayer.POPULATION]: {
    label: `${MapLayer.POPULATION} (per sq. mile)`,
    min: "<100",
    max: "8000+",
    gradient:
      "linear-gradient(to right, hsl(255, 100%, 20%), hsl(255, 100%, 80%))",
  },
  [MapLayer.ENERGY]: {
    label: MapLayer.ENERGY,
    min: 1,
    max: 100,
    gradient: "#55abff",
  },
};

export default function LayerLegend() {
  const { selectedLayer } = useContext(SimulatorContext);

  return (
    <div>
      {selectedLayer !== MapLayer.DEFAULT && (
        <Transition
          appear
          show
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="m-5 px-4 py-1 fixed bottom-0 right-96 rounded-md shadow-lg bg-white text-sm w-96 h-[50px]">
            {layerValues[selectedLayer].label}
            <div className="flex flex-row items-center place-content-between">
              <div className="pr-2">{layerValues[selectedLayer].min}</div>
              <div
                style={{
                  height: "10px",
                  background: layerValues[selectedLayer].gradient,
                }}
                className="relative flex-grow w-fit rounded-lg"
              />
              <div className="pl-2">{layerValues[selectedLayer].max}</div>
            </div>
          </div>
        </Transition>
      )}
    </div>
  );
}
