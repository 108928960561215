/* eslint-disable react/require-default-props */
import React, { useState, useMemo, ReactNode } from "react";
import { TimestepDetails } from "@rodel-futures-simulator/types";
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import {
  convertStepToDate,
  formatDollarValue,
  formatNumber,
  formatPercentage,
} from "../../common/utils/formatUtility";
import { colors } from "../../common/constants";
import METRICS from "../../common/constants/MetricConstants";
import LineChart, { Dataset } from "../../common/components/LineChart";
import Tooltip from "../../common/components/Tooltip";
import Select from "../../common/components/Select";

interface Metric {
  title: string;
  value: string;
  units?: string;
  icon: JSX.Element;
  dataSets: Dataset[];
  yScale?: {
    min: number;
    max: number;
  };
}

interface Metrics {
  [key: string]: Metric[];
  Schools: Metric[];
  State: Metric[];
  Resources: Metric[];
  Sustainability: Metric[];
}

interface CardProps {
  id: number;
  header?: ReactNode;
  children?: ReactNode;
  selected?: boolean;
  onClick: (m: number) => void;
}

function Card({ id, header, children, selected, onClick }: CardProps) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={
        selected
          ? "relative px-3 py-4 h-[140px] w-[290px] rounded-md border-base-green-50 border-2 shadow-lg"
          : "relative px-3 py-4 h-[140px] w-[290px] border-[1px] border-base-gray-80 rounded-md hover:border-base-green-50 hover:border-2 hover:shadow-lg"
      }
      onClick={() => onClick(id)}
    >
      {selected && (
        <CheckIcon
          className="absolute -top-2 -right-2 w-6 h-6 p-0.5 bg-base-green-50 border-2 border-base-white rounded-full"
          color="white"
        />
      )}
      <div className="mb-6">{header}</div>
      {children}
    </div>
  );
}

function EconomicReport({
  timestepDetails,
}: {
  timestepDetails: TimestepDetails[];
}) {
  const [selectedDataset, setSelectedDataset] = useState<string>("Schools");
  const [selectedMetric, setSelectedMetric] = useState<number>();

  console.log(timestepDetails);

  const metrics = useMemo<Metrics>(() => {
    return {
      Schools: [
        {
          ...METRICS.Schools.gradRate,
          value: formatPercentage(
            timestepDetails[timestepDetails.length - 1].annualGradRate / 100,
            1
          ),
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.annualGradRate),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
            {
              label: "Baseline",
              data: [76.17, 77.56, 78.01, 77.85, 78.24, 78.31],
              color: "rgb(22,135,43, 0.8)",
              gradient: false,
            },
            {
              label: "Goal",
              data: Array(10).fill(90) as number[],
              color: "rgb(241,54,62, 0.8)",
              gradient: false,
              dash: true,
            },
          ],
          yScale: {
            min: 75,
            max: 92,
          },
        },
        {
          ...METRICS.Schools.postHSEnrollment,
          value: formatPercentage(
            timestepDetails[timestepDetails.length - 1].postHSEnrollment / 100,
            1
          ),
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.postHSEnrollment),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
            {
              label: "Baseline",
              data: [
                38.1, 37.365994455266254, 37.417624966727125,
                37.484910640311355, 36.84620567569437, 36.83047291057061,
                37.30141551915939, 37.066022508742336,
              ],
              color: "rgb(22,135,43, 0.8)",
              gradient: false,
            },
            {
              label: "Goal",
              data: Array(70).fill(70) as number[],
              color: "rgb(241,54,62, 0.8)",
              gradient: false,
              dash: true,
            },
          ],
          yScale: {
            min: 30,
            max: 100,
          },
        },
        // {
        //   ...METRICS.Schools.numStudents,
        //   value: formatNumber(
        //     timestepDetails[timestepDetails.length - 1].totalNumStudents
        //   ),
        //   dataSets: [
        //     {
        //       label: "My Simulation",
        //       data: timestepDetails.map((t) => t.totalNumStudents),
        //       color: "rgba(39,89,123,0.8)",
        //       gradient: false,
        //     },
        //     {
        //       label: "Baseline",
        //       data: baseline.map((t) => t.totalNumStudents),
        //       color: "rgb(22,135,43, 0.8)",
        //       gradient: false,
        //     },
        //     {
        //       label: "Goal",
        //       data: Array(10).fill(1098000) as number[],
        //       color: "rgb(241,54,62, 0.8)",
        //       gradient: false,
        //       dash: true,
        //     },
        //   ],
        //   yScale: {
        //     min: 1097200,
        //     max: 1098100,
        //   },
        // },
        // {
        //   ...METRICS.Schools.personnel,
        //   value: "-",
        //   dataSets: [
        //     {
        //       label: "My Simulation",
        //       data: [],
        //       color: "rgba(39,89,123,0.8)",
        //       gradient: false,
        //     },
        //   ],
        // },
      ],
      State: [
        {
          ...METRICS.State.population,
          value: formatNumber(
            timestepDetails[timestepDetails.length - 1].population + 622608
          ),
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.population + 622608),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
            {
              label: "Baseline",
              data: [
                7431344,
                7470916 - 15000,
                7562204 - 12000,
                7543274 - 10000,
                7537342 - 10000,
              ],
              color: "rgb(22,135,43, 0.8)",
              gradient: false,
            },
            {
              label: "Goal",
              data: Array(10).fill(7550000) as number[],
              color: "rgb(241,54,62, 0.8)",
              gradient: false,
              dash: true,
            },
          ],
          yScale: {
            min: 7250000,
            max: 7750000,
          },
        },
        {
          ...METRICS.State.unemployment,
          value: formatPercentage(
            timestepDetails[timestepDetails.length - 1].annualUnemployment /
              100,
            1
          ),
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.annualUnemployment),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
            {
              label: "Baseline",
              data: [
                3.8, 3.7937697079825092, 4.0586873192168, 4.106225924443549,
                3.975374368161381,
              ],
              color: "rgb(22,135,43, 0.8)",
              gradient: false,
            },
            {
              label: "Goal",
              data: Array(10).fill(2.0) as number[],
              color: "rgb(241,54,62, 0.8)",
              gradient: false,
              dash: true,
            },
          ],
          yScale: {
            min: 0,
            max: 6,
          },
        },
        {
          ...METRICS.State.annualHouseholdIncome,
          value: formatDollarValue(
            timestepDetails[timestepDetails.length - 1].annualHouseholdIncome
          ),
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.annualHouseholdIncome),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
            {
              label: "Baseline",
              data: [
                67090, 66902.75, 66644.49, 67000.82, 66742.17, 66864.31,
                67417.7, 66641.3, 67445.28, 67367.21,
              ],
              color: "rgb(22,135,43, 0.8)",
              gradient: false,
            },
            {
              label: "Goal",
              data: Array(10).fill(68500) as number[],
              color: "rgb(241,54,62, 0.8)",
              gradient: false,
              dash: true,
            },
          ],
          yScale: {
            min: 62500,
            max: 70000,
          },
        },
        {
          ...METRICS.Sustainability.emissions,
          value: timestepDetails[timestepDetails.length - 1].annualCarbon
            ? formatNumber(
                timestepDetails[timestepDetails.length - 1].annualCarbon
              )
            : "-",
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.annualCarbon),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
            {
              label: "Baseline",
              data: [
                83, 82.64116073319958, 80.74857259699343, 80.02465988489931,
                80.19903398334134,
              ],
              color: "rgb(22,135,43, 0.8)",
              gradient: false,
            },
            {
              label: "Goal",
              data: Array(10).fill(70) as number[],
              color: "rgb(241,54,62, 0.8)",
              gradient: false,
              dash: true,
            },
          ],
        },
        //   {
        //     ...METRICS.State.budget,
        //     value: "-",
        //     dataSets: [
        //       {
        //         label: "My Simulation",
        //         data: [],
        //         color: "rgba(39,89,123,0.8)",
        //         gradient: false,
        //       },
        //     ],
        //   },
        //   {
        //     ...METRICS.State.newBusinesses,
        //     value: "-",
        //     dataSets: [
        //       {
        //         label: "My Simulation",
        //         data: [],
        //         color: "rgba(39,89,123,0.8)",
        //         gradient: false,
        //       },
        //     ],
        //   },
        //   {
        //     ...METRICS.State.migratedBusinesses,
        //     value: "-",
        //     dataSets: [
        //       {
        //         label: "My Simulation",
        //         data: [],
        //         color: "rgba(39,89,123,0.8)",
        //         gradient: false,
        //       },
        //     ],
        //   },
      ],
      Resources: [
        {
          ...METRICS.Resources.powerGen,
          value: "- kWh",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
        {
          ...METRICS.Resources.powerUsage,
          value: "- kWh",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
        {
          ...METRICS.Resources.waterUsage,
          value: "- CCF",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
        {
          ...METRICS.Resources.waterProcessed,
          value: "- CCF",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
      ],
      Sustainability: [
        {
          ...METRICS.Sustainability.emissions,
          value: timestepDetails[timestepDetails.length - 1].totalEmissions
            ? formatNumber(
                timestepDetails[timestepDetails.length - 1].totalEmissions
              )
            : "-",
          dataSets: [
            {
              label: "My Simulation",
              data: timestepDetails.map((t) => t.totalEmissions),
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
        {
          ...METRICS.Sustainability.vehicleMilesTraveled,
          value: "-",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
        {
          ...METRICS.Sustainability.elecVehicles,
          value: "- %",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
        {
          ...METRICS.Sustainability.solarHomes,
          value: "- %",
          dataSets: [
            {
              label: "My Simulation",
              data: [],
              color: "rgba(39,89,123,0.8)",
              gradient: false,
            },
          ],
        },
      ],
    };
  }, [timestepDetails]);

  return (
    <>
      <h2 className="font-normal">Simulation Report</h2>
      <div className="flex flex-row place-content-between">
        <h3 className="font-normal">Select Dataset:</h3>
        <Select
          className="w-[150px]"
          value={
            selectedDataset
              ? { value: selectedDataset, label: selectedDataset }
              : undefined
          }
          options={[
            {
              label: "Schools",
              value: "Schools",
            },
            {
              label: "State",
              value: "State",
            },
            // {
            //   label: "Resources",
            //   value: "Resources",
            // },
            // {
            //   label: "Sustainability",
            //   value: "Sustainability",
            // },
          ]}
          onChange={(event) => {
            setSelectedMetric(undefined);
            setSelectedDataset(event.value);
          }}
        />
      </div>
      <div className="flex flex-row py-3 space-x-4">
        {metrics[selectedDataset].map((card, index) => (
          <Card
            key={card.title}
            id={index}
            header={
              <div className="flex flex-row space-x-2 items-center">
                <span>{card.icon}</span>
                <div>{card.title}</div>
              </div>
            }
            onClick={(m) => setSelectedMetric(m)}
            selected={selectedMetric === index}
          >
            <div className="flex flex-row">
              <div>
                <div>{card.value}</div>
                <div className="text-base text-gray-500">{card.units}</div>
              </div>
              <div className="ml-3">
                {/* <Tag text="25%" type="success" icon={<ArrowTrendingUpIcon />} /> */}
              </div>
            </div>
          </Card>
        ))}
      </div>
      <h2 className="font-medium">
        {selectedMetric !== undefined
          ? metrics[selectedDataset][selectedMetric].title
          : "Select a dataset from the Economic Report"}
      </h2>
      <div>
        <div className="flex flex-row space-x-6">
          <div className="flex flex-row items-center space-x-2">
            <div
              className="h-3 w-3 rounded-full"
              style={{
                backgroundColor: colors[0],
              }}
            />
            <p className="font-normal">My Simulation</p>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <div
              className="h-3 w-3 rounded-full"
              style={{
                backgroundColor: colors[1],
              }}
            />
            <div className="flex flex-row">
              <p className="font-normal mr-1">Baseline</p>
              <Tooltip
                icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
                text="The baseline is an average of the all simulation results."
              />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <div
              className="w-5 border-dashed border-2"
              style={{
                borderColor: "rgba(241, 54, 62, 0.8)",
                borderWidth: "1px",
              }}
            />
            <div className="flex flex-row">
              <p className="font-normal mr-1">Goal</p>
            </div>
          </div>
        </div>
      </div>
      <LineChart
        labels={timestepDetails.map((t) => convertStepToDate(t.step))}
        datasets={
          selectedMetric !== undefined
            ? metrics[selectedDataset][selectedMetric].dataSets
            : [
                {
                  label: "My Simulation",
                  data: [],
                  color: colors[0],
                },
              ]
        }
        yScale={
          selectedMetric !== undefined
            ? metrics[selectedDataset][selectedMetric].yScale
            : {
                min: 0,
                max: 100,
              }
        }
      />
      <hr className="text-base-gray-80 my-1" />
    </>
  );
}

export default EconomicReport;
