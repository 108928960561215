import { useFrame } from "@react-three/fiber";
import { GridItem } from "@rodel-futures-simulator/types";
import { useContext, useRef } from "react";
import * as THREE from "three";
import { ModelCatalog } from "../useGridItemModelCatalog";
import SimulatorContext from "../../context/SimulatorContext";
import usePrevious from "../../../common/hooks/usePrevious";
import { MeshSizePair } from "../../../common/types/types";
import { getLoadingBoundary } from "./utils/boundaryUtils";
import { addGridItemMeshes, updateExistingMeshes } from "./utils/meshUtils";
import useMeshClickListener from "../useMeshClickListener";
import {
  filterGridItems,
  generateFillerGridItems,
} from "./utils/gridItemUtils";
import filterRaycasterIntersects from "../../utils/filterRaycasterIntersects";
import { transformThreeToGridCoords } from "../../utils/transformCoords";
import { LOD_ZOOM } from "../../../common/constants";

export default function useRenderGridItems(
  providedGridItems: GridItem[],
  catalog: ModelCatalog,
  visible?: boolean
) {
  const meshes = useRef<Record<string, MeshSizePair>>({});

  const {
    setGridItemModal,
    mapControlsRef,
    setAutoRotation,
    buildItem,
    selectedLayer,
    mapState,
  } = useContext(SimulatorContext);
  const timestep = mapState.step;
  const prevTimestep = usePrevious(timestep);
  const prevLayer = usePrevious(selectedLayer);

  const addMeshClickListener = useMeshClickListener(buildItem.state !== "form");

  let previousRaycasterIntersect: THREE.Vector3 | undefined;
  let zoomedInState = true;
  // Update the camera position and check if it has moved
  function renderGridItems(
    scene: THREE.Scene,
    camera: THREE.OrthographicCamera,
    raycaster: THREE.Raycaster
  ) {
    if (camera.zoom > LOD_ZOOM) {
      zoomedInState = true;
      // Compare the current camera position and zoom with the previous position
      const screenCenter = new THREE.Vector2(0, 0);
      // Set raycaster from the center of the camera
      raycaster.setFromCamera(screenCenter, camera);
      const intersectObjects = raycaster.intersectObjects(
        scene.children,
        false
      );
      const [groundIntersect] = filterRaycasterIntersects({
        intersectObjects,
        filter: "ground",
      });

      // This are the intersection points of our raycaster in THREE js coords
      const groundIntersectX = groundIntersect.point.x;
      const groundIntersectZ = groundIntersect.point.z;

      // These are the transformed THREE js coords to our map coords
      const [gridCenterX, , gridCenterZ] = transformThreeToGridCoords([
        groundIntersectX,
        0,
        groundIntersectZ,
      ]);

      const raycasterIntersect = new THREE.Vector3(gridCenterX, 0, gridCenterZ);

      if (!previousRaycasterIntersect) {
        previousRaycasterIntersect = new THREE.Vector3(
          gridCenterX,
          0,
          gridCenterZ
        );
        return true;
      }

      if (
        raycasterIntersect.distanceToSquared(previousRaycasterIntersect) >= 40
      ) {
        previousRaycasterIntersect.copy(raycasterIntersect);
        return true;
      }
      // Store the current camera position for the next frame
      return false;
    }
    if (zoomedInState) {
      previousRaycasterIntersect = undefined;
      zoomedInState = false;
      return true;
    }
    return false;
  }

  useFrame(({ camera, raycaster, scene }) => {
    if (renderGridItems(scene, camera as THREE.OrthographicCamera, raycaster)) {
      // Get the loading boundary and create a list of grid items to be rendered in the scene,
      // made up of the provided grid items and a generated list of items to fill in empy grid coordinates
      const loadingBoundary = getLoadingBoundary(scene, raycaster, camera, 40);
      const filteredGridItems = filterGridItems(
        providedGridItems,
        loadingBoundary
      );
      const fillerItems = generateFillerGridItems(
        loadingBoundary,
        filteredGridItems
        // meshes.current
      );
      const gridItems = [...filteredGridItems, ...fillerItems];
      // Add grid items that are not already in the scene and start tracking them
      addGridItemMeshes(
        gridItems,
        meshes.current,
        loadingBoundary,
        scene,
        setGridItemModal,
        setAutoRotation,
        mapControlsRef,
        catalog,
        selectedLayer,
        prevLayer,
        timestep,
        prevTimestep,
        addMeshClickListener
      );
      // Update the exising meshes, by removing or toggling their visibility
      updateExistingMeshes(
        meshes.current,
        loadingBoundary,
        scene,
        camera.zoom,
        visible
      );
    }
  });
}
