/* eslint-disable react/no-unknown-property */
import { Coords } from "@rodel-futures-simulator/types";
import React, { useRef, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Extrude, Text, Line } from "@react-three/drei";
import { transformGridToThreeCoords } from "../../utils/transformCoords";

interface CountyProps {
  name: string;
  borderCoords: Coords[];
  centerCoords: Coords;
}

const extrudeSettings = { steps: 1, depth: 4, bevelEnabled: false };

function County({ name, borderCoords, centerCoords }: CountyProps) {
  const textMeshRef = useRef<THREE.Mesh>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const textMaterialRef = useRef<THREE.MeshStandardMaterial>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const countyCenterCoords = transformGridToThreeCoords(centerCoords);
  const countyBorderCoords = borderCoords.map((coords: Coords) =>
    transformGridToThreeCoords(coords)
  );

  const initPos = countyBorderCoords[0];
  const shape = useMemo(() => {
    const extrudeShape = new THREE.Shape();
    extrudeShape.moveTo(0, 0);
    for (let i = 1; i < countyBorderCoords.length; i++) {
      const [x, , y] = countyBorderCoords[i];
      extrudeShape.lineTo(x - initPos[0], y - initPos[2]);
    }

    return extrudeShape;
  }, [initPos, countyBorderCoords]);

  useFrame(({ camera }) => {
    textMeshRef.current.quaternion.copy(camera.quaternion);
    if (camera.zoom < 3 && !textMaterialRef.current.visible) {
      textMaterialRef.current.visible = true;
    } else if (camera.zoom >= 3 && textMaterialRef.current.visible) {
      textMaterialRef.current.visible = false;
    }
  });

  return (
    <>
      <Line points={countyBorderCoords} color="#1a4728" lineWidth={1} />
      <mesh
        ref={textMeshRef}
        rotation={[-Math.PI / 2, 0, 0]}
        position={[countyCenterCoords[0], 10, countyCenterCoords[2]]}
        scale={1}
      >
        <Text fontSize={18} name={name} color="black">
          {name} County
          <meshStandardMaterial
            ref={textMaterialRef}
            opacity={0.7}
            visible={false}
          />
        </Text>
      </mesh>
      <Extrude
        name="county"
        userData={{
          value: `${name}_County`,
        }}
        args={[shape, extrudeSettings]}
        position={[initPos[0], -5, initPos[2]]}
        rotation={[-Math.PI / 2, Math.PI, -Math.PI]}
      >
        <meshPhysicalMaterial opacity={0} transparent />
      </Extrude>
    </>
  );
}

export default County;
