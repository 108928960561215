import React, { useContext, useMemo } from "react";
import { Coords } from "@rodel-futures-simulator/types";
import { VideoCameraIcon } from "@heroicons/react/24/outline";
import SimulatorContext from "../../context/SimulatorContext";
import { transformGridToThreeCoords } from "../../utils/transformCoords";
import * as constants from "../../../common/constants";
import Compass from "./Compass";
import Toolbar from "../../../common/components/Toolbar";
import Select from "../../../common/components/Select";
import { SelectOption } from "../../../common/types/types";
import Tooltip from "../../../common/components/Tooltip";

interface BoundaryOption extends SelectOption<string> {
  centerCoords: Coords;
}

function NavigationControls() {
  const {
    loadingMap,
    counties,
    mapControlsRef,
    selectRef,
    cameraType,
    setCameraType,
  } = useContext(SimulatorContext);

  const boundaryOptions: BoundaryOption[] = useMemo(() => {
    return counties.flatMap((county) => {
      const countyOption = {
        value: `${county.name}_County`,
        label: `${county.name} County`,
        centerCoords: county.centerCoords,
        disabled: true,
      };
      const cityOptions = county.cities.map((city) => ({
        value: `${city.name}_City`,
        label: city.name,
        centerCoords: city.centerCoords,
      }));

      return [countyOption, ...cityOptions];
    });
  }, [counties]);

  const handleOnChange = (option: BoundaryOption) => {
    const [x, y, z] = transformGridToThreeCoords(option.centerCoords);

    mapControlsRef.current.target.x = x;
    mapControlsRef.current.target.y = y;
    mapControlsRef.current.target.z = z;
    mapControlsRef.current.update();
    mapControlsRef.current.setAzimuthalAngle(constants.INIT_AZIMUTHAL_ANGLE);
    mapControlsRef.current.setPolarAngle(constants.INIT_POLAR_ANGLE);
    mapControlsRef.current.update();
  };

  return (
    <div className="flex flex-row items-end space-x-2">
      <Toolbar>
        <Tooltip
          icon={<VideoCameraIcon className="w-4 h-4 m-2" />}
          text="Change View"
          isActive={cameraType === "perspective"}
          onClick={() =>
            setCameraType((type) =>
              type === "orthographic" ? "perspective" : "orthographic"
            )
          }
        />
        <Select
          label="Viewing:"
          placeholder={loadingMap ? "Loading..." : "Nothing..."}
          options={boundaryOptions}
          ref={selectRef}
          onChange={(val) => {
            handleOnChange(val);
          }}
          placement="top-end"
        />
      </Toolbar>
      <Compass />
    </div>
  );
}

export default NavigationControls;
