/* eslint-disable react/no-unknown-property */
import {
  GridItem,
  GridItemSize,
  GridItemType,
} from "@rodel-futures-simulator/types";
import { ThreeEvent } from "@react-three/fiber";
import React, { useRef, useMemo, useContext, useLayoutEffect } from "react";
import * as THREE from "three";
import { useTexture } from "@react-three/drei";
import { ModelCatalogEntry } from "../../../hooks/useGridItemModelCatalog";
import SimulatorContext from "../../../context/SimulatorContext";
import { MapLayer } from "../../../../common/types/types";
import { transformGridToThreeCoords } from "../../../utils/transformCoords";
import * as constants from "../../../../common/constants";
import { getFilterColor } from "../../../utils/filterLayers/getFilter";
import {
  getTranslatedPosition,
  translateGeometry,
} from "../../../hooks/useRenderGridItems/utils/translationUtils";

const temp = new THREE.Object3D();

function useGridItemPlanarTextureMaterial(
  models: ModelCatalogEntry,
  size: GridItemSize
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const texture1 = useTexture(models[size].textures[0], (t) => {
    // eslint-disable-next-line no-param-reassign
    (t as THREE.Texture).encoding = THREE.sRGBEncoding;
  });
  const texture2 = useTexture(models[size].textures[1], (t) => {
    // eslint-disable-next-line no-param-reassign
    (t as THREE.Texture).encoding = THREE.sRGBEncoding;
  });
  const material1 = useMemo(
    () => new THREE.MeshBasicMaterial({ map: texture1, vertexColors: false }),
    [texture1]
  );
  const material2 = useMemo(
    () => new THREE.MeshBasicMaterial({ map: texture2, vertexColors: false }),
    [texture2]
  );
  return [material1, material2];
}

interface InstancedSizedGridItemsProps {
  type: GridItemType;
  size: GridItemSize;
  gridItems: GridItem[];
  handleOnDoubleClick?: (event: ThreeEvent<MouseEvent>) => void; // eslint-disable-line react/require-default-props
  models: ModelCatalogEntry;
  geometry: THREE.BoxGeometry;
}

function InstancedSizedGridItems({
  type,
  size,
  gridItems,
  handleOnDoubleClick = () => {},
  models,
  geometry,
}: InstancedSizedGridItemsProps) {
  const [material, greyMaterial] = useGridItemPlanarTextureMaterial(
    models,
    size
  );
  const colors = useMemo(
    () => new Float32Array(1360 * 1120 * 3).map(() => 1),
    []
  );

  const ref = useRef<THREE.InstancedMesh>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const { selectedLayer } = useContext(SimulatorContext);
  useLayoutEffect(() => {
    if (selectedLayer && selectedLayer !== MapLayer.DEFAULT)
      ref.current.material = greyMaterial;
    else ref.current.material = material;
    for (let i = 0; i < gridItems.length; i++) {
      const translatedPosition = getTranslatedPosition(gridItems[i]);
      const [x, z, y] = transformGridToThreeCoords([
        translatedPosition.x,
        0,
        translatedPosition.y,
      ]);
      temp.position.set(x, z, y);
      const randRotation = gridItems[i].rotation ?? 0;
      temp.rotation.set(0, Math.PI - randRotation, 0);
      temp.updateMatrix();
      ref.current.setMatrixAt(i, temp.matrix);

      ref.current.instanceMatrix.needsUpdate = true;

      // layer part
      const gridItem = gridItems[i];

      const layerColor = getFilterColor(selectedLayer, gridItem);

      layerColor.toArray(colors, i * 3);
    }
    ref.current.instanceColor!.needsUpdate = true; // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }, [selectedLayer, colors, gridItems, material, greyMaterial]);

  return (
    <instancedMesh
      ref={ref}
      args={[geometry, material, gridItems?.length]}
      name={type}
      onDoubleClick={(event) => handleOnDoubleClick(event)}
    >
      <instancedBufferAttribute
        attach="instanceColor"
        count={colors.length / 3}
        array={colors}
        itemSize={3}
        usage={THREE.DynamicDrawUsage}
      />
    </instancedMesh>
  );
}

interface InstancedGridItemsProps {
  type: GridItemType;
  sizes: GridItemSize[];
  gridItems: GridItem[];
  handleOnDoubleClick?: (event: ThreeEvent<MouseEvent>) => void; // eslint-disable-line react/require-default-props
  models: ModelCatalogEntry;
}

function InstancedGridItems({
  type,
  sizes,
  gridItems,
  handleOnDoubleClick = () => {},
  models,
}: InstancedGridItemsProps) {
  const quarterSizeGeometry = useMemo(
    () => new THREE.BoxGeometry(1 * constants.SCALE, 0, 1 * constants.SCALE),
    []
  );

  const halfSizeGeometry = useMemo(
    () => new THREE.BoxGeometry(2 * constants.SCALE, 0, 2 * constants.SCALE),
    []
  );

  const fullSizeGeometry = useMemo(
    () => new THREE.BoxGeometry(4 * constants.SCALE, 0, 4 * constants.SCALE),
    []
  );

  translateGeometry(quarterSizeGeometry);
  translateGeometry(halfSizeGeometry);
  translateGeometry(fullSizeGeometry);

  const quarterSize = gridItems.filter((x) => x.size === GridItemSize.QUARTER);
  const halfSize = gridItems.filter((x) => x.size === GridItemSize.HALF);
  const fullSize = gridItems.filter((x) => x.size === GridItemSize.FULL);

  return (
    <>
      {sizes.includes(GridItemSize.QUARTER) && (
        <InstancedSizedGridItems
          type={type}
          size={GridItemSize.QUARTER}
          gridItems={quarterSize}
          geometry={quarterSizeGeometry}
          handleOnDoubleClick={handleOnDoubleClick}
          models={models}
        />
      )}
      {sizes.includes(GridItemSize.HALF) && (
        <InstancedSizedGridItems
          type={type}
          size={GridItemSize.HALF}
          gridItems={halfSize}
          geometry={halfSizeGeometry}
          handleOnDoubleClick={handleOnDoubleClick}
          models={models}
        />
      )}
      {sizes.includes(GridItemSize.FULL) && (
        <InstancedSizedGridItems
          type={type}
          size={GridItemSize.FULL}
          gridItems={fullSize}
          geometry={fullSizeGeometry}
          handleOnDoubleClick={handleOnDoubleClick}
          models={models}
        />
      )}
    </>
  );
}

export default InstancedGridItems;
