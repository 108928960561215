import {
  AbstractGridItem,
  Coords,
  GridItemSize,
  GridItemType,
  Timestep,
  County,
  StatusType,
  Intervention,
} from "@rodel-futures-simulator/types";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { Mesh, BufferGeometry, Material } from "three";
import type { MapControls } from "three-stdlib";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ObjectMap } from "@react-three/fiber";

export declare type SelectOption<ValueType> = {
  label: string;
  value: ValueType;
  disabled?: boolean;
};

export interface BuildItem {
  state: "form" | "drag" | "submit";
  type:
    | GridItemType.BUSINESS
    | GridItemType.NEIGHBORHOOD
    | GridItemType.SCHOOL
    | GridItemType.POWER_PLANT;
  size: GridItemSize;
  gridCoords?: Coords;
}

export interface GridItemModalType {
  type: GridItemType;
  xCoord: number;
  yCoord: number;
  size: GridItemSize;
  index?: number;
  name?: string;
}

export type ToolType =
  | "Build Mode"
  | "Command Center"
  | "Layers"
  | "Interventions Overview"
  | undefined;

export enum MapLayer {
  GRAD_RATE = "Graduation Rate",
  INCOME = "Household Income",
  POPULATION = "Population",
  ENERGY = "Energy",
  DEFAULT = "Default",
}

export type ModelTypes = {
  meshVariants: BufferGeometry[];
  materialVariants: Material[];
};

export type CameraType = "orthographic" | "perspective";

export interface ChartData {
  label: string;
  value: number;
  formattedVal?: string;
  color: string;
}

export interface ISimulatorContext {
  simulationId: string;
  simStatus: StatusType;
  name: string;
  loadingMap: boolean;
  loadingScene: boolean;
  setLoadingScene: Dispatch<SetStateAction<boolean>>;
  setTimestep: Dispatch<SetStateAction<number>>;
  // setTimestepRangeMin: Dispatch<SetStateAction<number>>;
  finalTimestep: number;
  mapState: Timestep;
  counties: County[];
  mapControlsRef: MutableRefObject<MapControls>;
  buildItem: BuildItem;
  setBuildItem: Dispatch<SetStateAction<BuildItem>>;
  interventions: Intervention;
  setInterventions: Dispatch<SetStateAction<Intervention>>;
  playing: boolean;
  play: () => void;
  stop: () => void;
  reset: () => void;
  speed: number;
  setSpeed: Dispatch<SetStateAction<number>>;
  // networkPause: boolean;
  gridItemModal: AbstractGridItem | undefined;
  setGridItemModal: Dispatch<SetStateAction<GridItemModalType | undefined>>;
  selectedLayer: MapLayer;
  setSelectedLayer: Dispatch<SetStateAction<MapLayer>>;
  compassRef: MutableRefObject<HTMLButtonElement>;
  selectRef: MutableRefObject<HTMLSpanElement>;
  autoRotation: boolean;
  setAutoRotation: Dispatch<SetStateAction<boolean>>;
  cameraType: CameraType;
  setCameraType: Dispatch<SetStateAction<CameraType>>;
}

export type MeshSizePair = {
  mesh: Mesh;
  size: GridItemSize;
};

export type GLTFModel = GLTF & ObjectMap;

// An object for representing a two dimensional boundary
export type Bounds = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

export type Coord = {
  x: number;
  y: number;
};
