import { BufferGeometry } from "three";
import { GridItem, GridItemSize } from "@rodel-futures-simulator/types";
import { GLTFModel } from "../../../../common/types/types";

// Translates the geometry so that its origin is in the center
// This allows for rotating meshes that use this geometry and still maintaining its relative position
export function translateGeometry(geo: BufferGeometry) {
  geo.computeBoundingBox();
  if (geo.boundingBox) {
    const centerX = (geo.boundingBox.max.x + geo.boundingBox.min.x) / 2;
    const centerY = (geo.boundingBox.max.y + geo.boundingBox.min.y) / 2;
    // Translate
    geo.translate(-centerX, -centerY, 0);
  }
}

export function translateGLTF(gltf: GLTFModel, model: string) {
  const { geometry } = gltf.nodes[model] as THREE.Mesh;
  translateGeometry(geometry);
}

export function getTranslatedPosition(gridItem: GridItem) {
  let adjustment = 0;
  switch (gridItem.size) {
    case GridItemSize.QUARTER:
      adjustment = 0.5;
      break;
    case GridItemSize.HALF:
      adjustment = 1;
      break;
    case GridItemSize.FULL:
      adjustment = 2;
      break;
    default:
      adjustment = 0.5;
      break;
  }
  return {
    x: gridItem.xCoord + adjustment,
    y: gridItem.yCoord + adjustment,
  };
}
