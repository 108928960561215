import {
  Construction,
  GridItem,
  GridItemSize,
  GridItemType,
} from "@rodel-futures-simulator/types";
import React, { useContext, useEffect, useMemo } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
import SimulatorContext from "../../context/SimulatorContext";
import City from "./City";
import County from "./County";
import DragItem from "./DragItem";
import InstancedGridItems from "./instancedGridItems";
import * as constants from "../../../common/constants";
import useCustomFrame from "../../hooks/useCustomFrame";
import useRenderGridItems from "../../hooks/useRenderGridItems";
import useGridItemModelCatalog from "../../hooks/useGridItemModelCatalog";
import useGridItemSelection from "../../hooks/useGridItemSelection";

function Map() {
  const {
    mapState,
    counties,
    buildItem,
    interventions,
    gridItemModal,
    cameraType,
  } = useContext(SimulatorContext);

  const applyRotation = (item: GridItem) => {
    // Randomly rotate mesh
    const randIdx = Math.floor(Math.random() * constants.ROTATION_VALS.length);
    return {
      ...item,
      rotation: item.rotation ?? constants.ROTATION_VALS[randIdx],
    };
  };

  const schoolItems = useMemo(
    () =>
      mapState.schoolGridItems
        .filter(
          (item, i, self) =>
            i ===
            self.findIndex(
              (searchItem) =>
                searchItem.xCoord === item.xCoord &&
                searchItem.yCoord === item.yCoord
            )
        )
        .map(applyRotation),
    [mapState.schoolGridItems]
  );
  const businessItems = useMemo(
    () => mapState.businessGridItems.map(applyRotation),
    [mapState.businessGridItems]
  );
  const residentialItems = useMemo(
    () => mapState.residentialGridItems.map(applyRotation),
    [mapState.residentialGridItems]
  );
  const powerPlantItems = useMemo(
    () => mapState.powerGridItems.map(applyRotation),
    [mapState.powerGridItems]
  );

  const constructionItems = useMemo<Construction[]>(() => {
    return buildItem.state === "submit" && buildItem.gridCoords
      ? [
          {
            type: GridItemType.CONSTRUCTION,
            constructionType: buildItem.type,
            size: buildItem.size,
            xCoord: buildItem.gridCoords[0],
            yCoord: buildItem.gridCoords[2],
          },
          ...mapState.constructionGridItems,
          ...(interventions.builds.map((build) => ({
            type: GridItemType.CONSTRUCTION,
            constructionType: buildItem.type,
            size: build.size,
            xCoord: build.xCoord,
            yCoord: build.yCoord,
          })) as Construction[]),
        ]
      : [
          ...mapState.constructionGridItems,
          ...(interventions.builds.map((build) => ({
            type: GridItemType.CONSTRUCTION,
            constructionType: buildItem.type,
            size: build.size,
            xCoord: build.xCoord,
            yCoord: build.yCoord,
          })) as Construction[]),
        ];
  }, [
    mapState.constructionGridItems,
    buildItem.gridCoords,
    buildItem.size,
    buildItem.type,
    buildItem.state,
    interventions.builds,
  ]);

  const [selectGridItemAt, removeGridItemSelection] = useGridItemSelection();

  useEffect(() => {
    if (gridItemModal)
      selectGridItemAt({
        pos: [gridItemModal.xCoord, gridItemModal.yCoord],
        size: gridItemModal.size,
      });
    else removeGridItemSelection();
  }, [gridItemModal, selectGridItemAt, removeGridItemSelection]);

  const catalog = useGridItemModelCatalog();
  useRenderGridItems(
    [
      ...schoolItems,
      ...residentialItems,
      ...businessItems,
      ...powerPlantItems,
      ...constructionItems,
    ],
    catalog,
    cameraType === "perspective" ? true : undefined
  );

  useCustomFrame();

  return (
    <>
      <InstancedGridItems
        type={GridItemType.SCHOOL}
        gridItems={schoolItems}
        models={catalog[GridItemType.SCHOOL]}
        sizes={[GridItemSize.QUARTER, GridItemSize.HALF]}
      />
      <InstancedGridItems
        type={GridItemType.BUSINESS}
        gridItems={businessItems}
        models={catalog[GridItemType.BUSINESS]}
        sizes={[GridItemSize.QUARTER, GridItemSize.HALF, GridItemSize.FULL]}
      />
      <InstancedGridItems
        type={GridItemType.NEIGHBORHOOD}
        gridItems={residentialItems}
        models={catalog[GridItemType.NEIGHBORHOOD]}
        sizes={[GridItemSize.QUARTER, GridItemSize.HALF, GridItemSize.FULL]}
      />
      <InstancedGridItems
        type={GridItemType.POWER_PLANT}
        gridItems={powerPlantItems}
        models={catalog[GridItemType.POWER_PLANT]}
        sizes={[GridItemSize.QUARTER, GridItemSize.HALF]}
      />
      <InstancedGridItems
        type={GridItemType.CONSTRUCTION}
        gridItems={constructionItems}
        models={catalog[GridItemType.CONSTRUCTION]}
        sizes={[GridItemSize.QUARTER, GridItemSize.HALF, GridItemSize.FULL]}
      />
      {counties?.map((county) => (
        <React.Fragment key={`${county.name}_County`}>
          <County
            name={county.name}
            borderCoords={county.borderCoords}
            centerCoords={county.centerCoords}
          />
          {county.cities?.map((city) => (
            <City
              key={`${city.name}_City`}
              name={city.name}
              borderCoords={city.borderCoords}
              centerCoords={city.centerCoords}
            />
          ))}
        </React.Fragment>
      ))}
      {buildItem.state === "drag" && <DragItem />}
    </>
  );
}

export default Map;
