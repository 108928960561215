import React from "react";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div className="flex flex-col w-screen h-screen gradient justify-center items-center">
      <p className="text-base-white font-bold">
        404: There was an error. Click the link below to get back on track.
      </p>
      <Link
        to="/"
        className="text-base-rodel-100 hover:text-base-rodel-110 hover:underline font-bold p-4"
      >
        Return to Dashboard
      </Link>
    </div>
  );
}

export default ErrorPage;
