import { format } from "date-fns";

export function formatDate(date: Date) {
  return format(date, "MMM d, yyyy");
}

export function formatTimestamp(timestamp: number) {
  const date: Date = new Date(timestamp);
  return format(date, "MM/dd/yyyy, hh:mm a");
}

export function formatPercentage(percentage: number, precision = 2) {
  return `${(percentage * 100).toFixed(precision)}%`;
}

export function formatDollarValue(value: number, includeCents = true) {
  return `$${Intl.NumberFormat("en", {
    minimumFractionDigits: includeCents ? 2 : 0,
    maximumFractionDigits: includeCents ? 2 : 0,
  }).format(value)}`;
}

export function formatNumber(value: number) {
  return Intl.NumberFormat("en").format(value);
}

export function convertStepToDate(years: number) {
  const startingDate = new Date(2024, 0, 1);

  const futureDate = new Date(
    startingDate.getFullYear() + years,
    startingDate.getMonth(),
    startingDate.getDate()
  );

  const formattedDate = futureDate.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
