/* eslint-disable @typescript-eslint/quotes */
import React, { useMemo, useContext, useState } from "react";
import {
  GridItem,
  GridItemSize,
  GridItemType,
} from "@rodel-futures-simulator/types";
import { Placement } from "@floating-ui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { calcAvailableFunds, getBuildAmount } from "../../utils/investUtils";
import { getItemSizeString } from "../../utils/getEnum";
import Button from "../../../common/components/Button";
import FormRow from "../../../common/components/FormRow";
import Select from "../../../common/components/Select";
import Input from "../../../common/components/Input";
import SimulatorContext from "../../context/SimulatorContext";

type FormData = {
  label: string;
  id: string;
  name?: string;
  type?: string;
  options?: { value: string; label: string }[];
  disabled?: boolean;
  placement?: Placement;
};

interface FormDetails {
  [key: string]:
    | {
        [key: string]: string | number;
        name: string;
        schoolType: string;
        schoolLevel: string;
      }
    | {
        [key: string]: string | number;
        numberOfResidents: number | string;
        numberOfHouseholds: number | string;
        avgHouseholdSalary: number | string;
      }
    | {
        [key: string]: string | number;
        numberOfEmployees: number | string;
      }
    | {
        [key: string]: string | number;
        name: string;
        powerOutput: string;
        energyType: string;
      };
}

function BuildForm() {
  const { buildItem, setBuildItem, interventions, setInterventions } =
    useContext(SimulatorContext);

  const [message, setMessage] = useState<string | null>(null);
  const [form, setForm] = useState<FormDetails>({
    [GridItemType.SCHOOL]: {
      name: "",
      schoolType: "",
      schoolLevel: "",
    },
    [GridItemType.NEIGHBORHOOD]: {
      numberOfResidents: "",
      numberOfHouseholds: "",
      avgHouseholdSalary: "",
    },
    [GridItemType.BUSINESS]: {
      numberOfEmployees: "",
    },
    [GridItemType.POWER_PLANT]: {
      name: "",
      powerOutput: "0",
      energyType: "",
    },
  });

  const handleSubmitBtnClick = () => {
    const decrement = getBuildAmount(buildItem.type, buildItem.size);
    const availableFunds = calcAvailableFunds(interventions);

    if (decrement <= availableFunds) {
      setInterventions((prevIntevention) => {
        return {
          ...prevIntevention,
          builds: [
            ...prevIntevention.builds,
            {
              ...form[buildItem.type],
              xCoord: buildItem?.gridCoords?.[0],
              yCoord: buildItem?.gridCoords?.[2],
              size: buildItem.size,
              type: buildItem.type,
            } as GridItem,
          ],
        };
      });
    } else {
      setMessage("You do not have enough investment funds to build this item");
    }

    setBuildItem({
      state: "form",
      type: GridItemType.SCHOOL,
      size: GridItemSize.QUARTER,
    });
    setForm({
      [GridItemType.SCHOOL]: {
        name: "",
        schoolType: "",
        schoolLevel: "",
      },
      [GridItemType.NEIGHBORHOOD]: {
        numberOfResidents: "",
        numberOfHouseholds: "",
        avgHouseholdSalary: "",
      },
      [GridItemType.BUSINESS]: {
        numberOfEmployees: "",
      },
      [GridItemType.POWER_PLANT]: {
        name: "",
        powerOutput: "0",
        energyType: "",
      },
    });
  };

  const handleFormBtnClick = () => {
    setBuildItem({
      ...buildItem,
      state: "drag",
    });
  };

  const dynamicForm: {
    [type: string]: {
      structureSize: GridItemSize[];
      formData: FormData[];
    };
  } = useMemo(() => {
    return {
      [GridItemType.SCHOOL]: {
        structureSize: [GridItemSize.QUARTER, GridItemSize.HALF],
        formData: [
          {
            label: "Name of School",
            id: "name",
            type: "string",
            name: "name",
          },
          {
            label: "School Type",
            id: "schoolType",
            options: [
              { value: "Public", label: "Public" },
              { value: "Private", label: "Private" },
              { value: "Charter", label: "Charter" },
            ],
          },
          {
            label: "School Level",
            id: "schoolLevel",
            options: [
              { value: "Elementary", label: "Elementary" },
              { value: "Middle", label: "Middle School" },
              { value: "High", label: "High School" },
            ],
          },
        ],
      },
      [GridItemType.NEIGHBORHOOD]: {
        structureSize: [
          GridItemSize.QUARTER,
          GridItemSize.HALF,
          GridItemSize.FULL,
        ],
        formData: [
          {
            label: "Number of Residents",
            id: "numberOfResidents",
            type: "number",
            name: "numberOfResidents",
          },
          {
            label: "Number of Households",
            id: "numberOfHouseholds",
            type: "number",
            name: "numberOfHouseholds",
          },
          {
            label: "Average Household Salary",
            id: "avgHouseholdSalary",
            type: "number",
            name: "avgHouseholdSalary",
          },
        ],
      },
      [GridItemType.BUSINESS]: {
        structureSize: [
          GridItemSize.QUARTER,
          GridItemSize.HALF,
          GridItemSize.FULL,
        ],
        formData: [
          {
            label: "Number of Employees",
            id: "numberOfEmployees",
            type: "number",
            name: "numberOfEmployees",
          },
        ],
      },
      [GridItemType.POWER_PLANT]: {
        structureSize: [
          // GridItemSize.QUARTER,
          GridItemSize.HALF,
        ],
        formData: [
          {
            label: "Name of Power Plant",
            id: "name",
            type: "string",
            name: "name",
          },
          {
            label: "Energy Type",
            id: "energyType",
            options: [
              { value: "Hydroelectric", label: "Hydroelectric" },
              { value: "Natural gas", label: "Natural gas" },
              { value: "Coal", label: "Coal" },
              { value: "Nuclear", label: "Nuclear" },
              { value: "Reservoir", label: "Reservoir" },
              { value: "Biomass", label: "Biomass" },
              { value: "Petroleum", label: "Petroleum" },
              { value: "Solar", label: "Solar" },
              { value: "Wind", label: "Wind" },
              { value: "Pumped storage", label: "Pumped storage" },
              { value: "Battery storage", label: "Battery storage" },
            ],
            placement: "top-end",
          },
          {
            label: "Power Output (MW)",
            id: "powerOutput",
            type: "number",
            name: "powerOutput",
            disabled: true,
          },
        ],
      },
    };
  }, []);

  const sizeSelectOptions = useMemo(() => {
    return dynamicForm[buildItem.type].structureSize.map((itemSize) => {
      return {
        value: itemSize,
        label: `${getItemSizeString(itemSize)} ${buildItem.type}`,
      };
    });
  }, [buildItem.type, dynamicForm]);

  const isFormFilled = () => {
    const hasEmptyProperty = Object.values(form[buildItem.type]).some(
      (value) => {
        return value === "";
      }
    );

    return !hasEmptyProperty;
  };

  const returnDefaultPowerOutput = (energyType: string, size: GridItemSize) => {
    let powOutput = 0;
    if (energyType === "Hydroelectric") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 10;
      } else if (size === GridItemSize.HALF) {
        powOutput = 250;
      }
    }
    if (energyType === "Natural gas") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 200;
      } else if (size === GridItemSize.HALF) {
        powOutput = 1000;
      }
    }
    if (energyType === "Coal") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 300;
      } else if (size === GridItemSize.HALF) {
        powOutput = 1250;
      }
    }
    if (energyType === "Nuclear") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 3000;
      } else if (size === GridItemSize.HALF) {
        powOutput = 4000;
      }
    }
    if (energyType === "Reservoir") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 10;
      } else if (size === GridItemSize.HALF) {
        powOutput = 50;
      }
    }
    if (energyType === "Biomass") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 3;
      } else if (size === GridItemSize.HALF) {
        powOutput = 20;
      }
    }
    if (energyType === "Petroleum") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 25;
      } else if (size === GridItemSize.HALF) {
        powOutput = 75;
      }
    }
    if (energyType === "Solar") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 20;
      } else if (size === GridItemSize.HALF) {
        powOutput = 80;
      }
    }
    if (energyType === "Wind") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 20;
      } else if (size === GridItemSize.HALF) {
        powOutput = 100;
      }
    }
    if (energyType === "Pumped storage") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 50;
      } else if (size === GridItemSize.HALF) {
        powOutput = 100;
      }
    }
    if (energyType === "Battery storage") {
      if (size === GridItemSize.QUARTER) {
        powOutput = 5;
      } else if (size === GridItemSize.HALF) {
        powOutput = 10;
      }
    }
    return powOutput;
  };

  const handleStructureSizeChange = (option: {
    value: GridItemSize;
    label: string;
  }) => {
    setBuildItem({
      ...buildItem,
      size: option.value,
    });

    if (buildItem.type === GridItemType.POWER_PLANT) {
      const powOutput = returnDefaultPowerOutput(
        form[GridItemType.POWER_PLANT].energyType.toString(),
        option.value
      );
      setForm((prev) => ({
        ...prev,
        [GridItemType.POWER_PLANT]: {
          ...prev[buildItem.type],
          powerOutput: powOutput.toString(),
        },
      }));
    }
  };

  const handleOnChangeForm = (input: string | number, id: string) => {
    if (buildItem.type === GridItemType.POWER_PLANT) {
      let powOutput = 0;
      if (id !== "energyType") {
        // Do nothing
      } else if (id === "energyType") {
        powOutput = returnDefaultPowerOutput(input.toString(), buildItem.size);
      }
      setForm((prev) => ({
        ...prev,
        [GridItemType.POWER_PLANT]: {
          ...prev[buildItem.type],
          [id]: input,
          powerOutput: powOutput.toString(),
        },
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [buildItem.type]: {
          ...prev[buildItem.type],
          [id]: input,
        },
      }));
    }
  };

  if (buildItem.state === "form")
    return (
      <>
        <FormRow label="Structure Size">
          <Select
            id="structureSize"
            className="w-[190px]"
            value={{
              value: buildItem.size,
              label: `${getItemSizeString(buildItem.size)} ${buildItem.type}`,
            }}
            options={sizeSelectOptions}
            onChange={handleStructureSizeChange}
          />
        </FormRow>
        {dynamicForm[buildItem.type].formData.map((data: FormData) => {
          return (
            <FormRow key={data.id} label={data.label}>
              {data.options ? (
                <Select
                  id={data.id}
                  options={data.options}
                  value={{
                    value: form[buildItem.type][data.id]
                      ? form[buildItem.type][data.id]
                      : "Select an option..",
                    label: form[buildItem.type][data.id]
                      ? (form[buildItem.type][data.id] as string)
                      : "Select an option..",
                  }}
                  onChange={(option) =>
                    handleOnChangeForm(option.value as string, data.id)
                  }
                  placement={data.placement}
                />
              ) : (
                <Input
                  id={data.id}
                  type={data.type}
                  name={data.name}
                  value={form[buildItem.type][data.id]}
                  onChange={(event) => {
                    let val: string | number;
                    if (event.target.type === "number")
                      val = parseFloat(event.target.value);
                    else val = event.target.value;
                    handleOnChangeForm(val, data.id);
                  }}
                  disabled={data.disabled}
                />
              )}
            </FormRow>
          );
        })}
        <Button
          className="w-full"
          color="primary"
          onClick={handleFormBtnClick}
          disabled={!isFormFilled()}
        >
          Build
        </Button>
      </>
    );
  if (buildItem.state === "submit")
    return (
      <div className="pt-3 text-gray-500 space-y-4">
        <div key="structureSize">
          <div className="flex flex-row place-content-between">
            <p className="font-medium text-base-black">Structure Size:</p>
            <p>{`${getItemSizeString(buildItem.size)} ${buildItem.type}`}</p>
          </div>
          <hr className="text-base-gray-80 my-1" />
        </div>
        {dynamicForm[buildItem.type].formData.map((data: FormData) => (
          <div key={data.id}>
            <div className="flex flex-row place-content-between">
              <p className="font-medium text-base-black">{data.label}:</p>
              <p>{form[buildItem.type][data.id]}</p>
            </div>
            <hr className="text-base-gray-80 my-1" />
          </div>
        ))}
        <Button
          className="w-full"
          color="primary"
          icon={<CheckIcon />}
          onClick={handleSubmitBtnClick}
          disabled={!isFormFilled()}
        >
          Build
        </Button>
        {message && <div className="text-base-red">{message}</div>}
      </div>
    );
  return null;
}

export default BuildForm;
