import React, { useContext } from "react";
import { MapLayer } from "../../../common/types/types";
import SimulatorContext from "../../context/SimulatorContext";
import CustomRadioGroup, {
  RadioGroupOption,
} from "../../../common/components/CustomRadioGroup";

function LayersModal() {
  const { selectedLayer, setSelectedLayer } = useContext(SimulatorContext);

  const options: RadioGroupOption[] = [
    {
      value: MapLayer.DEFAULT,
      label: "Default",
      image: "/images/layer_default.jpg",
    },
    {
      value: MapLayer.GRAD_RATE,
      label: MapLayer.GRAD_RATE,
      image: "/images/layer_grad_rate.jpg",
    },
    {
      value: MapLayer.INCOME,
      label: MapLayer.INCOME,
      image: "/images/layer_income.jpg",
    },
    {
      value: MapLayer.POPULATION,
      label: MapLayer.POPULATION,
      image: "/images/layer_population.jpg",
    },
  ];

  return (
    <div className="flex flex-col w-72">
      <CustomRadioGroup
        options={options}
        value={selectedLayer}
        onChange={(val) => {
          setSelectedLayer(val as MapLayer);
        }}
      />
    </div>
  );
}

export default LayersModal;
