/* eslint-disable import/first */
/* eslint-disable react/require-default-props */

// Redecalare forwardRef
declare module "react" {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import React, {
  useContext,
  Fragment,
  ForwardedRef,
  forwardRef,
  useCallback,
  useState,
} from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useFloating, offset, Placement } from "@floating-ui/react";
import { ToolbarContext } from "./Toolbar";
import { SelectOption } from "../types/types";

// Use the Select component in the parent component through the following steps :-
//
//  1) Store a modified array of options in the parent component
//  For example:-
//  const users = [
//   { firstName: "Jeff", lastName: "Byju" },
//   { firstName: "Spencer", lastName: "Creer" },
//   { firstName: "Allen", lastName: "Lin" },
//   { firstName: "Aaron", lastName: "Edwards" },
//  ];
// const userOptions: SelectOption[] = useMemo(() => {
//   return users.map((user) => ({
//     label: user.firstName,
//     value: user,
//   }));
//  }, [users]);
//
//  2) Have a state variable in the parent component to keep track of the option selected by the user
//  For example:-
//    const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
//      null
//    );
//
//  3) Use the Select component in the parent component by providing the required props and the optional ones

interface SelectProps<ValueType, OptionType extends SelectOption<ValueType>> {
  id?: string;
  options: OptionType[];
  label?: string;
  placeholder?: string;
  value?: OptionType | null;
  onChange?: (value: OptionType) => void;
  disabled?: boolean;
  className?: string;
  placement?: Placement;
  citySelect?: boolean;
}

function Select<ValueType, OptionType extends SelectOption<ValueType>>(
  {
    id,
    options,
    label,
    placeholder = "Select an option...",
    value: providedValue,
    onChange: providedOnChange,
    disabled,
    className,
    placement = "bottom-start",
    citySelect = false,
  }: SelectProps<ValueType, OptionType>,
  ref?: ForwardedRef<HTMLSpanElement>
) {
  const compareOptions = (a?: OptionType, b?: OptionType) => {
    if (a && b) return a.label === b.label;
    return false;
  };

  const { x, y, reference, floating, strategy } = useFloating({
    placement,
    middleware: [offset(5)],
  });

  const toolbarContext = useContext(ToolbarContext);

  const [value, setValue] = useState(providedValue);

  const onChange = useCallback(
    (val: OptionType) => {
      if (providedOnChange) {
        providedOnChange(val);
      } else {
        setValue(val);
      }
    },
    [providedOnChange]
  );

  return (
    <div
      id={id}
      className={`min-w-max w-full flex items-center ${className ?? ""}`}
    >
      <Listbox
        value={providedValue}
        onChange={onChange}
        by={compareOptions}
        disabled={disabled ?? false}
      >
        <>
          <div className="flex flex-col gap-2 w-full">
            {label && toolbarContext == null && (
              <Listbox.Label className="text-sm font-medium">
                {label}
              </Listbox.Label>
            )}
            <Listbox.Button
              ref={reference}
              className="relative w-full cursor-pointer disabled:opacity-70 disabled:cursor-not-allowed rounded-md bg-white py-2 pl-3 pr-10 text-left border border-gray-200 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <div className="flex flex-row gap-1 truncate">
                {label && toolbarContext != null && <span>{label}</span>}
                <span ref={ref}>
                  {providedValue?.label ?? value?.label ?? placeholder}
                </span>
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              ref={floating}
              className="w-max max-h-96 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: "max-content",
              }}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.label}
                  className={({ active }) =>
                    `relative select-none py-2 pl-10 pr-4 ${
                      option.disabled
                        ? "opacity-50 cursor-not-allowed"
                        : "opacity-100 cursor-pointer"
                    } ${
                      !citySelect && active
                        ? "bg-blue-200 text-blue-900"
                        : "hover:bg-blue-200 hover:text-blue-900 text-gray-900"
                    }`
                  }
                  value={option}
                  disabled={option.disabled}
                >
                  {({ selected }) => {
                    return (
                      <>
                        <span
                          className={`block truncate ${
                            selected && !citySelect
                              ? "font-medium"
                              : "font-normal"
                          }`}
                        >
                          {option.label}
                        </span>
                        {selected && !citySelect ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    );
                  }}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      </Listbox>
    </div>
  );
}

export default forwardRef(Select);
