/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Simulation, StatusType } from "@rodel-futures-simulator/types";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/solid";
import { useGetSimulations } from "../../api/queries";
import { formatDate } from "../../common/utils/formatUtility";
import usePrevious from "../../common/hooks/usePrevious";
import LoadingSimTable from "./LoadingSimTable";
import Card from "../../common/components/Card";
import Button from "../../common/components/Button";
import Spinner from "../../common/components/Spinner";
import Tag from "../../common/components/Tag";
import Tooltip from "../../common/components/Tooltip";

function DashboardCards({ handleCreateSim }: { handleCreateSim: () => void }) {
  const { data, isError, isLoading } = useGetSimulations();
  const simulations = data?.data;
  const navigate = useNavigate();

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const prevCardIndex = usePrevious(currentCardIndex);
  const progressing = currentCardIndex > prevCardIndex;

  function getHeader(status: StatusType) {
    switch (status) {
      case StatusType.FAILED:
        return <Tag text="Failed" type="danger" />;
      case StatusType.IN_PROGRESS:
        return <Tag text="Processing" type="primary" />;
      default:
        return <Tag text="Completed" type="success" />;
    }
  }

  function getFooter(simulation: Simulation) {
    switch (simulation.status) {
      case StatusType.FAILED:
        return null;
      case StatusType.IN_PROGRESS:
        return (
          <div className="flex flex-row place-content-end mt-2 mb-1">
            <Spinner className="h-8 w-8" />
          </div>
        );
      default:
        return (
          <div className="flex flex-row place-content-end mt-2">
            <Tooltip
              text="Results"
              position="bottom"
              onClick={() => {
                navigate(
                  simulation._id ? `/simulation/summary/${simulation._id}` : ""
                );
              }}
              icon={
                <ChartBarSquareIcon
                  color="#27597B"
                  className="cursor-pointer h-8 w-8 mb-1"
                />
              }
            />
            <Tooltip
              text="Map"
              position="bottom"
              onClick={() => {
                navigate(simulation._id ? `/simulation/${simulation._id}` : "");
              }}
              icon={
                <PlayCircleIcon
                  color="#27597B"
                  className="cursor-pointer h-8 w-8 mb-1"
                />
              }
            />
          </div>
        );
    }
  }

  function getCardDeck(index: number) {
    if (index >= currentCardIndex + 1) return ["rightDeck", "66.7%"];
    if (index === currentCardIndex) return ["middleDeck", "33.3%"];
    return ["leftDeck", "0%"];
  }

  if (isLoading) return <LoadingSimTable />;

  if (isError || !simulations)
    return <div className="text-base-red">Error loading simulations.</div>;

  return (
    <div className="px-12 py-8 h-[468px]">
      <div className="relative h-[400px] w-[570px]">
        <h2>Simulator Dashboard</h2>
        <p className="text-base-black">
          Create a new simulation below to get started
        </p>
        <hr className="text-base-gray-80 mt-4 mb-6" />
        <p className="font-medium mb-4">Current Simulations</p>
        <div className="relative w-[584px]">
          <div
            className="grid h-[208px] w-[180px] border-2 border-dashed rounded-lg place-content-center cursor-pointer"
            onClick={handleCreateSim}
            style={{
              position: "absolute",
              left: "0%",
              transition: "left 0.6s ease",
            }}
          >
            <PlusCircleIcon className="w-12 h-12 mx-auto" color="#959595" />
            <div className="mx-auto text-xl text-base-gray-100 text-center">
              Create New Simulation
            </div>
          </div>
          {simulations
            .slice()
            .reverse()
            .map((simulation, i) => {
              const [deck, position] = getCardDeck(i);
              return (
                <Card
                  style={{
                    position: "absolute",
                    left: position,
                    transition: "left 0.6s ease",
                    zIndex:
                      deck === "rightDeck" ||
                      (deck === "middleDeck" && progressing)
                        ? simulations.length - i
                        : i,
                  }}
                  key={simulation._id}
                  title={simulation.name.substring(0, 12)}
                  header={getHeader(simulation.status)}
                  footer={getFooter(simulation)}
                >
                  <div className="mt-2">
                    {simulation.createdAt && (
                      <p className="text-sm">
                        {formatDate(new Date(simulation.createdAt))}
                      </p>
                    )}
                    {simulation.totalNumTimesteps && (
                      <p className="text-sm">
                        Timesteps: {simulation.totalNumTimesteps}
                      </p>
                    )}
                  </div>
                </Card>
              );
            })}
        </div>
        <div className="absolute bottom-0 right-0">
          <Button
            color="secondary"
            className="border-[1px] mr-2"
            icon={<ChevronLeftIcon />}
            disabled={currentCardIndex === 0}
            onClick={() => {
              setCurrentCardIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            }}
          />
          <Button
            color="secondary"
            className="border-[1px]"
            icon={<ChevronRightIcon />}
            disabled={currentCardIndex === simulations.length - 2}
            onClick={() => {
              setCurrentCardIndex((prevIndex) =>
                Math.min(prevIndex + 1, simulations.length - 1)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardCards;
