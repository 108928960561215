/* eslint-disable react/require-default-props */
import React from "react";

interface SpinnerProps {
  color?: string;
  className?: string;
}

export default function Spinner({
  color = "#27597B",
  className,
}: SpinnerProps) {
  return (
    <svg
      className={className ? `animate-spin ${className}` : "animate-spin"}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42 23C42 33.4934 33.4934 42 23 42C12.5066 42 4 33.4934 4 23C4 12.5066 12.5066 4 23 4C33.4934 4 42 12.5066 42 23ZM7.8 23C7.8 31.3947 14.6053 38.2 23 38.2C31.3947 38.2 38.2 31.3947 38.2 23C38.2 14.6053 31.3947 7.8 23 7.8C14.6053 7.8 7.8 14.6053 7.8 23Z"
        fill={color}
        fillOpacity="0.1"
      />
      <path
        d="M23 40.1C23 41.1493 22.1472 42.0098 21.1031 41.9051C18.6382 41.6578 16.239 40.9301 14.0435 39.7565C11.2878 38.2836 8.93799 36.1538 7.20208 33.5558C5.46616 30.9579 4.39775 27.9718 4.09149 24.8623C3.78523 21.7528 4.25057 18.6157 5.44629 15.729C6.64201 12.8423 8.5312 10.295 10.9465 8.3128C13.3619 6.3306 16.2288 4.97465 19.2933 4.36508C22.3578 3.75551 25.5254 3.91112 28.5154 4.81813C30.8977 5.5408 33.1088 6.72271 35.0266 8.29079C35.839 8.95501 35.8335 10.1665 35.0915 10.9085C34.3495 11.6505 33.1529 11.6395 32.3241 10.9958C30.8603 9.85875 29.1963 8.99568 27.4123 8.45451C25.0203 7.7289 22.4862 7.60441 20.0346 8.09206C17.583 8.57972 15.2895 9.66448 13.3572 11.2502C11.425 12.836 9.91361 14.8738 8.95703 17.1832C8.00045 19.4926 7.62818 22.0022 7.87319 24.4899C8.1182 26.9775 8.97293 29.3663 10.3617 31.4447C11.7504 33.5231 13.6303 35.2269 15.8348 36.4052C17.4789 37.284 19.2658 37.8503 21.1049 38.0814C22.1461 38.2122 23 39.0507 23 40.1Z"
        fill={color}
      />
    </svg>
  );
}
