import { GridItemType, Neighborhood } from "@rodel-futures-simulator/types";
import React, {
  useState,
  useEffect,
  useContext,
  FormEventHandler,
} from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { calcAvailableFunds } from "../../utils/investUtils";
import { formatDollarValue } from "../../../common/utils/formatUtility";
import SimulatorContext from "../../context/SimulatorContext";
import Button from "../../../common/components/Button";
import Input from "../../../common/components/Input";
import FormRow from "../../../common/components/FormRow";
import Select from "../../../common/components/Select";

interface NeighborhoodFormProps {
  neighborhood: Neighborhood;
}

interface NeighborhoodIntervention extends Neighborhood {
  investment?: number;
  allocation?: string;
}

function NeighborhoodInterventionForm({ neighborhood }: NeighborhoodFormProps) {
  const { interventions, setInterventions } = useContext(SimulatorContext);

  const [editInvestment, setEditInvestment] = useState<boolean>(true);
  const [neighborhoodFormState, setNeighborhoodFormState] =
    useState<NeighborhoodIntervention>({
      ...neighborhood,
      investment: undefined,
      allocation: undefined,
    });
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const foundInvestment = interventions.investments.find(
      (investment) =>
        investment.xCoord === neighborhood.xCoord &&
        investment.yCoord === neighborhood.yCoord
    );

    if (foundInvestment) {
      setEditInvestment(false);
      setNeighborhoodFormState({
        ...neighborhood,
        investment: foundInvestment.amount,
        allocation: foundInvestment.allocation,
      });
    }
  }, [
    interventions.investments,
    neighborhood,
    neighborhood.xCoord,
    neighborhood.yCoord,
  ]);

  const submitAddGridItem: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const buildCost = neighborhoodFormState.investment;
    const availableFunds = calcAvailableFunds(interventions);

    if (!buildCost) {
      setMessage("You must invest more than $0.");
    } else if (buildCost <= availableFunds) {
      setInterventions((prevInterventions) => {
        let investmentFound = false;
        const updatedInvestments = prevInterventions.investments.map(
          (investment) => {
            if (
              investment.xCoord === neighborhood.xCoord &&
              investment.yCoord === neighborhood.yCoord
            ) {
              investmentFound = true;
              return {
                type: GridItemType.NEIGHBORHOOD,
                size: neighborhood.size,
                xCoord: neighborhood.xCoord,
                yCoord: neighborhood.yCoord,
                amount: buildCost,
                allocation: neighborhoodFormState.allocation
                  ? neighborhoodFormState.allocation
                  : "",
              };
            }
            return investment;
          }
        );

        if (!investmentFound) {
          updatedInvestments.push({
            type: GridItemType.NEIGHBORHOOD,
            size: neighborhood.size,
            xCoord: neighborhood.xCoord,
            yCoord: neighborhood.yCoord,
            amount: buildCost,
            allocation: neighborhoodFormState.allocation
              ? neighborhoodFormState.allocation
              : "",
          });
        }

        return {
          ...prevInterventions,
          investments: updatedInvestments,
        };
      });
    } else {
      setMessage("You do not have enough funds to make this investment");
    }
  };

  return (
    <div>
      {neighborhoodFormState.investment && !editInvestment ? (
        <div>
          <div className="flex justify-end">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setEditInvestment(true);
              }}
              icon={
                <PencilIcon className="h-3 w-3 mr-0.5 mt-0.5 text-blue-500" />
              }
            >
              <div className="text-xs text-blue-500">Edit</div>
            </Button>
          </div>
          <div>
            You have invested{" "}
            <span className="font-bold text-base-rodel-100">
              {formatDollarValue(neighborhoodFormState.investment)}
            </span>{" "}
            in this neighborhood
          </div>
        </div>
      ) : (
        <>
          <form onSubmit={submitAddGridItem}>
            <div className="flex flex-col gap">
              <FormRow label="Investment ($)">
                <Input
                  id="investment"
                  type="number"
                  min={0}
                  step="any"
                  name="investment"
                  value={neighborhoodFormState.investment ?? 0}
                  onChange={(event) => {
                    const investment = parseFloat(event.target.value);
                    setNeighborhoodFormState({
                      ...neighborhoodFormState,
                      investment,
                    });
                  }}
                />
              </FormRow>
              <FormRow label="Allocation">
                <Select
                  value={
                    neighborhoodFormState.allocation
                      ? {
                          value: neighborhoodFormState.allocation,
                          label: neighborhoodFormState.allocation,
                        }
                      : null
                  }
                  options={[
                    { value: "Transportation", label: "Transportation" },
                    { value: "Lighting", label: "Lighting" },
                    {
                      value: "Parks and Recreation",
                      label: "Parks and Recreation",
                    },
                  ]}
                  onChange={(option) => {
                    setMessage("");
                    setNeighborhoodFormState({
                      ...neighborhoodFormState,
                      allocation: option.value,
                    });
                  }}
                />
              </FormRow>
              <Button type="submit">Submit</Button>
            </div>
          </form>
          {message && <div className="text-base-red-100 mt-3">{message}</div>}
        </>
      )}
    </div>
  );
}

export default NeighborhoodInterventionForm;
