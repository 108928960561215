import React from "react";
import Spinner from "../common/components/Spinner";

function LoadingPage({ text }: { text: string }) {
  return (
    <div className="gradient fixed text-base-white top-0 left-0 w-screen h-screen flex flex-col justify-center items-center font-bold ui-container z-60">
      <Spinner color="white" className="w-16 h-16" />
      <div>{text}</div>
    </div>
  );
}

export default LoadingPage;
