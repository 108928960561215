function getFilterHue(min: number, max: number, val: number) {
  const percentage = Math.min(1, Math.max(0, val - min) / (max - min));

  const maxHue = 130;
  const minHue = 0;
  const hue = percentage * (maxHue - minHue) + minHue;
  return `hsl(${hue}, 100%, 75%)`;
}

export default getFilterHue;
