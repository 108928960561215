interface FilterRaycasterIntersectsParams {
  intersectObjects: THREE.Intersection<THREE.Object3D<THREE.Event>>[];
  filter: string;
}

function filterRaycasterIntersects({
  intersectObjects,
  filter,
}: FilterRaycasterIntersectsParams) {
  const filteredIntersects = intersectObjects.filter(
    (intersect: THREE.Intersection<THREE.Object3D<THREE.Event>>) =>
      intersect.object.name === filter
  );
  return filteredIntersects;
}

export default filterRaycasterIntersects;
