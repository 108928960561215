/* eslint-disable react/require-default-props */
import React from "react";

interface TagProps {
  type?: "primary" | "success" | "danger";
  text?: string;
  icon?: React.ReactNode;
}

export default function Tag({ type = "primary", text, icon }: TagProps) {
  let className: string;

  switch (type) {
    case "danger":
      className =
        "flex flex-row text-base-red-110 bg-base-red-90 w-fit h-fit py-1 px-2 rounded-full text-xs items-center";
      break;
    case "success":
      className =
        "flex flex-row text-base-green-110 bg-base-green-40 w-fit h-fit py-1 px-2 rounded-full text-xs items-center";
      break;
    default:
      className =
        "flex flex-row text-base-blue-110 bg-base-blue-50 w-fit h-fit py-1 px-2 rounded-full text-xs items-center";
      break;
  }
  return (
    <div className={className}>
      <div>{text}</div>
      {icon && <span className="h-3 w-3 ml-1">{icon}</span>}
    </div>
  );
}
