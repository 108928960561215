import React, { useContext, useMemo, Dispatch } from "react";
import { PencilIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import SimulatorContext from "../../context/SimulatorContext";
import { colors } from "../../../common/constants";
import Input from "../../../common/components/Input";
import { formatPercentage } from "../../../common/utils/formatUtility";
import DoughnutChart from "../../../common/components/DoughnutChart";
import Tooltip from "../../../common/components/Tooltip";
import Button from "../../../common/components/Button";

interface BudgetProps {
  setEditBudget: Dispatch<React.SetStateAction<boolean>>;
}

export default function Budget({ setEditBudget }: BudgetProps) {
  const { mapState } = useContext(SimulatorContext);

  const budgetData = useMemo(() => {
    return mapState.budget.categories.map(
      ({ name, amountInMillions }, index) => ({
        label: name,
        value: amountInMillions,
        color: colors[index % colors.length],
      })
    );
  }, [mapState.budget.categories]);

  const totalBudget = budgetData.reduce(
    (acc, category) => acc + category.value,
    0
  );

  return (
    <div className="flex flex-col mt-3 h-[565px]">
      <div className="flex justify-center">
        <DoughnutChart
          data={budgetData}
          centerValue={formatPercentage(1, 1)}
          label="Allocated Budget"
        />
      </div>
      <div>
        <div className="flex justify-between my-2">
          <div className="text-xs text-base-black flex items-center font-normal">
            Industry Budget (in millions)&nbsp;
            <Tooltip
              icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
              text="Industry budgets divided into spending categories."
              position="bottom"
            />
          </div>
          <div className="ml-20">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setEditBudget(true);
              }}
              icon={
                <PencilIcon className="h-3 w-3 mr-0.5 mt-0.5 text-blue-500" />
              }
            >
              <div className="text-xs text-blue-500">Edit</div>
            </Button>
          </div>
        </div>
        {budgetData.map((category, index, categories) => {
          return (
            <div key={category.label}>
              <div className="flex justify-between">
                <div className="flex items-center space-x-2">
                  <div
                    className="h-3 w-3 rounded-full mr-3"
                    style={{
                      backgroundColor: colors[index % colors.length],
                    }}
                  />
                  <p className="font-medium">{category.label}</p>
                </div>
                <Input
                  type="text"
                  className="w-16"
                  value={formatPercentage(category.value / totalBudget, 1)}
                  disabled
                />
              </div>
              {index < categories.length - 1 && (
                <hr className="text-base-gray-80 my-1" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
