// Simulation types shared between both server and client only
// If a type is exclusive to the client save in ./packages/client/src/common

export interface Simulation {
  _id: string;
  name: string;
  totalNumTimesteps?: number;
  status: StatusType;
  message?: string;
  budget: Budget;
  policies: Policies;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface TimestepDetails {
  _id?: string;
  step: number;
  population: number;
  totalNumStudents: number;
  totalNumSchools: number;
  avgGraduationRate: number | null;
  totalEmissions: number;
  annualCarbon: number;
  annualGradRate: number;
  annualPopulation: number;
  annualUnemployment: number;
  postHSEnrollment: number;
  annualHouseholdIncome: number;
}

export enum StatusType {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum GridItemType {
  BUSINESS = "Business",
  NEIGHBORHOOD = "Neighborhood",
  SCHOOL = "School",
  POWER_PLANT = "Power Plant",
  DESERT = "Desert",
  CONSTRUCTION = "Construction",
}

export enum PowerPlantType {
  BATTERY_STORAGE = "Battery storage",
  PUMPED_STORAGE = "Pumped storage",
  WIND = "Wind",
  SOLAR = "Solar",
  HYDROELECTRIC = "Hydroelectric",
  BIOMASS = "Biomass",
  PETROLEUM = "Petroleum",
  NATURAL_GAS = "Natural gas",
  COAL = "Coal",
  NUCLEAR = "Nuclear",
  RESERVOIR = "Reservoir",
}

export enum SchoolType {
  PUBLIC = "Public",
  PRIVATE = "Private",
  CHARTER = "Charter",
}

export enum GridItemSize {
  QUARTER = "025",
  HALF = "050",
  FULL = "100",
}

export enum IndustryType {
  MGBIZFIN = "Management and Finance",
  COMPENGSCI = "Engineering and Science",
  EDULEGCOMARTMEDIA = "Education, Legal, and Arts",
  HEALTHTECHNICAL = "Healthcare",
  HEALTHSUPPORT = "Healthcare Support",
  PROTECTIVESERV = "Protective Services",
  FOODPREP = "Food Preparation",
  GROUNDSMAINT = "Grounds Maintenance",
  PERSONALCARE = "Personal Care",
  SALESOCC = "Sales Occupations",
  OFFICEADMIN = "Office and Admin Support",
  FARMFISH = "Farming and Fishing",
  CONSTRUCTION = "Construction",
  INSTREPAIR = "Installation and Repair",
  PRODUCTION = "Production",
  TRANSPORTATION = "Transportation",
  MATERIALMOVING = "Material Moving",
  NONE = "None",
}

export enum EducationLevel {
  LT9GRADE = "Less than 9th Grade",
  NOHSDIPLOMA = "No High School Diploma",
  HSGRAD = "High School Graduate",
  SOMECOLLEGE = "Some College",
  ASSOCIATES = "Associates Degree",
  BACHELORS = "Bachelors Degree",
  GRADPROF = "Graduate or Professional Degree",
  NONE = "None",
}

export enum FundingType {
  FEDERAL = "Federal Funding",
  STATE = "State Funding",
  LOCAL = "Local Funding",
  NONE = "None",
}

export interface AbstractGridItem {
  _id?: string;
  type: GridItemType;
  xCoord: number;
  yCoord: number;
  size: GridItemSize;
  rotation?: number;
}

export enum SchoolLevel {
  ELEMENTARY = "Elementary",
  MIDDLE = "Middle",
  HIGH = "High",
  UNIVERSITY = "University",
}

export type IndustryInfo = {
  name: IndustryType;
  numWorkers: number;
};

export type EducationLevelInfo = {
  name: EducationLevel;
  numResidents: number;
};

export type FundingInfo = {
  name: FundingType;
  funding: number;
};

export interface School extends AbstractGridItem {
  type: GridItemType.SCHOOL;
  name: string;
  numberOfStudents?: number;
  graduationRate?: number;
  schoolFundingInfo?: FundingInfo[];
  // powerConsumption: number;
  // carbonEmission: number;
  schoolType: SchoolType;
  schoolLevel: SchoolLevel;
  hasGrade12: boolean;
  district?: string;
}

export interface Business extends AbstractGridItem {
  type: GridItemType.BUSINESS;
  numberOfEmployees: number;
  industryInfo?: IndustryInfo[];
  numberOfBusinesses?: number;
  // powerConsumption: number;
  // carbonEmission: number;
}

export interface Neighborhood extends AbstractGridItem {
  type: GridItemType.NEIGHBORHOOD;
  educationLevelInfo?: EducationLevelInfo[];
  numberOfResidents: number;
  numberOfHouseholds: number;
  avgHouseholdSalary: number;
  // powerConsumption: number;
  // carbonEmission: number;
}

export interface PowerPlant extends AbstractGridItem {
  type: GridItemType.POWER_PLANT;
  name: string;
  powerOutput: number;
  powerType: PowerPlantType;
}

export interface DesertTile extends AbstractGridItem {
  type: GridItemType.DESERT;
}

export interface Construction extends AbstractGridItem {
  type: GridItemType.CONSTRUCTION;
  constructionType:
    | GridItemType.BUSINESS
    | GridItemType.SCHOOL
    | GridItemType.NEIGHBORHOOD
    | GridItemType.POWER_PLANT;
}

export type GridItem =
  | School
  | Business
  | Neighborhood
  | PowerPlant
  | DesertTile
  | Construction;

export interface Investment {
  type: GridItemType;
  size: GridItemSize;
  xCoord: number;
  yCoord: number;
  amount: number;
  allocation: string;
}

export interface SchoolInvestment extends Investment {
  type: GridItemType.SCHOOL;
  name: string;
  predictions: {
    gradRate?: number;
    capacity?: number;
  };
}

export interface Intervention {
  investments: Investment[];
  schoolInvestments: SchoolInvestment[];
  builds: AbstractGridItem[];
  budget: Budget;
  policies: Policies;
}

export interface BudgetCategory {
  name: string;
  abbr?: string;
  description?: string;
  amountInMillions: number;
}
export interface Policies {
  salesTax: number;
  schoolsOfferingDE: number;
  studentsParticipationDE: number;
  thirdGradLit: number;
}
export interface Budget {
  totalInMillions?: number;
  categories: BudgetCategory[];
}

export interface EmissionCategory {
  name: string;
  amount: number;
}

export interface Emissions {
  total?: number;
  categories: EmissionCategory[];
}

export interface Timestep {
  _id?: string;
  step: number;
  simulationId?: string;
  schoolGridItems: School[];
  businessGridItems: Business[];
  residentialGridItems: Neighborhood[];
  powerGridItems: PowerPlant[];
  constructionGridItems: Construction[];
  budget: Budget;
  createdAt?: Date;
  updatedAt?: Date;
  emissions: Emissions;
  annualPopulation?: number;
  annualGradRate?: number;
  annualUnemployment?: number;
  annualCarbon?: number;
  postHSEnrollment?: number;
  annualHouseholdIncome?: number;
}

export interface Seed {
  schoolGridItems: School[];
  businessGridItems: Business[];
  residentialGridItems: Neighborhood[];
  powerGridItems: PowerPlant[];
  budget: Budget;
  emissions: Emissions;
  annualPopulation?: number;
  annualGradRate?: number;
  annualUnemployment?: number;
  annualCarbon?: number;
  postHSEnrollment?: number;
  annualHouseholdIncome?: number;
}

export type Coords = [number, number, number];

export interface City {
  _id?: string;
  name: string;
  centerCoords: Coords;
  borderCoords: Coords[];
}

export interface County {
  _id?: string;
  name: string;
  centerCoords: Coords;
  borderCoords: Coords[];
  cities: City[];
}

export type Block = { x: number; y: number; size: number };

export type SimulatorRouteState = {
  state: Simulation;
};
