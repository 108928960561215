/* eslint-disable react/no-unknown-property */
import React from "react";

function Lights({ intensity }: { intensity: number }) {
  return (
    <>
      <ambientLight color="#FFFFFF" intensity={intensity} />
      <pointLight position={[100, 200, 0]} intensity={0.55} />
    </>
  );
}

export default Lights;
