import { Timestep } from "@rodel-futures-simulator/types";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useGetTimestepRange } from "../../api/queries";

interface UseTimerParams {
  simulationId: string;
  finalTimestep: number;
}

const useTimer = ({ simulationId, finalTimestep }: UseTimerParams) => {
  const [timestep, setTimestep] = useState<number>(0);
  // const [timestepRangeMin, setTimestepRangeMin] = useState<number>(0);
  const [mapState, setMapState] = useState<Timestep>({
    step: 0,
    schoolGridItems: [],
    residentialGridItems: [],
    businessGridItems: [],
    powerGridItems: [],
    constructionGridItems: [],
    budget: {
      totalInMillions: 0,
      categories: [],
    },
    emissions: {
      categories: [],
    },
  });
  const [playing, setPlaying] = useState<boolean>(false);
  const [speed, setSpeed] = useState<number>(1);
  // const [networkPause, setNetworkPause] = useState<boolean>(false);

  let timestepData: Timestep[] = useMemo(() => [], []);
  const { data, isLoading: loadingMap } = useGetTimestepRange({
    simulationId,
    minStep: 0,
    maxStep: 9,
  });
  if (data) {
    timestepData = data.data;
  }

  const play = () => {
    if (timestep === finalTimestep) {
      setTimestep(0);
    }
    setPlaying(true);
  };

  const stop = () => setPlaying(false);

  const reset = useCallback(() => {
    setPlaying(false);
    setTimestep(0);
    // setTimestepRangeMin(0);
  }, []);

  // Set interval to increment timestep if playing and not paused due to slow network.
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (
      playing
      // && !networkPause
    ) {
      interval = setInterval(() => {
        setTimestep((currentTimestep: number) => {
          const nextTimestep = currentTimestep + 1;
          if (currentTimestep < finalTimestep) {
            return nextTimestep;
          }
          return currentTimestep;
        });
      }, 1000 / speed);
    }

    return () => clearInterval(interval);
  }, [
    playing,
    // networkPause,
    speed,
    finalTimestep,
  ]);

  // Request a new timestep range every 10 timesteps.
  // useEffect(() => {
  //   const nextTimestep = timestep + 1;
  //   if (nextTimestep % 10 === 0) {
  //     setTimestepRangeMin(timestep);
  //   }
  // }, [timestep]);

  // Pause timer if the next timestep is greater than the last timestep in timestepData.
  // Else play timer if it was paused due to slow network.
  // useEffect(() => {
  //   const nextTimestep = timestep + 1;
  //   if (timestepData.length < 1) return;
  //   if (
  //     playing &&
  //     nextTimestep > timestepData[timestepData.length - 1].step &&
  //     timestep !== finalTimestep
  //   ) {
  //     setNetworkPause(true);
  //   } else if (
  //     (networkPause &&
  //       nextTimestep < timestepData[timestepData.length - 1].step) ||
  //     timestepData[timestepData.length - 1].step === finalTimestep
  //   ) {
  //     setNetworkPause(false);
  //   }
  // }, [playing, networkPause, timestep, timestepData, finalTimestep]);

  // Stop timer if current timestep is greater than final timestep.
  useEffect(() => {
    if (timestep >= finalTimestep) {
      stop();
    }
  }, [playing, timestep, finalTimestep]);

  useEffect(() => {
    if (timestepData.length > 0) {
      const gridData = timestepData.find(
        ({ step }: Timestep) => timestep === step
      );
      if (gridData) {
        setMapState(gridData);
      }
    }
  }, [timestep, timestepData]);

  return {
    loadingMap,
    mapState,
    playing,
    play,
    stop,
    reset,
    speed,
    setSpeed,
    // networkPause,
    setTimestep,
    // setTimestepRangeMin,
  };
};

export default useTimer;
