/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  Ref,
  useContext,
  useMemo,
} from "react";
import { ToolbarContext } from "./Toolbar";
import Spinner from "./Spinner";

type ButtonVariant = "standard" | "outline";
type ButtonColor = "primary" | "secondary" | "success" | "danger";

const ButtonGroupContext = React.createContext<ButtonGroupProps | null>(null);

interface ButtonGroupProps {
  color?: ButtonColor;
  children: React.ReactNode;
}

function Group({ color = "secondary", ...props }: ButtonGroupProps) {
  const groupProps = useMemo(() => ({ color, ...props }), [color, props]);
  return (
    <ButtonGroupContext.Provider value={groupProps}>
      <span className="button-group">{props.children}</span>
    </ButtonGroupContext.Provider>
  );
}

interface ButtonComponent extends React.ForwardRefExoticComponent<ButtonProps> {
  Group: typeof Group;
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  color?: ButtonColor;
  icon?: React.ReactNode;
  shadow?: boolean;
  loading?: boolean;
}

function getColorClass(color: ButtonColor) {
  switch (color) {
    case "primary":
      return "text-white bg-base-rodel-100 hover:bg-base-rodel-110 hover:border-base-rodel-110";
    case "secondary":
      return "text-base-black bg-white hover:bg-light-gray";
    case "success":
      return "text-white bg-base-green-50 hover:bg-base-green-60 hover:border-base-green-60";
    default:
      return "text-white bg-red-500 hover:bg-red-700 hover:border-red-700";
  }
}

function getClassName(
  props: ButtonProps,
  context: ButtonGroupProps | null,
  toolbar: boolean,
  shadow: boolean
) {
  const classNameComponents: string[] = [];
  classNameComponents.push(
    getColorClass(context?.color ?? props.color ?? "primary")
  );

  if (props.className) {
    classNameComponents.push(props.className);
  }
  classNameComponents.push(
    `h-[38px] min-w-[38px] inline-flex flex-grow-0 items-center justify-center space-x-2 py-2 rounded ${
      props.icon && !props.children ? "px-2" : "px-4"
    } text-sm font-medium focus:outline-none`
  );

  if (shadow) {
    classNameComponents.push("shadow-md");
  }

  if (context == null && !toolbar) {
    classNameComponents.push(
      "focus:ring-2 focus:ring-secondary focus:ring-offset-2"
    );
  }

  classNameComponents.push("disabled:opacity-70 disabled:cursor-not-allowed");

  return classNameComponents.join(" ");
}

const InternalButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
      children,
      type = "button",
      icon,
      className: providedClassName,
      shadow = false,
      loading = false,
      ...rest
    } = props;
    const buttonGroupContext = useContext(ButtonGroupContext);
    const toolbarContext = useContext(ToolbarContext);

    const className = getClassName(
      props,
      buttonGroupContext,
      toolbarContext != null,
      shadow
    );

    return (
      <button ref={ref} type={type} className={className} {...rest}>
        {icon && <span className="h-4 w-4">{icon}</span>}
        {children && <span>{children}</span>}
        {loading && <Spinner color="white" className="h-6 w-6" />}
      </button>
    );
  }
);

const Button = InternalButton as unknown as ButtonComponent;
Button.Group = Group;

export default Button as (
  props: ButtonProps & React.RefAttributes<HTMLButtonElement>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => React.ReactElement<any, string | React.JSXElementConstructor<any>> | null;
