import React, { useContext } from "react";
import { OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import SimulatorContext from "../../context/SimulatorContext";
import * as constants from "../../../common/constants";

function Camera() {
  const { cameraType } = useContext(SimulatorContext);
  return cameraType === "orthographic" ? (
    <OrthographicCamera
      position={[
        constants.INIT_CAM_POS_X,
        constants.INIT_CAM_POS_Y,
        constants.INIT_CAM_POS_Z,
      ]}
      rotation={[0, 0, 0]}
      near={constants.CAM_NEAR}
      far={constants.CAM_FAR}
      zoom={constants.INIT_CAM_ZOOM}
      makeDefault
    />
  ) : (
    <PerspectiveCamera>
      fov ={75}
      near={constants.CAM_NEAR}
      far={constants.CAM_FAR}
      position=
      {[
        constants.INIT_CAM_POS_X,
        constants.INIT_CAM_POS_Y,
        constants.INIT_CAM_POS_Z,
      ]}
      rotation={[0, 0, 0]}
      makeDefault
    </PerspectiveCamera>
  );
}

export default Camera;
