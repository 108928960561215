/* eslint-disable no-console */
import { useEffect } from "react";

const useKeyPressListener = (callback: () => void) => {
  useEffect(() => {
    console.log("added keydown listener");
    window.addEventListener("keydown", (event) => {
      if (event.key === "r" && !event.repeat) {
        callback();
      }
    });

    return () => {
      window.removeEventListener("keydown", () => {
        console.log("removed keydown listener");
      });
    };
  }, [callback]);
};

export default useKeyPressListener;
