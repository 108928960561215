/* eslint-disable no-param-reassign */
import {
  AbstractGridItem,
  GridItem,
  GridItemSize,
  GridItemType,
} from "@rodel-futures-simulator/types";
import { BufferGeometry, Material, Mesh, Scene, Vector3 } from "three";
import type { MapControls } from "three-stdlib";
import * as constants from "../../../../common/constants";
import { Bounds, MeshSizePair } from "../../../../common/types/types";
import { ModelCatalog } from "../../useGridItemModelCatalog";
import { isWithinBounds } from "./boundaryUtils";
import {
  getBounds,
  transformGridToThreeCoords,
} from "../../../utils/transformCoords";
import { getTranslatedPosition } from "./translationUtils";
import { LOD_ZOOM } from "../../../../common/constants";
import { getFilterMaterial } from "../../../utils/filterLayers/getFilter";

// Makes a mesh given a specific preoperties and attaches user data
export function makeMesh(
  item: GridItem,
  position: Vector3,
  geometry: BufferGeometry,
  material: Material | Material[],
  rotation: number
) {
  // Create mesh with provided geometry, material, position, and rotation
  (material as Material).vertexColors = false;
  const mesh = new Mesh(geometry, material);
  mesh.position.set(position.x, position.y, position.z);
  mesh.rotation.set(Math.PI / 2, 0, rotation);

  // Set the appropriate scale in order to tile things correctly
  mesh.scale.set(
    constants.CAD_FACTOR * constants.SCALE,
    constants.CAD_FACTOR * constants.SCALE,
    constants.CAD_FACTOR * constants.SCALE
  );

  // Set user data for use in lookup later
  mesh.userData = { type: item.type, xCoord: item.xCoord, yCoord: item.yCoord };

  return mesh;
}

// Start tracking and adds meshes to the scene for grid items
// that are not already being tracked
export function addGridItemMeshes(
  gridItems: GridItem[],
  meshes: Record<string, MeshSizePair>,
  bounds: Bounds,
  scene: Scene,
  setGridItemModal: React.Dispatch<
    React.SetStateAction<AbstractGridItem | undefined>
  >,
  setAutoRotate: React.Dispatch<React.SetStateAction<boolean>>,
  mapControlsRef: React.MutableRefObject<MapControls>,
  catalog: ModelCatalog,
  layer: string,
  prevLayer: string,
  timestep: number,
  prevTimestep: number,
  addMeshClickListener: (mesh: THREE.Mesh, callback: () => void) => void
) {
  const tempVector = new Vector3();
  // Add meshes within loading boundary
  gridItems.forEach((gridItem) => {
    const { modelTypes } =
      catalog[gridItem.type][gridItem.size ?? GridItemSize.QUARTER];

    const key = `${gridItem.xCoord}_${gridItem.yCoord}`;
    let existingMesh = meshes[key] ? meshes[key].mesh : undefined;

    const isWithinBoundary = isWithinBounds(
      { x: gridItem.xCoord, y: gridItem.yCoord },
      bounds
    );

    if (existingMesh && existingMesh.userData.type !== gridItem.type) {
      // Mesh exists but its type is now different. Remove it to replace set up for replacement.
      const removeBounds = getBounds(
        { x: gridItem.xCoord, y: gridItem.yCoord },
        gridItem.size
      );

      const keysToRemove: string[] = [];
      for (let x = removeBounds.xMin; x <= removeBounds.xMax; x++) {
        for (let y = removeBounds.yMin; y <= removeBounds.yMax; y++) {
          keysToRemove.push(`${x}_${y}`);
        }
      }
      keysToRemove.forEach((removeKey) => {
        // Remove existing mesh
        if (meshes[removeKey]) {
          scene.remove(meshes[removeKey].mesh);
          delete meshes[removeKey];
        }
      });

      existingMesh = undefined;
    }

    if (existingMesh) {
      // Mesh exists. Update existing mesh with any changes
      // Todo: fix
      if (!(layer === prevLayer) || !(timestep === prevTimestep)) {
        existingMesh.material = getFilterMaterial(layer, gridItem, modelTypes);
      }
    }

    if (!existingMesh && isWithinBoundary) {
      // No mesh exists, but it is withing the loading boundary. Create and add mesh.
      const translatedPosition = getTranslatedPosition(gridItem);
      const coords = transformGridToThreeCoords([
        translatedPosition.x,
        0,
        translatedPosition.y,
      ]);
      tempVector.set(coords[0], coords[1], coords[2]);

      // Create the mesh to be added with the correct properties
      // determined by the grid item details
      const rotation = gridItem.rotation ?? 0;
      const { geometry } =
        catalog[gridItem.type][gridItem.size ?? GridItemSize.QUARTER];
      const filterMaterial = getFilterMaterial(layer, gridItem, modelTypes);

      const mesh = makeMesh(
        gridItem,
        tempVector,
        geometry,
        filterMaterial,
        rotation
      );

      meshes[key] = {
        mesh,
        size: gridItem.size ?? GridItemSize.QUARTER,
      };

      mesh.name = "itemMesh";

      addMeshClickListener(mesh, () => {
        if (gridItem.type !== GridItemType.DESERT) {
          setGridItemModal({
            xCoord: gridItem.xCoord,
            yCoord: gridItem.yCoord,
            type: gridItem.type,
            size: gridItem.size,
          });

          setAutoRotate(true);
          // selectGridItemAt({
          //   pos: [gridItem.xCoord, gridItem.yCoord],
          //   size: gridItem.size,
          // });

          const { x, y } = getTranslatedPosition(gridItem);

          const [x1, , z1] = transformGridToThreeCoords([x, 0, y]);

          mapControlsRef.current.target.x = x1;
          mapControlsRef.current.target.y = 0;
          mapControlsRef.current.target.z = z1;
          mapControlsRef.current.update();
        }
      });

      scene.add(mesh);
    }
  });
}

// Determines which of the meshes that are currently being tracked
// we should stop tracking and remove from the scene
export function updateExistingMeshes(
  meshes: Record<string, MeshSizePair>,
  bounds: Bounds,
  scene: Scene,
  zoom: number,
  visible?: boolean
) {
  Object.keys(meshes).forEach((key) => {
    // if the mesh is outside the boundary, remove it
    const meshPair = meshes[key];
    const coords = key.split("_").map((coord) => Number(coord)) as [
      number,
      number
    ];

    const isOutsideOfBoundary = !isWithinBounds(
      { x: coords[0], y: coords[1] },
      bounds
    );

    // Remove the mesh if it is outside of our loading boundary
    if (isOutsideOfBoundary) {
      delete meshes[key];

      scene.remove(meshPair.mesh);
    } else {
      // If the mesh is to inside the loading boundary,
      // update it to only be visible if it the camera zoom level is close enough
      // to show the detailed meshes
      meshPair.mesh.visible = visible ?? zoom > LOD_ZOOM;
    }
  });
}
