/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

interface FormRowProps {
  label: string;
  vertical?: boolean;
  children: React.ReactNode;
}
export default function FormRow({
  label,
  vertical = false,
  children,
}: FormRowProps) {
  return (
    <div className="flex flex-col w-full">
      <div
        className={`my-1 flex ${
          vertical ? "flex-col" : "flex-row"
        } gap-6 place-content-between`}
      >
        <label className="text-base font-medium w-3/4">{label}</label>
        <div className="w-full">{children}</div>
      </div>
      <hr className="text-base-gray-80 my-1" />
    </div>
  );
}
