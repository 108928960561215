import {
  TimestepRangeReqBody,
  GridItemReqQuery,
  GridItemType,
} from "@rodel-futures-simulator/types";
import { useQuery } from "@tanstack/react-query";
import * as client from "./client";

export function useGetSimulations() {
  return useQuery(["simulations"], () => client.getSimulations(), {
    refetchInterval: 2000,
  });
}

export function useGetSimulation(id: string) {
  return useQuery(["simulation", id], () => client.getSimulation(id));
}

export function useGetTimestepRange(body: TimestepRangeReqBody) {
  return useQuery(
    ["timestepRange", body.simulationId, body.minStep, body.maxStep],
    () => client.getTimestepRange(body),
    {
      keepPreviousData: true,
    }
  );
}

export function useGetSimulationDetails(simId: string) {
  return useQuery(["simulationDetails", simId], () =>
    client.getSimulationDetails(simId)
  );
}

export function useGetGridItem(
  query: GridItemReqQuery,
  gridItemType: GridItemType
) {
  return useQuery(
    [gridItemType, query.simulationId, query.xCoord, query.yCoord, query.name],
    () => client.getGridItem(query, gridItemType)
  );
}

export function useGetCounties() {
  return useQuery(["countyData"], () => client.getCounties());
}
