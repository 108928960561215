import React, { Dispatch, useContext, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { XMarkIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Button from "../../../common/components/Button";
import DoughnutChart from "../../../common/components/DoughnutChart";
import SimulatorContext from "../../context/SimulatorContext";
import Input from "../../../common/components/Input";
import { formatPercentage } from "../../../common/utils/formatUtility";
import { colors } from "../../../common/constants";
import { useUpdateBudget } from "../../../api/mutations";
import Tooltip from "../../../common/components/Tooltip";

interface EditBudgetProps {
  setEditBudget: Dispatch<React.SetStateAction<boolean>>;
}

export default function EditBudget({ setEditBudget }: EditBudgetProps) {
  const { mapState, simulationId } = useContext(SimulatorContext);

  const totalBudget = useMemo(
    () =>
      mapState.budget.categories.reduce(
        (acc, category) => acc + category.amountInMillions,
        0
      ),
    [mapState.budget.categories]
  );
  const defaultPercentages: number[] = useMemo(
    () =>
      mapState.budget.categories.map(
        (category) => category.amountInMillions / totalBudget
      ),
    [mapState.budget.categories, totalBudget]
  );

  const [percentages, setPercentages] = useState(defaultPercentages);
  const [totalAllocPercentage, setTotalAllocPercentage] = useState(1);
  const [message, setMessage] = useState("");

  const budgetData = useMemo(() => {
    let d = mapState.budget.categories.map(({ name }, index) => ({
      label: name,
      value: percentages[index] * totalBudget,
      color: colors[index % colors.length],
    }));
    if (parseFloat((totalAllocPercentage * 100).toFixed(1)) < 100.0) {
      d.push({
        label: "Not allocated correctly",
        value: Math.abs(totalAllocPercentage - 1) * totalBudget,
        color: "#F1363E",
      });
    } else if (parseFloat((totalAllocPercentage * 100).toFixed(1)) > 100.0) {
      d = [
        {
          label: "Not allocated correctly",
          value: totalBudget,
          color: "#CFCFCF",
        },
      ];
    }

    return d;
  }, [
    mapState.budget.categories,
    percentages,
    totalAllocPercentage,
    totalBudget,
  ]);

  const queryClient = useQueryClient();
  const mutation = useUpdateBudget({
    onSuccess: async () => {
      await queryClient.invalidateQueries(["timestepRange"]);
      setEditBudget(false);
    },
    onError: () => {
      setMessage("Unable to save new budget. Try again.");
    },
  });

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Remove the percent sign and convert string to float
    let newValue =
      parseFloat(parseFloat(e.target.value.replace(/%$/, "")).toFixed(1)) / 100;

    if (Number.isNaN(newValue) || newValue < 0) {
      newValue = 0;
      e.target.value = formatPercentage(0, 1);
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length - 1;
    }

    const updatedPercentages = percentages.map((category, i) =>
      i === index ? newValue : category
    );

    const sum = updatedPercentages.reduce((acc, val) => acc + val, 0);

    if (parseFloat((sum * 100).toFixed(1)) > 100.0) {
      setMessage(" You have exceeded the maximum Total Budget");
    } else if (parseFloat((sum * 100).toFixed(1)) < 100.0) {
      setMessage(" You have unallocated funds in your budget");
    } else {
      setMessage("");
    }
    setPercentages(updatedPercentages);
    setTotalAllocPercentage(sum);
  };

  const handleSaveNumbers = () => {
    mutation.mutate({
      simulationId,
      step: mapState.step,
      newBudget: {
        categories: budgetData.map((category, i) => ({
          name: category.label,
          amountInMillions: totalBudget * percentages[i],
        })),
      },
    });
  };

  return (
    <div className="flex flex-col mt-3 h-[565px]">
      <div className="flex justify-center">
        <DoughnutChart
          data={budgetData}
          centerValue={formatPercentage(1, 1)}
          label="Allocated Budget"
        />
      </div>
      <div className="flex justify-between my-2">
        <div className="text-xs text-base-black flex items-center font-normal">
          Industry Budget (in millions)&nbsp;
          <Tooltip
            icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
            text="Industry budgets divided into spending categories."
            position="bottom"
          />
        </div>
        <div className="ml-20">
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setEditBudget(false);
            }}
            icon={<XMarkIcon className="h-3 w-3 mr-0.5 mt-0.5 text-red-500" />}
          >
            <div className="text-xs text-red-500">Reset</div>
          </Button>
        </div>
      </div>
      {mapState.budget.categories.map((category, index, categories) => {
        return (
          <div key={category.name}>
            <div className="flex justify-between">
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-full mr-3"
                  style={{
                    backgroundColor: colors[index % colors.length],
                  }}
                />
                <p className="font-medium">{category.name}</p>
              </div>
              <Input
                type="text"
                className="w-16"
                defaultValue={formatPercentage(percentages[index], 1)}
                onChange={(e) => handleInputChange(index, e)}
                onBlur={(e) => {
                  const newValue =
                    parseFloat(e.target.value.replace(/%$/, "")) / 100;
                  e.target.value = formatPercentage(newValue, 1);
                }}
              />
            </div>
            {index < categories.length - 1 && (
              <hr className="text-base-gray-80 my-1" />
            )}
          </div>
        );
      })}
      <p className="text-left text-sm text-center font-light text-base-red-100 h-5">
        {message && <ExclamationCircleIcon className="inline-block h-4 w-4" />}
        {message}
      </p>
      <div className="mt-2">
        <Button
          type="button"
          color="primary"
          className="px-4 py-2 text-white w-full rounded hover:bg-gray-200 text-gray"
          onClick={handleSaveNumbers}
          disabled={
            parseFloat((totalAllocPercentage * 100).toFixed(1)) !== 100.0
          }
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
