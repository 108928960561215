import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { EventEmitter } from "events";

const emitter = new EventEmitter();

const addMeshClickListener = (mesh: THREE.Mesh, callback: () => void) => {
  emitter.on("modelDblClick", (intersect: THREE.Object3D) => {
    if (intersect.uuid === mesh.uuid) callback();
  });
};

const useMeshClickListener = (isDragItemDropped: boolean) => {
  const { scene, camera, gl } = useThree();
  const canvas = gl.domElement;

  const mouse = useMemo(() => new THREE.Vector2(), []);
  const raycaster = useMemo(() => new THREE.Raycaster(), []);

  useEffect(() => {
    function handler(event: MouseEvent) {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      raycaster.params.Points!.threshold = 0.1; // eslint-disable-line @typescript-eslint/no-non-null-assertion

      const intersects = raycaster.intersectObjects(scene.children);

      if (!isDragItemDropped) {
        emitter.emit(
          "modelDblClick",
          intersects.filter(
            (intersect) => intersect.object.name === "itemMesh"
          )[0].object
        );
      }
    }

    canvas.addEventListener("dblclick", handler, false);

    return () => {
      canvas.removeEventListener("dblclick", handler);
    };
  }, [camera, canvas, mouse, raycaster, scene.children, isDragItemDropped]);

  return addMeshClickListener;
};

export default useMeshClickListener;
