import {
  ApiRes,
  BudgetUpdateReqBody,
  ClientRes,
  CreateSimReqBody,
  InterventionReqBody,
  ResMessage,
  Timestep,
  UpsertGridItemReqBody,
} from "@rodel-futures-simulator/types";
import { useMutation } from "@tanstack/react-query";
import * as client from "./client";

interface MutationOptions<T> {
  onMutate?: () => Promise<void>;
  onSuccess?: (data: T) => Promise<void>;
  onError?: () => void;
}

export function useCreateSimulation(
  options?: MutationOptions<
    ClientRes<{ simulationID: string; success: boolean }>
  >
) {
  return useMutation<
    ClientRes<{ simulationID: string; success: boolean }>,
    Error,
    CreateSimReqBody
  >((body) => client.createSimulation(body), options);
}

export function useUpsertGridItem(
  action: string,
  update: boolean,
  options?: MutationOptions<ClientRes<Timestep[]>>
) {
  return useMutation<ClientRes<Timestep[]>, Error, UpsertGridItemReqBody>(
    (body) => client.upsertGridItem(action, body, update),
    options
  );
}

export function usePostIntervention(options?: MutationOptions<ApiRes<string>>) {
  return useMutation(
    (body: InterventionReqBody) => client.postInvervention(body),
    options
  );
}

// Update this any
export function useUpdateBudget(options?: MutationOptions<ApiRes<ResMessage>>) {
  return useMutation(
    (body: BudgetUpdateReqBody) => client.updateBudget(body),
    options
  );
}
