import {
  GridItemType,
  SchoolType,
  SchoolLevel,
  GridItemSize,
  PowerPlantType,
} from "@rodel-futures-simulator/types";

export function getGridItemEnum(value: string) {
  switch (value) {
    case "School":
      return GridItemType.SCHOOL;
    case "Business":
      return GridItemType.BUSINESS;
    case "Neighborhood":
      return GridItemType.NEIGHBORHOOD;
    case "Power Plant":
      return GridItemType.POWER_PLANT;
    default:
      throw new Error("Invald grid item type");
  }
}

export function getSchoolEnum(value: string) {
  switch (value) {
    case "Public":
      return SchoolType.PUBLIC;
    case "Private":
      return SchoolType.PRIVATE;
    case "Charter":
      return SchoolType.CHARTER;
    default:
      throw new Error("Invald school type");
  }
}

export function getSchoolLevelEnum(value: string) {
  switch (value) {
    case "Elementary":
      return SchoolLevel.ELEMENTARY;
    case "Middle":
      return SchoolLevel.MIDDLE;
    case "High":
      return SchoolLevel.HIGH;
    case "University":
      return SchoolLevel.UNIVERSITY;
    default:
      throw new Error("Invalid school level");
  }
}

export function getItemSizeString(size: GridItemSize) {
  switch (size) {
    case GridItemSize.QUARTER:
      return "Small";
    case GridItemSize.HALF:
      return "Medium";
    case GridItemSize.FULL:
      return "Large";
    default:
      throw new Error("Invalid grid item size");
      break;
  }
}

export function getPowerType(value: string) {
  switch (value) {
    case "Battery storage":
      return PowerPlantType.BATTERY_STORAGE;
    case "Pumped storage":
      return PowerPlantType.PUMPED_STORAGE;
    case "Wind":
      return PowerPlantType.WIND;
    case "Solar":
      return PowerPlantType.SOLAR;
    case "Hydroelectric":
      return PowerPlantType.HYDROELECTRIC;
    case "Biomass":
      return PowerPlantType.BIOMASS;
    case "Petroleum":
      return PowerPlantType.PETROLEUM;
    case "Natural gas":
      return PowerPlantType.NATURAL_GAS;
    case "Coal":
      return PowerPlantType.COAL;
    case "Nuclear":
      return PowerPlantType.NUCLEAR;
    case "Reservoir":
      return PowerPlantType.RESERVOIR;
    default:
      throw new Error("Invald power plant type");
  }
}
