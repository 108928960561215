import React, { useState } from "react";
import { CubeIcon, CubeTransparentIcon } from "@heroicons/react/24/outline";
import Input from "../common/components/Input";
import Button from "../common/components/Button";

interface AuthPageProps {
  isLogin: boolean;
}

function AuthPage({ isLogin }: AuthPageProps) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle login or signup here
    if (isLogin) {
      // Handle login
    } else {
      // Handle signup
    }
  };

  return (
    <div className="h-screen grid grid-cols-12">
      <div className="flex justify-center items-center gradient col-span-6 m-6 text-center rounded-lg">
        <div>
          <CubeTransparentIcon className="w-40 h-40 text-white mb-4 mx-auto">
            <CubeIcon className="w-32 h-32 text-white mb-4 mx-auto" />
          </CubeTransparentIcon>

          <h1 className="text-[56px] text-white">Rodel Futures Simulator</h1>
          <h2 className="text-2xl text-white mb-4">
            Your gateway to the simulation of the future
          </h2>
        </div>
      </div>

      <div className="flex items-center justify-center col-start-7 col-span-6">
        <form className="p-8" onSubmit={handleSubmit}>
          <h1 className="text-black text-3xl mb-4 block">
            {isLogin ? "Log In" : "Sign Up"}
          </h1>
          <h2 className="text-base text-gray-400 mb-4 block">
            {isLogin
              ? "Welcome back! Sign in to access your simulations."
              : "Create an account below to get started"}
          </h2>
          <div className="mb-4">
            <h2 className="text-l text-black mb-2 block">Username</h2>
            <Input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className="w-[28rem] rounded-lg text-lg inline-block"
              required
            />
          </div>
          {!isLogin && (
            <div className="mb-4">
              <h2 className="text-l text-black mb-2 block">Email</h2>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-[28rem] rounded-lg text-lg inline-block"
                required
              />
            </div>
          )}
          <div className="mb-4">
            <h2 className="text-l text-black mb-2 block">Password</h2>
            <Input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-[28rem] rounded-lg text-lg inline-block"
              required
            />
          </div>
          <div className="flex text-center mb-4">
            <Button
              type="submit"
              color="primary"
              className="w-[28rem] px-4 py-2 mt-6 border rounded-lg text-lg inline-block"
            >
              {isLogin ? "Log In" : "Create an Account"}
            </Button>
          </div>
          <hr
            className="w-[28rem] border rounded-lg text-lg inline-block"
            style={{
              borderTop: "0.5px solid #a9a9a9",
              margin: "20px 0",
            }}
          />
          <div className="flex justify-center text-center items-center flex-col mr-[3rem]">
            <h1 className="text-center text-sm mb-2 block mr-4">
              {isLogin ? "Don’t have an account?" : "Already have an account?"}{" "}
              <a
                href={isLogin ? "/signup" : "/login"}
                className="text-blue-400"
              >
                {isLogin ? "Register here" : "Log In"}
              </a>
            </h1>
            <h2 className="text-center text-sm text-gray-400 mb-4 block mr-4">
              or
            </h2>
            <h1 className="text-center text-sm mb-2 block mr-4">
              Explore the{" "}
              <a href="/" className="text-blue-400">
                simulation{" "}
              </a>{" "}
              without logging in
            </h1>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AuthPage;
