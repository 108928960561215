import React, { useState } from "react";

export interface RadioGroupOption {
  label: string;
  value: string;
  image: string;
}

interface RadioButtonProps {
  option: RadioGroupOption;
  isSelected: boolean;
  onChange: (value: string) => void;
}

function RadioButton({ option, isSelected, onChange }: RadioButtonProps) {
  return (
    <label
      htmlFor={option.value}
      className="flex items-center space-x-2 cursor-pointer"
    >
      <input
        id={option.value}
        type="radio"
        className="hidden"
        value={option.value}
        checked={isSelected}
        onChange={() => onChange(option.value)}
      />
      <div
        className={`relative w-16 h-16 rounded-md border border-base-gray-80 ${
          isSelected ? "ring-2 ring-base-blue-100" : ""
        }`}
      >
        <img
          src={option.image}
          alt={option.label}
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      <span
        className={`text-base font-medium ${
          isSelected ? "text-base-blue-100" : ""
        }`}
      >
        {option.label}
      </span>
    </label>
  );
}

interface CustomRadioGroupProps {
  options: RadioGroupOption[];
  value: string;
  onChange: (value: string) => void;
}

function CustomRadioGroup({ options, value, onChange }: CustomRadioGroupProps) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOptionChange = (val: string) => {
    setSelectedValue(val);
    onChange(val);
  };

  return (
    <div className="flex flex-col space-y-3">
      {options.map((option) => (
        <RadioButton
          key={option.value}
          option={option}
          isSelected={selectedValue === option.value}
          onChange={handleOptionChange}
        />
      ))}
    </div>
  );
}

export default CustomRadioGroup;
