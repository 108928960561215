import {
  GridItemSize,
  GridItemType,
  Intervention,
} from "@rodel-futures-simulator/types";
import { INIT_INVEST_FUNDS } from "../../common/constants";

export function getBuildAmount(type: GridItemType, size: GridItemSize) {
  let amount = 0;
  if (type === GridItemType.NEIGHBORHOOD && size === GridItemSize.QUARTER)
    amount = 25000000;
  if (type === GridItemType.NEIGHBORHOOD && size === GridItemSize.HALF)
    amount = 40000000;
  if (type === GridItemType.NEIGHBORHOOD && size === GridItemSize.FULL)
    amount = 50000000;
  if (type === GridItemType.SCHOOL && size === GridItemSize.QUARTER)
    amount = 50000000;
  if (type === GridItemType.SCHOOL && size === GridItemSize.HALF)
    amount = 75000000;
  else if (type === GridItemType.BUSINESS && GridItemSize.QUARTER)
    amount = 60000000;
  else if (type === GridItemType.BUSINESS && GridItemSize.HALF)
    amount = 100000000;
  else if (type === GridItemType.BUSINESS && GridItemSize.FULL)
    amount = 250000000;
  else if (type === GridItemType.POWER_PLANT && GridItemSize.QUARTER)
    amount = 300000000;
  else if (type === GridItemType.POWER_PLANT && GridItemSize.HALF)
    amount = 500000000;

  return amount;
}

export function calcAvailableFunds(interventions: Intervention) {
  const schoolInvestmentAmount = interventions.schoolInvestments.reduce(
    (accumulator, schoolInvestment) => accumulator + schoolInvestment.amount,
    0
  );
  const otherInvestmentAmount = interventions.investments.reduce(
    (accumulator, investment) => accumulator + investment.amount,
    0
  );
  const totalInvestmentAmount = schoolInvestmentAmount + otherInvestmentAmount;
  const totalBuildAmount = interventions.builds.reduce(
    (accumulator, build) =>
      accumulator + getBuildAmount(build.type, build.size),
    0
  );
  return INIT_INVEST_FUNDS - totalInvestmentAmount - totalBuildAmount;
}
