import React, { useContext } from "react";
import { StatusType } from "@rodel-futures-simulator/types";
import SimulatorContext from "../context/SimulatorContext";
import SearchControls from "./components/SearchControls";
import TimelapseControls from "./components/TimelapseControls";
import NavigationControls from "./components/NavigationControls";
import TopLevelMetrics from "./components/TopLevelMetrics";
import ToolsControls from "./components/ToolsControls";
import SubmitControls from "./components/SubmitControls";
import LayerLegend from "./components/LayerLegend";

function UI() {
  const { simStatus } = useContext(SimulatorContext);
  return (
    <>
      <div className="mt-5 ml-5 fixed top-0 left-0">
        <SearchControls />
      </div>
      <div className="mt-5 mr-5 fixed top-0 right-0 items-end">
        <TopLevelMetrics />
      </div>
      <div className="mr-5 fixed top-[95px] right-0 items-end">
        <ToolsControls />
      </div>
      <div className="m-5 fixed bottom-0 left-0">
        {simStatus === StatusType.COMPLETED ? (
          <TimelapseControls />
        ) : (
          <SubmitControls />
        )}
      </div>
      <LayerLegend />
      <div className="m-5 fixed bottom-0 right-0">
        <NavigationControls />
      </div>
    </>
  );
}

export default UI;
