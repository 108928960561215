/* eslint-disable react/require-default-props */
import React from "react";
import { formatPercentage } from "../utils/formatUtility";
import usePrevious from "../hooks/usePrevious";

interface ProgressBarProps {
  color: string;
  backgroundColor: string;
  steps: { name: string; percent: number }[];
  currentStep: string;
  className?: string;
}

export default function ProgressBar({
  color,
  backgroundColor,
  steps,
  currentStep,
  className,
}: ProgressBarProps) {
  const currentStepObject = steps.find((step) => step.name === currentStep);
  const currentPercent = currentStepObject ? currentStepObject.percent : 0;
  const prevPercent = usePrevious(currentPercent);
  const progressing = currentPercent > prevPercent;

  return (
    <div
      className={
        className ? `relative mx-auto ${className}` : "relative mx-auto"
      }
    >
      <div
        id="progress-bar"
        style={{
          width: "100%",
          height: "2px",
          position: "absolute",
          backgroundColor,
          zIndex: "0",
        }}
      />
      {steps.map((step, index) => (
        <div key={index}>
          <div
            style={{
              height: "12px",
              width: "12px",
              position: "absolute",
              backgroundColor:
                step.percent <= currentPercent ? color : backgroundColor,
              left: `calc(${formatPercentage(step.percent, 0)} - 6px)`,
              borderRadius: "100%",
              zIndex: "0",
              top: "-5px",
              transition: "background-color 0.2s",
              transitionDelay: progressing ? "0.7s" : "0s",
            }}
          />
          <div
            style={{
              width: "80px",
              position: "absolute",
              textAlign: "center",
              color: step.percent <= currentPercent ? color : backgroundColor,
              left: `calc(${formatPercentage(step.percent, 0)} - 40px)`,
              zIndex: "0",
              top: "15px",
              transition: "color 0.2s",
              transitionDelay: progressing ? "0.7s" : "0s",
            }}
          >
            {step.name}
          </div>
        </div>
      ))}
      <div
        id="progress"
        className="relative h-1"
        style={{
          width: `calc(${formatPercentage(currentPercent, 0)} - 6px)`,
          height: "2px",
          backgroundColor: "rgb(255, 255, 255)",
          position: "absolute",
          zIndex: "1",
          transition: "width 0.7s ease-in-out",
        }}
      />
    </div>
  );
}
