/* eslint-disable no-underscore-dangle */
import {
  GridItemSize,
  GridItemType,
  Simulation,
  County,
  Intervention,
} from "@rodel-futures-simulator/types";
import React, { useState, useMemo, useRef, Suspense } from "react";
import { useParams } from "react-router-dom";
import type { MapControls } from "three-stdlib";
import {
  BuildItem,
  CameraType,
  GridItemModalType,
  MapLayer,
} from "../common/types/types";
import SimulatorContext from "./context/SimulatorContext";
import Scene from "./scene/Scene";
import UI from "./ui/UI";
import useTimer from "./hooks/useTimer";
import useMemoContext from "./hooks/useMemoContext";
import "../App.css";
import { useGetSimulation, useGetCounties } from "../api/queries";
import LoadingPage from "../loadingPage/LoadingPage";
import ErrorPage from "../errorPage/ErrorPage";

function Simulator({
  simData,
  countyData,
}: {
  simData: Simulation;
  countyData: County[];
}) {
  const name = useMemo(() => simData.name, [simData.name]);

  const [loadingScene, setLoadingScene] = useState<boolean>(true);

  const simulationId = useMemo(() => simData._id, [simData._id]);
  const simStatus = useMemo(() => simData.status, [simData.status]);

  const finalTimestep = simData.totalNumTimesteps
    ? simData.totalNumTimesteps - 1
    : 0;

  const counties = useMemo(() => countyData, [countyData]);

  const [buildItem, setBuildItem] = useState<BuildItem>({
    state: "form",
    type: GridItemType.SCHOOL,
    size: GridItemSize.QUARTER,
  });

  const mapControlsRef = useRef<MapControls>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const compassRef = useRef<HTMLButtonElement>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const selectRef = useRef<HTMLSpanElement>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const [gridItemModal, setGridItemModal] = useState<
    GridItemModalType | undefined
  >();
  const [selectedLayer, setSelectedLayer] = useState<MapLayer>(
    MapLayer.DEFAULT
  );
  const [interventions, setInterventions] = useState<Intervention>({
    investments: [],
    schoolInvestments: [],
    builds: [],
    budget: simData.budget,
    policies: simData.policies,
  });

  const {
    loadingMap,
    mapState,
    playing,
    play,
    stop,
    reset,
    speed,
    setSpeed,
    // networkPause,
    setTimestep,
    // setTimestepRangeMin,
  } = useTimer({ simulationId, finalTimestep });

  const [autoRotation, setAutoRotation] = useState(false);
  const [cameraType, setCameraType] = useState<CameraType>("orthographic");

  const simulatorContext = useMemoContext({
    loadingMap,
    mapState,
    counties,
    mapControlsRef,
    playing,
    play,
    stop,
    reset,
    speed,
    setSpeed,
    // networkPause,
    simulationId,
    simStatus,
    name,
    setTimestep,
    // setTimestepRangeMin,
    finalTimestep,
    buildItem,
    setBuildItem,
    interventions,
    setInterventions,
    gridItemModal,
    setGridItemModal,
    compassRef,
    selectRef,
    selectedLayer,
    setSelectedLayer,
    autoRotation,
    setAutoRotation,
    cameraType,
    setCameraType,
    loadingScene,
    setLoadingScene,
  });

  return (
    <div className="App">
      <SimulatorContext.Provider value={simulatorContext}>
        <Scene />
        <UI />
        {loadingScene && <LoadingPage text="Gathering map data..." />}
      </SimulatorContext.Provider>
    </div>
  );
}

function SimulatorWrapper() {
  const { id } = useParams();

  const {
    data: dataCounty,
    isError: isErrorCounty,
    isLoading: isLoadingCounty,
  } = useGetCounties();
  const countyData: County[] | undefined = dataCounty?.data;

  const {
    data: dataSimulation,
    isError: isErrorSimulation,
    isLoading: isLoadingSimulation,
  } = useGetSimulation(id ?? "");
  const simulationData: Simulation | undefined = dataSimulation?.data;

  if (isLoadingSimulation || isLoadingCounty) {
    return <LoadingPage text="Gathering simulation data..." />;
  }
  if (isErrorSimulation || !simulationData || isErrorCounty || !countyData) {
    return <ErrorPage />;
  }
  return (
    <Suspense fallback={<LoadingPage text="Building simulation map..." />}>
      <Simulator simData={simulationData} countyData={countyData} />
    </Suspense>
  );
}

export default SimulatorWrapper;
