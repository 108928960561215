/* eslint-disable react/require-default-props */
import React from "react";
import { Tab as T } from "@headlessui/react";
import classNames from "../../simulator/utils/classNames";

function TabGroup({
  tabs,
  children,
  setSelectedItem,
  centered = false,
}: {
  tabs: string[];
  children: React.ReactNode;
  setSelectedItem?: (value: string) => void;
  centered?: boolean;
}) {
  return (
    <T.Group>
      <T.List
        className={classNames(
          "flex",
          centered
            ? "w-full justify-between border-b mb-2"
            : "space-x-4 border-b mb-2"
        )}
      >
        {tabs.map((tab) => (
          <T
            key={tab}
            className={({ selected }) =>
              classNames(
                "py-2.5 focus-visible:outline-none text-base leading-5 font-medium",
                selected
                  ? "border-b-2 border-base-rodel-100"
                  : "border-b-2 border-transparent text-light"
              )
            }
            onClick={() => {
              if (setSelectedItem) setSelectedItem(tab);
            }}
          >
            {tab}
          </T>
        ))}
      </T.List>
      <T.Panels>{children}</T.Panels>
    </T.Group>
  );
}

function Panel({ children }: { children: React.ReactNode }) {
  return <T.Panel>{children}</T.Panel>;
}

TabGroup.Panel = Panel;

export default TabGroup;
