/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useRef, useMemo, useContext } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Edges } from "@react-three/drei";
import * as constants from "../../../common/constants";
import filterRaycasterIntersects from "../../utils/filterRaycasterIntersects";
import {
  transformDragToGridCoords,
  transformGridToDragCoords,
  findGridItems,
} from "../../utils/transformCoords";
import useGridItemModelCatalog from "../../hooks/useGridItemModelCatalog";
import useKeyPressListener from "../../../common/utils/keyboardListener";
import SimulatorContext from "../../context/SimulatorContext";

function DragItem() {
  const { mapState, buildItem, setBuildItem } = useContext(SimulatorContext);
  const [color, setColor] = useState("#48539d");
  const dragItemRef = useRef<THREE.Mesh>(null!);
  useKeyPressListener(() => {
    dragItemRef.current.rotateZ(-Math.PI / 2);
  });

  const catalog = useGridItemModelCatalog();
  const { geometry: dragGeometry } = catalog[buildItem.type][buildItem.size];

  const dragMaterial = useMemo(
    () =>
      new THREE.MeshPhongMaterial({
        color: "black",
        opacity: 0,
        transparent: true,
      }),
    []
  );

  useFrame(({ camera, mouse, raycaster, scene }) => {
    // Generate Raycaster and determine intersect with the ground
    raycaster.setFromCamera(new THREE.Vector2(mouse.x, mouse.y), camera); // Set raycaster from the mouse position
    const intersectObjects = raycaster.intersectObjects(scene.children, false);
    const [groundIntersect] = filterRaycasterIntersects({
      intersectObjects,
      filter: "ground",
    });

    if (groundIntersect) {
      // Determine grid coords of ground intersection point
      const gridCoords = transformDragToGridCoords(
        [groundIntersect.point.x, 0, groundIntersect.point.z],
        buildItem.size
      );
      // Transform grid coords back to drag coords for snapping the drag item into the grid
      const [x, y, z] = transformGridToDragCoords(gridCoords, buildItem.size);
      // Set postion of drag item
      dragItemRef.current.position.x = x;
      dragItemRef.current.position.y = y;
      dragItemRef.current.position.z = z;

      // Determine if the drag item is over another item and set the color accordingly
      const foundItem = findGridItems(gridCoords, buildItem.size, mapState);
      if (foundItem && color !== "#be3535") {
        setColor("#be3535"); // red
      } else if (!foundItem && color !== "#48539d") {
        setColor("#48539d"); // blue
      }
    } else if (buildItem.state === "submit" && buildItem.gridCoords) {
      // Drag item has been dropped. Transform the set grid coords to the three location.
      const [x, y, z] = transformGridToDragCoords(
        buildItem.gridCoords,
        buildItem.size
      );
      dragItemRef.current.position.x = x;
      dragItemRef.current.position.y = y;
      dragItemRef.current.position.z = z;
    }
  });

  const handleDoubleClick = () => {
    const gridCoords = transformDragToGridCoords(
      [dragItemRef.current.position.x, 0, dragItemRef.current.position.z],
      buildItem.size
    );

    if (color === "#48539d") {
      setBuildItem((prevItem) => ({
        ...prevItem,
        gridCoords,
        state: "submit",
        rotation: dragItemRef.current.rotation.z,
      }));
    }
  };

  return (
    <mesh
      name="drag-item"
      ref={dragItemRef}
      material={dragMaterial}
      geometry={dragGeometry}
      rotation={[Math.PI / 2, 0, 0]}
      scale={constants.CAD_FACTOR * constants.SCALE}
      onDoubleClick={handleDoubleClick}
    >
      <Edges
        scale={1}
        threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
        visible={buildItem.state !== "submit"}
        color={color}
      />
    </mesh>
  );
}

export default DragItem;
