import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/solid";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import ArrowUturnLeftIcon from "@heroicons/react/24/solid/ArrowUturnLeftIcon";
import SimulatorContext from "../../context/SimulatorContext";
import Toolbar from "../../../common/components/Toolbar";
import Select from "../../../common/components/Select";
import { convertStepToDate } from "../../../common/utils/formatUtility";
import Tooltip from "../../../common/components/Tooltip";

function TimelapseControls() {
  const {
    simulationId,
    loadingMap,
    mapState,
    finalTimestep,
    playing,
    play,
    stop,
    reset,
    speed,
    setSpeed,
    // networkPause,
    setTimestep,
    // setTimestepRangeMin,
  } = useContext(SimulatorContext);

  const navigate = useNavigate();

  const { step } = mapState;

  return (
    <Toolbar>
      <button
        type="button"
        className="hover:text-base-gray-100"
        onClick={playing ? stop : play}
      >
        {playing ? (
          <PauseIcon className="w-4 h-4 m-2" />
        ) : (
          <PlayIcon className="w-4 h-4 m-2" />
        )}
      </button>
      <Tooltip
        icon={<ArrowUturnLeftIcon className="w-4 h-4 m-2" />}
        text="Restart"
        onClick={reset}
      />
      <Select
        id="speed"
        label="Speed:"
        placement="top"
        value={{ value: speed, label: `${speed.toString()}x` }}
        options={[
          { value: 0.25, label: "0.25x" },
          { value: 0.5, label: "0.5x" },
          { value: 1, label: "1x" },
          { value: 2, label: "2x" },
          { value: 3, label: "3x" },
          { value: 4, label: "4x" },
        ]}
        disabled={playing || loadingMap}
        onChange={(opt) => setSpeed(opt.value)}
      />
      <Select
        id="timestep"
        placement="top"
        value={{ value: step, label: convertStepToDate(step) }}
        options={Array.from({ length: finalTimestep + 1 }).map((_, index) => ({
          value: index,
          label: convertStepToDate(index),
        }))}
        disabled={playing || loadingMap}
        onChange={(opt) => {
          setTimestep(opt.value);
          // setTimestepRangeMin(opt.value);
        }}
      />
      {/* {networkPause && (
        <div className="text-base-red inline-block m-2">Buffering</div>
      )} */}
      <Tooltip
        text="Simulation Results"
        onClick={() => navigate(`/simulation/summary/${simulationId}`)}
        icon={<ChartBarIcon className="w-4 h-4 m-2" />}
      />
    </Toolbar>
  );
}

export default TimelapseControls;
