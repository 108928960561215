/* eslint-disable react/require-default-props */
import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

export interface Dataset {
  data: number[];
  label: string;
  color: string;
  dash?: boolean;
  gradient?: boolean;
}

interface LineChartProps {
  title?: string;
  labels: string[];
  datasets: Dataset[];
  yScale?: {
    min: number;
    max: number;
  };
  animate?: boolean;
}

function LineChart({
  title,
  labels,
  datasets,
  yScale,
  animate = true,
}: LineChartProps) {
  const chartRef = useRef<HTMLCanvasElement>(null);

  function getGradient(color: string, myChartRef: CanvasRenderingContext2D) {
    const gradientBg = myChartRef.createLinearGradient(0, 0, 0, 1200);
    gradientBg.addColorStop(0, color);
    gradientBg.addColorStop(0.6, "white");
    return gradientBg;
  }

  useEffect(() => {
    if (chartRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const myChartRef = chartRef.current.getContext("2d")!;
      if (myChartRef) {
        const myChart = new Chart(myChartRef, {
          type: "line",
          data: {
            labels,
            datasets: datasets.map((dataset) => ({
              label: dataset.label,
              data: dataset.data,
              fill: dataset.gradient,
              borderColor: dataset.color,
              backgroundColor: getGradient(dataset.color, myChartRef),
              borderWidth: 2,
              tension: 0.1,
              borderDash: dataset.dash ? [5, 5] : undefined,
            })),
          },
          options: {
            scales: yScale
              ? {
                  y: yScale,
                }
              : undefined,
            elements: {
              point: {
                pointStyle: false,
              },
            },
            plugins: {
              legend: {
                display: false,
                position: "bottom",
              },
            },
            animation: {
              duration: animate ? 1000 : 0,
            },
          },
        });
        return () => {
          myChart.destroy();
        };
      }
    }

    return () => {};
  }, [labels, datasets, animate, yScale]);

  return (
    <div className="flex mx-auto h-[500px]">
      {title && <div className="text-l text-black">{title}</div>}
      <canvas className="h-full" ref={chartRef} />
    </div>
  );
}

export default LineChart;
