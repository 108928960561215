import { GridItemType, Business } from "@rodel-futures-simulator/types";
import React, {
  useState,
  useContext,
  FormEventHandler,
  useEffect,
} from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { calcAvailableFunds } from "../../utils/investUtils";
import { formatDollarValue } from "../../../common/utils/formatUtility";
import SimulatorContext from "../../context/SimulatorContext";
import Button from "../../../common/components/Button";
import Input from "../../../common/components/Input";
import FormRow from "../../../common/components/FormRow";
import Select from "../../../common/components/Select";

interface BusinessFormProps {
  business: Business;
}

interface BusinessIntervention extends Business {
  investment?: number;
  allocation?: string;
}

function BusinessInterventionForm({ business }: BusinessFormProps) {
  const { interventions, setInterventions } = useContext(SimulatorContext);

  const [editInvestment, setEditInvestment] = useState<boolean>(true);
  const [businessFormState, setBusinessFormState] =
    useState<BusinessIntervention>({
      ...business,
      investment: undefined,
      allocation: undefined,
    });
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const foundInvestment = interventions.investments.find(
      (investment) =>
        investment.xCoord === business.xCoord &&
        investment.yCoord === business.yCoord
    );

    if (foundInvestment) {
      setEditInvestment(false);
      setBusinessFormState({
        ...business,
        investment: foundInvestment.amount,
        allocation: foundInvestment.allocation,
      });
    }
  }, [interventions.investments, business, business.xCoord, business.yCoord]);

  const submitAddGridItem: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const buildCost = businessFormState.investment;
    const availableFunds = calcAvailableFunds(interventions);

    if (!buildCost) {
      setMessage("You must invest more than $0.");
    } else if (buildCost <= availableFunds) {
      setInterventions((prevInterventions) => {
        let investmentFound = false;
        const updatedInvestments = prevInterventions.investments.map(
          (investment) => {
            if (
              investment.xCoord === business.xCoord &&
              investment.yCoord === business.yCoord
            ) {
              investmentFound = true;
              return {
                type: GridItemType.BUSINESS,
                size: business.size,
                xCoord: business.xCoord,
                yCoord: business.yCoord,
                amount: buildCost,
                allocation: businessFormState.allocation
                  ? businessFormState.allocation
                  : "",
              };
            }
            return investment;
          }
        );

        if (!investmentFound) {
          updatedInvestments.push({
            type: GridItemType.BUSINESS,
            size: business.size,
            xCoord: business.xCoord,
            yCoord: business.yCoord,
            amount: buildCost,
            allocation: businessFormState.allocation
              ? businessFormState.allocation
              : "",
          });
        }

        return {
          ...prevInterventions,
          investments: updatedInvestments,
        };
      });
    } else {
      setMessage("You do not have enough funds to make this investment");
    }
  };

  return (
    <div>
      {businessFormState.investment && !editInvestment ? (
        <div>
          <div className="flex justify-end">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setEditInvestment(true);
              }}
              icon={
                <PencilIcon className="h-3 w-3 mr-0.5 mt-0.5 text-blue-500" />
              }
            >
              <div className="text-xs text-blue-500">Edit</div>
            </Button>
          </div>
          <div>
            You have invested{" "}
            <span className="font-bold text-base-rodel-100">
              {formatDollarValue(businessFormState.investment)}
            </span>{" "}
            in this business area
          </div>
        </div>
      ) : (
        <>
          <form onSubmit={submitAddGridItem}>
            <div className="flex flex-col gap">
              <FormRow label="Investment ($)">
                <Input
                  id="investment"
                  type="number"
                  min={0}
                  step="any"
                  name="investment"
                  value={businessFormState.investment ?? 0}
                  onChange={(event) => {
                    const investment = parseFloat(event.target.value);
                    setBusinessFormState({
                      ...businessFormState,
                      investment,
                    });
                  }}
                />
              </FormRow>
              <FormRow label="Allocation">
                <Select
                  value={
                    businessFormState.allocation
                      ? {
                          value: businessFormState.allocation,
                          label: businessFormState.allocation,
                        }
                      : null
                  }
                  options={[
                    { value: "Finance", label: "Finance" },
                    { value: "Healthcare", label: "Healthcare" },
                    {
                      value: "Engineering",
                      label: "Engineering",
                    },
                  ]}
                  onChange={(option) => {
                    setMessage("");
                    setBusinessFormState({
                      ...businessFormState,
                      allocation: option.value,
                    });
                  }}
                />
              </FormRow>
              <Button type="submit">Submit</Button>
            </div>
          </form>
          {message && <div className="text-base-red-100 mt-3">{message}</div>}
        </>
      )}
    </div>
  );
}

export default BusinessInterventionForm;
