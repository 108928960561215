import React from "react";
import {
  AcademicCapIcon,
  ArrowRightCircleIcon,
  BoltIcon,
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  LightBulbIcon,
  PowerIcon,
  SunIcon,
  TruckIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchIcon,
  BriefcaseIcon,
  GlobeAmericasIcon,
  BeakerIcon,
} from "@heroicons/react/24/outline";

const METRICS = {
  Schools: {
    numSchools: {
      title: "Schools",
      units: undefined,
      icon: <BuildingLibraryIcon className="w-4 h-4" color="green" />,
    },
    numStudents: {
      title: "Students",
      units: undefined,
      icon: <UsersIcon className="w-4 h-4" color="dodgerblue" />,
    },
    gradRate: {
      title: "Graduation Rate",
      units: undefined,
      icon: <AcademicCapIcon className="w-4 h-4" color="red" />,
    },
    postHSEnrollment: {
      title: "Post High School Enrollment",
      units: undefined,
      icon: <BeakerIcon className="w-4 h-4" color="green" />,
    },
    personnel: {
      title: "Personnel",
      units: "Million",
      icon: <BriefcaseIcon className="w-4 h-4" />,
    },
  },
  State: {
    population: {
      title: "Population",
      units: undefined,
      icon: <UserGroupIcon className="w-4 h-4" />,
    },
    budget: {
      title: "State Budget",
      units: "Million",
      icon: <CurrencyDollarIcon className="w-4 h-4" color="green" />,
    },
    newBusinesses: {
      title: "New Businesses",
      units: "Million",
      icon: <BuildingOfficeIcon className="w-4 h-4" color="blue" />,
    },
    migratedBusinesses: {
      title: "Migrated Businesses",
      units: "Million",
      icon: <ArrowRightCircleIcon className="w-4 h-4" color="red" />,
    },
    unemployment: {
      title: "Unemployment",
      units: undefined,
      icon: <BriefcaseIcon className="w-4 h-4" color="red" />,
    },
    annualHouseholdIncome: {
      title: "Median Annual Household Income",
      units: "",
      icon: <CurrencyDollarIcon className="w-4 h-4" color="green" />,
    },
  },
  Resources: {
    powerGen: {
      title: "Power Generation",
      units: "kWh",
      icon: <PowerIcon className="w-4 h-4 text-yellow" color="red" />,
    },
    powerUsage: {
      title: "Power Usage",
      units: "kWh",
      icon: <LightBulbIcon className="w-4 h-4" color="gold" />,
    },
    waterUsage: {
      title: "Water Usage",
      units: "CCF",
      icon: <ChartPieIcon className="w-4 h-4" color="dodgerblue" />,
    },
    waterProcessed: {
      title: "Water Processed for Use",
      units: "CCF",
      icon: <WrenchIcon className="w-4 h-4" />,
    },
  },
  Sustainability: {
    emissions: {
      title: "Emissions",
      units: "Mmt",
      icon: <GlobeAmericasIcon className="w-4 h-4" color="dodgerblue" />,
    },
    vehicleMilesTraveled: {
      title: "Vehicle Miles Traveled",
      units: undefined,
      icon: <TruckIcon className="w-4 h-4" />,
    },
    elecVehicles: {
      title: "% Electric Vehicles",
      units: undefined,
      icon: <BoltIcon className="w-4 h-4" color="gold" />,
    },
    solarHomes: {
      title: "% Homes with Solar",
      units: undefined,
      icon: <SunIcon className="w-4 h-4" color="gold" />,
    },
  },
};

export default METRICS;
