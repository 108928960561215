/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes, forwardRef, Ref } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line react/require-default-props
  label?: string | undefined;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, className, ...props }: InputProps, ref: Ref<HTMLInputElement>) => {
    return (
      <div className={className}>
        {label && <label htmlFor={props.id}>{label}</label>}
        <input
          ref={ref}
          className="w-full h-9 relative cursor-pointer text-base-gray-100 disabled:opacity-70 disabled:cursor-not-allowed rounded-md bg-white py-2 px-3 text-left border border-gray-200 focus:outline-none focus-visible:border-base-green-50 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm"
          {...props}
        />
      </div>
    );
  }
);

export default Input;
