/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { InputHTMLAttributes } from "react";
import { formatDollarValue, formatPercentage } from "../utils/formatUtility";

interface SliderProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label?: string;
  min: number;
  max: number;
  step?: number;
  value?: number;
  onChange: (val: number) => void;
  className?: string;
  units?: "dollar" | "percent";
}

export default function Slider({
  label,
  value,
  min,
  max,
  step = 1,
  onChange,
  className = "",
  units,
}: SliderProps) {
  function formatLabel(val: number) {
    let l = "";

    if (!units) {
      l = val.toString();
    } else if (units === "dollar") {
      l = formatDollarValue(val);
    } else if (units === "percent") {
      const decimalPartLength = step.toString().split(".")[1];
      const decimalLength = decimalPartLength ? decimalPartLength.length : 0;
      l = formatPercentage(val, Math.max(decimalLength - 2, 0));
    }
    return l;
  }

  return (
    <div className={`flex flex-col w-full space-y-3 ${className}`}>
      <div className="flex flex-row place-content-between">
        {label && <div className="text-sm pt-2">{label}</div>}
        <div className="rounded-md pt-2 text-base-gray-100 text-sm">
          {value !== undefined && formatLabel(value)}
        </div>
      </div>
      <input
        type="range"
        className="appearance-none w-full h-2 bg-gray-300 rounded-md outline-none transition-all duration-300 ease-in-out hover:bg-gray-400 focus:bg-gray-400"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
}
