import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowUpCircleIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { usePostIntervention } from "../../../api/mutations";
import SimulatorContext from "../../context/SimulatorContext";
import Button from "../../../common/components/Button";
import Toolbar from "../../../common/components/Toolbar";
import Modal from "../../../common/components/Modal";

function SubmitControls() {
  const { interventions, simulationId } = useContext(SimulatorContext);

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [interventionConfirm, setInterventionConfirm] = useState(false);
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const mutation = usePostIntervention({
    onMutate: async () => {
      setIsLoading(true);
      setMessage(undefined);
    },
    onSuccess: async () => {
      setInterventionConfirm(true);
    },
    onError: () => {
      setMessage(
        "There was an error submitting your interventions. Please try again."
      );
    },
  });

  function getModalTitle() {
    if (interventionConfirm) {
      return "We are Building Your Simulation";
    }
    return "Ready to submit your simulation?";
  }

  function getModalContent() {
    if (interventionConfirm)
      return (
        <div className="h-48 flex flex-col justify-between">
          <div className="mt-4">
            We are building your simulation. We will alert you when it is ready.
          </div>
          <div className="mt-8">
            <Button className="w-full" onClick={() => navigate("/")}>
              Return to Dashboard
            </Button>
          </div>
        </div>
      );
    return (
      <div className="h-48 flex flex-col justify-between">
        <div className="mt-4">
          Processing your simulation will take a few minutes to complete. Please
          confirm that all of your interventions are correct.
        </div>
        <div className="mt-3">
          <Button
            className="w-full mt-8"
            onClick={() => {
              mutation.mutate({ simulationId, interventions });
            }}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>
        {message && <div className="text-base-red-100 mt-3">{message}</div>}
      </div>
    );
  }

  return (
    <>
      <Toolbar>
        <div className="flex">
          <h3 className="font-medium p-2 mr-2">Submit Simulation</h3>
          <button
            type="button"
            onClick={() => {
              setShowSubmitModal(true);
            }}
          >
            <ArrowUpCircleIcon color="#2E6A92" className="w-6 h-6" />
          </button>
        </div>
      </Toolbar>
      <Modal
        size="sm"
        header={<SparklesIcon color="#99C59C" className="w-8 h-8 mb-4" />}
        title={getModalTitle()}
        isOpen={showSubmitModal}
        onCloseModal={() => setShowSubmitModal(false)}
      >
        {getModalContent()}
      </Modal>
    </>
  );
}

export default SubmitControls;
