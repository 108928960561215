/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "./Button";

interface ModalProps {
  header?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  isOpen?: boolean;
  onCloseModal: () => void;
  size?: "sm" | "md" | "lg";
}

export default function MyModal({
  header,
  title,
  subtitle,
  children,
  footer,
  isOpen = true,
  size = "md",
  onCloseModal,
}: ModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal} static>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${
                  size === "lg" ? "w-9/12" : size === "md" ? "w-4/12" : "w-80"
                } transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all`}
              >
                <div className="flex flex-row justify-between">
                  <div>
                    {header}
                    <Dialog.Title as="h3">{title}</Dialog.Title>
                  </div>
                  <Button
                    color="secondary"
                    onClick={onCloseModal}
                    icon={<XMarkIcon />}
                    className="w-4 h-4"
                    variant="standard"
                    shadow={false}
                  />
                </div>
                {subtitle ? (
                  <div className="mt-2">
                    <div className="text-xs text-gray-500">{subtitle}</div>
                  </div>
                ) : (
                  <div />
                )}
                <div>{children}</div>
                {footer ? (
                  <div>
                    <hr className="text-base-gray-80 my-1" />
                    <div className="mt-4">{footer}</div>
                  </div>
                ) : (
                  <div />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
