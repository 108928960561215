/* eslint-disable react/require-default-props */
import React, { ReactNode, CSSProperties } from "react";

interface CardProps {
  style: CSSProperties;
  title?: string;
  subtitle?: string;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
}

function Card({ style, title, subtitle, header, footer, children }: CardProps) {
  return (
    <div
      style={style}
      className="flex flex-col py-4 px-3 bg-base-gray-60 w-[180px] h-[208px] rounded-lg border-[1px] border-base-gray-70"
    >
      <div className="mb-2">{header}</div>
      <h3 className="capitalize">{title}</h3>
      <p className="capitalize text-sm">{subtitle}</p>
      <div className="grow">{children}</div>
      <div className="flex flex-col text-sm">{footer}</div>
    </div>
  );
}

export default Card;
