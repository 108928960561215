/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useMemo, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import filterRaycasterIntersects from "../utils/filterRaycasterIntersects";
import SimulatorContext from "../context/SimulatorContext";

// This is the use frame for the entire Map.
// Any access to useFrame should be done here.
const useCustomFrame = () => {
  const {
    selectRef,
    mapControlsRef,
    compassRef,
    loadingScene,
    setLoadingScene,
  } = useContext(SimulatorContext);

  const screenCenter = useMemo(() => new THREE.Vector2(0, 0), []);
  useFrame(({ scene, camera, raycaster }) => {
    if (scene.children.length > 0 && loadingScene) setLoadingScene(false);
    // Every frame generate a raycaster from the center of the camera and determine the intersect with the grid.
    raycaster.setFromCamera(screenCenter, camera);
    const intersectObjects = raycaster.intersectObjects(scene.children, false);
    const [cityIntersect] = filterRaycasterIntersects({
      intersectObjects,
      filter: "city",
    });
    const [countyIntersect] = filterRaycasterIntersects({
      intersectObjects,
      filter: "county",
    });

    // Set the value of the city select based on intersection with the map
    if (cityIntersect && selectRef.current) {
      const city = (cityIntersect.object.userData.value as string).split(
        "_"
      )[0];
      if (selectRef.current.innerText !== city) {
        selectRef.current.innerHTML = `${city}`;
      }
    } else if (countyIntersect && !cityIntersect) {
      const county = `${
        (countyIntersect.object.userData.value as string).split("_")[0]
      } County`;
      if (selectRef.current.innerText !== county) {
        selectRef.current.innerHTML = county;
      }
    } else if (!cityIntersect && !countyIntersect) {
      selectRef.current.innerHTML = "Nothing...";
    }

    // Rotate the compass according to map controls azimuthal angle
    if (mapControlsRef.current && compassRef.current) {
      compassRef.current.style.transform = `rotate(${mapControlsRef.current.getAzimuthalAngle()}rad)`;
    }
  });
};

export default useCustomFrame;
