import React, { useContext, useEffect, useMemo, useState } from "react";
import TabGroup from "../../../common/components/TabGroup";
import Slider from "../../../common/components/Slider";
import SimulatorContext from "../../context/SimulatorContext";
import { colors } from "../../../common/constants/constants";
import EditBudget from "./EditBudget";
import Budget from "./Budget";

function FieldWrapper({ children }: { children: React.ReactNode }) {
  return <div className="w-full">{children}</div>;
}

function CommandControls() {
  const { mapState } = useContext(SimulatorContext);

  const budgetData = useMemo(() => {
    const updatedCategories = mapState.budget.categories.map(
      ({ name, amountInMillions }, index) => ({
        label: name,
        value: amountInMillions,
        color: colors[index % colors.length],
      })
    );

    return updatedCategories;
  }, [mapState.budget.categories]);

  const totalBudget = budgetData.reduce(
    (acc, category) => acc + category.value,
    0
  );

  const defaultValuesPercentage = useMemo(() => {
    return budgetData.map((category) =>
      ((category.value / totalBudget) * 100).toFixed(1)
    );
  }, [budgetData, totalBudget]);

  const [editBudget, setEditBudget] = useState(false);

  const [, setSum] = useState<number>();

  const [inputValues] = useState<string[]>(defaultValuesPercentage);

  const [commandState, setCommandState] = useState({
    gradRate: 0.762,
    studentCapacity: 1097856,
    salesTax: 0.056,
    elecVehicle: 0,
    homeSolar: 1000,
    busAcquisition: 0,
  });

  useEffect(() => {
    const sum = inputValues.reduce(
      (acc, val) => acc + Number(val.replace("%", "")),
      0
    );
    setSum(sum / 100);
  }, [inputValues]);

  return (
    <div>
      <TabGroup tabs={["Budget", "School", "Energy", "Business"]}>
        {/* Budget */}
        <TabGroup.Panel>
          <FieldWrapper>
            {editBudget ? (
              <EditBudget setEditBudget={setEditBudget} />
            ) : (
              <Budget setEditBudget={setEditBudget} />
            )}
          </FieldWrapper>
        </TabGroup.Panel>
        {/* School */}
        <TabGroup.Panel>
          <div className="p-4">
            <FieldWrapper>
              <Slider
                label="Graduation Rate"
                min={0}
                max={1}
                step={0.01}
                onChange={(val) => {
                  setCommandState({
                    ...commandState,
                    gradRate: val,
                  });
                }}
                value={commandState.gradRate}
                units="percent"
              />
            </FieldWrapper>

            <FieldWrapper>
              <Slider
                label="Total Student Capacity"
                min={1000000}
                max={5000000}
                onChange={(val) => {
                  setCommandState({
                    ...commandState,
                    studentCapacity: val,
                  });
                }}
                value={commandState.studentCapacity}
                step={10}
              />
            </FieldWrapper>
          </div>
        </TabGroup.Panel>
        {/* Energy */}
        <TabGroup.Panel>
          <div className="p-4">
            <FieldWrapper>
              <Slider
                label="State Electric Vehicle Incentive (Per Vehicle)"
                min={0}
                max={1000}
                onChange={(val) => {
                  setCommandState({
                    ...commandState,
                    elecVehicle: val,
                  });
                }}
                value={commandState.elecVehicle}
                units="dollar"
              />
            </FieldWrapper>

            <FieldWrapper>
              <Slider
                label="Home Solar Conversion Rebate (Per Home)"
                min={0}
                max={10000}
                onChange={(val) => {
                  setCommandState({
                    ...commandState,
                    homeSolar: val,
                  });
                }}
                value={commandState.homeSolar}
                units="dollar"
              />
            </FieldWrapper>
          </div>
        </TabGroup.Panel>

        {/* Business */}
        <TabGroup.Panel>
          <div className="p-4">
            <FieldWrapper>
              <Slider
                label="Sales Tax"
                min={0}
                max={0.15}
                step={0.001}
                onChange={(val) => {
                  setCommandState({
                    ...commandState,
                    salesTax: val,
                  });
                }}
                value={commandState.salesTax}
                units="percent"
              />
            </FieldWrapper>

            <FieldWrapper>
              <Slider
                label="Incentives for Business Acquisition (Total Spend)"
                min={0}
                max={10000}
                onChange={(val) => {
                  setCommandState({
                    ...commandState,
                    busAcquisition: val,
                  });
                }}
                value={commandState.busAcquisition}
                units="dollar"
              />
            </FieldWrapper>
          </div>
        </TabGroup.Panel>
      </TabGroup>
    </div>
  );
}

export default CommandControls;
