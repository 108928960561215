import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Simulator from "./simulator/Simulator";
import SimResultsPage from "./resultsPage/SimResultsPage";
import SimulatorDashboard from "./dashboard/SimulatorDashboard";
import ErrorPage from "./errorPage/ErrorPage";
import AuthPage from "./userPage/AuthPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/simulation/:id" element={<Simulator />} />
        <Route path="/simulation/summary/:id" element={<SimResultsPage />} />
        <Route path="/login" element={<AuthPage isLogin />} />
        <Route path="/signup" element={<AuthPage isLogin={false} />} />
        <Route path="/" element={<SimulatorDashboard />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
