import {
  GridItem,
  GridItemSize,
  GridItemType,
} from "@rodel-futures-simulator/types";
import { Bounds, Coord } from "../../../../common/types/types";
import * as constants from "../../../../common/constants";
import { isWithinBounds } from "./boundaryUtils";

// Generates filler grid items that will cover the area of the loading boundary
// that is unoccupied by meshes
export function generateFillerGridItems(
  bounds: Bounds,
  gridItems: GridItem[]
): GridItem[] {
  const desertTileCoords: Coord[] = [];

  const occupiedCoords = gridItems.flatMap((gridItem) => {
    const coords = [gridItem.xCoord, gridItem.yCoord];
    const rawSize = gridItem.size;
    let size = 0;
    switch (rawSize) {
      case GridItemSize.QUARTER:
        size = 0;
        break;
      case GridItemSize.HALF:
        size = 1;
        break;
      case GridItemSize.FULL:
        size = 3;
        break;
      default:
        break;
    }

    const additionalCoords: string[] = [];
    const xMin = coords[0];
    const yMin = coords[1];
    const xMax = xMin + size;
    const yMax = yMin + size;

    for (let x = xMin; x <= xMax; x++) {
      for (let y = yMin; y <= yMax; y++) {
        // create coord here
        additionalCoords.push(`${x}_${y}`);
      }
    }

    return additionalCoords;
  });

  const set = new Set(occupiedCoords);

  for (let x = bounds.xMin; x <= bounds.xMax; x++) {
    for (let y = bounds.yMin; y <= bounds.yMax; y++) {
      if (!set.has(`${x}_${y}`)) {
        desertTileCoords.push({ x, y });
      }
    }
  }

  const tiles: GridItem[] = desertTileCoords.map((coords) => {
    const randIdx = Math.floor(Math.random() * constants.ROTATION_VALS.length);
    const rotation = constants.ROTATION_VALS[randIdx];
    return {
      type: GridItemType.DESERT,
      xCoord: coords.x,
      yCoord: coords.y,
      rotation,
      size: GridItemSize.QUARTER,
    };
  });

  return tiles;
}

export function filterGridItems(gridItems: GridItem[], bounds: Bounds) {
  const filtered: GridItem[] = [];
  const newBounds = {
    xMin: bounds.xMin - 3,
    xMax: bounds.xMax + 3,
    yMin: bounds.yMin - 3,
    yMax: bounds.yMax + 3,
  };
  gridItems.forEach((gridItem) => {
    const isWithinBoundary = isWithinBounds(
      { x: gridItem.xCoord, y: gridItem.yCoord },
      newBounds
    );

    if (isWithinBoundary) {
      filtered.push(gridItem);
    }
  });
  return filtered;
}
