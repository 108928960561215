import React, { useContext, useMemo } from "react";
import {
  ModalDetailsRes,
  GridItemType,
  GridItem,
  School,
} from "@rodel-futures-simulator/types";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { GridItemModalType } from "../../../common/types/types";
import { useGetGridItem } from "../../../api/queries";
import { colors } from "../../../common/constants/constants";
import SimulatorContext from "../../context/SimulatorContext";
import SchoolInterventionForm from "./SchoolInterventionForm";
import BusinessInterventionForm from "./BusinessInterventionForm";
import NeighborhoodInterventionForm from "./NeighborhoodInterventionForm";
import PowerPlantInterventionForm from "./PowerPlantInterventionForm";
import { getItemSizeString } from "../../utils/getEnum";
import InteractionModal from "../../../common/components/InteractionModal";
import Button from "../../../common/components/Button";
import {
  formatNumber,
  formatPercentage,
  formatDollarValue,
} from "../../../common/utils/formatUtility";
import ChartLegend from "../../../common/components/ChartLegend";
import DoughnutChart from "../../../common/components/DoughnutChart";
import TabGroup from "../../../common/components/TabGroup";

interface FixedDetailModalProps {
  item: GridItemModalType;
  onClose: () => void;
}

export default function FixedDetailModal({
  item,
  onClose,
}: FixedDetailModalProps) {
  const { simulationId, mapState, setGridItemModal } =
    useContext(SimulatorContext);
  const { step } = mapState;
  const { data, isLoading } = useGetGridItem(
    {
      simulationId,
      xCoord: item.xCoord.toString(),
      yCoord: item.yCoord.toString(),
      name: item.name,
    },
    item.type
  );
  const gridItemData: ModalDetailsRes<GridItem>[] | undefined =
    data?.data.filter((timestep) => timestep.step === step);

  function getModalTitle(d: ModalDetailsRes<GridItem>[] | undefined) {
    if (!d) return <div>Details Unavailable</div>;

    if (item.index !== undefined || d.length === 1) {
      const itemIndex = item.index ? item.index : 0;
      const modalData = d[itemIndex].modalDetails;

      if (modalData.type === GridItemType.SCHOOL) {
        return modalData.name;
      }
      if (modalData.type === GridItemType.BUSINESS) {
        return `${getItemSizeString(modalData.size)} Business`;
      }
      if (modalData.type === GridItemType.NEIGHBORHOOD) {
        return `${getItemSizeString(modalData.size)} Neighborhood`;
      }
      if (modalData.type === GridItemType.POWER_PLANT) {
        return modalData.name;
      }
    }
    return "School Overview";
  }

  function getModalSubtitle(d: ModalDetailsRes<GridItem>[] | undefined) {
    if (!d) return undefined;

    if (item.index !== undefined || d.length === 1) {
      return `${item.type}: Coordinates (${item.xCoord}, ${item.yCoord})`;
    }
    return "List of Schools in the Area";
  }

  function getModalHeader(modalData: ModalDetailsRes<GridItem>[] | undefined) {
    if (!modalData) return undefined;
    if (item.index !== undefined && modalData.length > 1)
      return (
        <Button
          color="secondary"
          icon={<ArrowLeftIcon />}
          className="w-fit h-4"
          shadow={false}
          onClick={() => setGridItemModal({ ...item, index: undefined })}
        >
          Back
        </Button>
      );
    return undefined;
  }

  const tabs = useMemo(() => {
    return ["Summary", "Interventions"];
  }, []);

  function getModalContent(d: ModalDetailsRes<GridItem>[] | undefined) {
    if (!d) return <div>Details are not available at this timestep</div>;

    const details: { label: string; value: string }[] = [];

    if (item.index !== undefined || d.length === 1) {
      const itemIndex = item.index ? item.index : 0;
      const modalData = d[itemIndex].modalDetails;
      if (modalData.type === GridItemType.SCHOOL) {
        details.push({
          label: "School Type",
          value: modalData.schoolType.toString(),
        });
        details.push({
          label: "Number of Students",
          value: modalData.numberOfStudents
            ? formatNumber(modalData.numberOfStudents)
            : formatNumber(0),
        });
        details.push({
          label: "Graduation Rate",
          value: modalData.graduationRate
            ? formatPercentage(modalData.graduationRate / 100)
            : "Graduation Rate Unavailable",
        });
        details.push({
          label: "District",
          value: modalData.district!,
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const chartData = modalData
          .schoolFundingInfo!.sort((a, b) => b.funding - a.funding)
          .slice(0, 3)
          .map(({ name, funding }, index) => ({
            label: name,
            value: funding,
            formattedVal: formatDollarValue(funding / 1000),
            color: colors[index % colors.length],
          }));
        const chartLabel = "Million";
        const chartTitle = `${modalData.district!} Funding`;
        const chartHeader = "Funding Sources";
        const chartID = "fundingDetailsType";
        const chartVal = {
          value: "Funding (in millions)",
          label: "Funding (in millions)",
        };
        const chartOptions = [
          {
            value: "Funding (in millions)",
            label: "Funding (in millions)",
          },
        ];
        const centerValue = `$${(
          chartData.reduce((acc, curr) => acc + curr.value, 0) / 1000
        ).toFixed(2)}`;
        const show = centerValue !== "$0.00";
        return (
          <TabGroup tabs={tabs}>
            <TabGroup.Panel>
              <div className="text-sm text-gray-500 space-y-4">
                {details.map((detail) => {
                  return (
                    <div key={detail.label}>
                      <div className="flex flex-row space-x-2">
                        <p className="font-medium text-base-black">
                          {detail.label}:
                        </p>
                        <p>{detail.value}</p>
                      </div>
                      <hr className="text-base-gray-80 my-1" />
                    </div>
                  );
                })}
              </div>
              {show && (
                <ChartLegend
                  data={chartData}
                  title={chartTitle}
                  header={chartHeader}
                  id={chartID}
                  val={chartVal}
                  options={chartOptions}
                  colors={colors}
                >
                  <DoughnutChart
                    data={chartData}
                    label={chartLabel}
                    centerValue={centerValue}
                  />
                </ChartLegend>
              )}
              {!show && (
                <div className="mb-2 mt-4">
                  <p className="text-center font-bold">
                    {`${modalData.district!} Funding Data Coming Soon...`}
                  </p>
                </div>
              )}
            </TabGroup.Panel>
            <TabGroup.Panel>
              <SchoolInterventionForm school={modalData} />
            </TabGroup.Panel>
          </TabGroup>
        );
      }
      if (modalData.type === GridItemType.BUSINESS) {
        details.push({
          label: "Number of Employees",
          value: formatNumber(modalData.numberOfEmployees),
        });
        const sortedChartData = modalData
          .industryInfo!.sort((a, b) => b.numWorkers - a.numWorkers)
          .slice(0, 4)
          .map(({ name, numWorkers }, index) => ({
            label: name,
            value: numWorkers,
            color: colors[index % colors.length],
          }));
        const otherValue = modalData
          .industryInfo!.slice(4)
          .reduce((acc, { numWorkers }) => acc + numWorkers, 0);
        const otherChartData = {
          label: "Other",
          value: otherValue,
          color: colors[colors.length - 1],
        };
        const chartData = [...sortedChartData, otherChartData];
        const chartLabel = "Employees";
        const chartTitle = "Industry Breakdown";
        const chartHeader = "Industries in Grid Area";
        const chartID = "businessDetailsType";
        const chartVal = {
          value: "Number of Employees",
          label: "Number of Employees",
        };
        const chartOptions = [
          { value: "Number of Employees", label: "Number of Employees" },
        ];
        return (
          <TabGroup tabs={tabs}>
            <TabGroup.Panel>
              <div className="text-sm text-gray-500 space-y-4">
                {details.map((detail) => {
                  return (
                    <div key={detail.label}>
                      <div className="flex flex-row space-x-2">
                        <p className="font-medium text-base-black">
                          {detail.label}:
                        </p>
                        <p>{detail.value}</p>
                      </div>
                      <hr className="text-base-gray-80 my-1" />
                    </div>
                  );
                })}
              </div>
              <ChartLegend
                data={chartData}
                title={chartTitle}
                header={chartHeader}
                id={chartID}
                val={chartVal}
                options={chartOptions}
                colors={colors}
              >
                <DoughnutChart data={chartData} label={chartLabel} />
              </ChartLegend>
            </TabGroup.Panel>
            <TabGroup.Panel>
              <BusinessInterventionForm business={modalData} />
            </TabGroup.Panel>
          </TabGroup>
        );
      }
      if (modalData.type === GridItemType.NEIGHBORHOOD) {
        details.push({
          label: "Number of Households",
          value: formatNumber(modalData.numberOfHouseholds),
        });
        details.push({
          label: "Number of Residents",
          value: formatNumber(modalData.numberOfResidents),
        });
        details.push({
          label: "Average Household Salary",
          value: formatDollarValue(modalData.avgHouseholdSalary),
        });
        const sortedChartData = modalData
          .educationLevelInfo!.sort((a, b) => b.numResidents - a.numResidents)
          .slice(0, 4)
          .map(({ name, numResidents }, index) => ({
            label: name,
            value: numResidents,
            color: colors[index % colors.length],
          }));
        const otherValue = modalData
          .educationLevelInfo!.slice(4)
          .reduce((acc, { numResidents }) => acc + numResidents, 0);
        const otherChartData = {
          label: "Other",
          value: otherValue,
          color: colors[colors.length - 1],
        };
        const chartData = [...sortedChartData, otherChartData];
        const chartLabel = "Employed Residents";
        const chartTitle = "Education Level Breakdown";
        const chartHeader = "Education Level in Grid Area";
        const chartID = "educationLevelDetailsType";
        const chartVal = {
          value: "Employed Residents",
          label: "Employed Residents",
        };
        const chartOptions = [
          {
            value: "Employed Residents",
            label: "Employed Residents",
          },
        ];
        return (
          <TabGroup tabs={tabs}>
            <TabGroup.Panel>
              <div className="my-2">
                <div className="text-base text-gray-500">
                  General Information:
                </div>
              </div>
              <div className="text-sm text-gray-500 space-y-4">
                {details.map((detail) => {
                  return (
                    <div key={detail.label}>
                      <div className="flex flex-row space-x-2">
                        <p className="font-medium text-base-black">
                          {detail.label}:
                        </p>
                        <p>{detail.value}</p>
                      </div>
                      <hr className="text-base-gray-80 my-1" />
                    </div>
                  );
                })}
              </div>
              <ChartLegend
                data={chartData}
                title={chartTitle}
                header={chartHeader}
                id={chartID}
                val={chartVal}
                options={chartOptions}
                colors={colors}
              >
                <DoughnutChart data={chartData} label={chartLabel} />
              </ChartLegend>
            </TabGroup.Panel>
            <TabGroup.Panel>
              <NeighborhoodInterventionForm neighborhood={modalData} />
            </TabGroup.Panel>
          </TabGroup>
        );
      }
      if (modalData.type === GridItemType.POWER_PLANT) {
        details.push({
          label: "Power Type",
          value: modalData.powerType.toString(),
        });
        details.push({
          label: "Power Output",
          value: formatNumber(modalData.powerOutput),
        });
        return (
          <TabGroup tabs={tabs}>
            <TabGroup.Panel>
              <div className="text-sm text-gray-500 space-y-4">
                {details.map((detail) => {
                  return (
                    <div key={detail.label}>
                      <div className="flex flex-row space-x-2">
                        <p className="font-medium text-base-black">
                          {detail.label}:
                        </p>
                        <p>{detail.value}</p>
                      </div>
                      <hr className="text-base-gray-80 my-1" />
                    </div>
                  );
                })}
              </div>
            </TabGroup.Panel>
            <TabGroup.Panel>
              <PowerPlantInterventionForm powerPlant={modalData} />
            </TabGroup.Panel>
          </TabGroup>
        );
      }
    }

    const schools = d as ModalDetailsRes<School>[];
    return (
      <div>
        {schools.map((school, i) => (
          <div className="flex flex-col w-full" key={school.modalDetails.name}>
            <button
              type="button"
              className="cursor-pointer hover:bg-light-gray"
              onClick={() => {
                setGridItemModal({ ...item, index: i });
              }}
            >
              <p className="font-medium text-base text-left">
                {school.modalDetails.name}
                <span className="font-normol text-sm text-white text-center px-2 py-1 ml-2 bg-base-rodel-100 rounded-full">
                  {school.modalDetails.schoolType}
                </span>
              </p>
              <p className="text-sm text-gray-400 text-left">{`Number of Students: ${
                school.modalDetails.numberOfStudents
                  ? formatNumber(
                      school.modalDetails.numberOfStudents
                    ).toString()
                  : "0"
              }, Graduation Rate: ${
                school.modalDetails.graduationRate
                  ? formatPercentage(school.modalDetails.graduationRate / 100)
                  : "0.00%"
              }`}</p>
            </button>
            <hr className="text-base-gray-80 my-1" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <InteractionModal
      title={getModalTitle(gridItemData)}
      subtitle={getModalSubtitle(gridItemData)}
      header={getModalHeader(gridItemData)}
      isLoading={isLoading}
      onClose={onClose}
    >
      {getModalContent(gridItemData)}
    </InteractionModal>
  );
}
