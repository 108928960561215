/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react/no-unknown-property */
import React, { Suspense, useContext, useState } from "react";
import { Canvas, Vector3 } from "@react-three/fiber";
import { ArrowPathIcon, CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import { OrbitControls, OrthographicCamera } from "@react-three/drei";
import { GridItemSize, GridItemType } from "@rodel-futures-simulator/types";
import SimulatorContext from "../../context/SimulatorContext";
import Lights from "../../scene/components/Lights";
import useGridItemModelCatalog from "../../hooks/useGridItemModelCatalog";
import { getGridItemEnum } from "../../utils/getEnum";
import BuildForm from "./BuildForm";
import TabGroup from "../../../common/components/TabGroup";

function BuildControls() {
  const { buildItem, setBuildItem } = useContext(SimulatorContext);

  const catalog = useGridItemModelCatalog();
  const { geometry, modelTypes } = catalog[buildItem.type][buildItem.size];

  const getMeshScale = (size: GridItemSize, type: GridItemType) => {
    if (size === GridItemSize.QUARTER) return 1000;
    if (size === GridItemSize.HALF) return 550;
    if (size === GridItemSize.FULL && type === GridItemType.BUSINESS)
      return 190;

    return 250;
  };

  const getMeshPosition = (size: GridItemSize, type: GridItemType) => {
    if (size === GridItemSize.FULL && type === GridItemType.BUSINESS)
      return [0, -1.1, 0];
    return [0, 0, 0];
  };

  const creatableItems: { [type: string]: GridItemSize[] } = {
    [GridItemType.SCHOOL]: [GridItemSize.QUARTER, GridItemSize.HALF],
    [GridItemType.NEIGHBORHOOD]: [
      GridItemSize.QUARTER,
      GridItemSize.HALF,
      GridItemSize.FULL,
    ],
    [GridItemType.BUSINESS]: [
      GridItemSize.QUARTER,
      GridItemSize.HALF,
      GridItemSize.FULL,
    ],
    [GridItemType.POWER_PLANT]: [
      // GridItemSize.QUARTER,
      GridItemSize.HALF,
    ],
  };

  const tabs = [
    GridItemType.SCHOOL,
    GridItemType.NEIGHBORHOOD,
    GridItemType.BUSINESS,
    GridItemType.POWER_PLANT,
  ];

  const [activeTab, setActiveTab] = useState(GridItemType.SCHOOL);

  const mappedTabs = tabs.map((item) => item.toString());

  const getContent = () => {
    if (buildItem.state === "form") {
      return (
        <div className="w-auto flex flex-col items-center justify-center">
          <Suspense fallback="Loading...">
            <Canvas dpr={[1, 2]}>
              <Lights intensity={0.2} />
              <OrthographicCamera
                position={[8, 6, 12]}
                zoom={30}
                near={0.001}
                makeDefault
              />
              <OrbitControls
                autoRotate
                enablePan={false}
                minPolarAngle={0.5}
                maxPolarAngle={Math.PI / 2.25}
                dampingFactor={0.5}
              />
              <mesh
                rotation={[Math.PI / 2, 0, 0]}
                position={
                  getMeshPosition(buildItem.size, buildItem.type) as Vector3
                }
                scale={getMeshScale(buildItem.size, buildItem.type)}
                geometry={geometry}
                material={modelTypes.materialVariants[0]}
              />
            </Canvas>
          </Suspense>
          <TabGroup
            tabs={mappedTabs}
            setSelectedItem={(value: string) => {
              const gridItemType = getGridItemEnum(value);
              setBuildItem({
                state: "form",
                type: gridItemType,
                size: creatableItems[gridItemType][0],
              });
              setActiveTab(gridItemType);
            }}
            centered
          >
            <div className="flex justify-center items-center w-full">
              {mappedTabs.map((tab) => (
                <TabGroup.Panel key={tab}>
                  {tab === activeTab && (
                    <div className="flex justify-center mt-2" />
                  )}
                </TabGroup.Panel>
              ))}
            </div>
          </TabGroup>
        </div>
      );
    }

    if (buildItem.state === "drag")
      return (
        <>
          <div className="flex flex-row gap-2 items-center text-m text-gray-500">
            <CursorArrowRaysIcon className="w-4 h-4" />
            <span>Double Click to Place</span>
          </div>
          <div className="flex flex-row gap-2 items-center text-m text-gray-500">
            <ArrowPathIcon className="w-4 h-4" />
            <span>{`Press "R" to rotate the item`}</span>
          </div>
        </>
      );

    return (
      <div className="text-sm text-gray-500 w-72">
        This action cannot be undone. Confirm the build simulation before
        proceeding.
      </div>
    );
  };

  return (
    <div className="px-2">
      {getContent()}
      <BuildForm />
    </div>
  );
}

export default BuildControls;
