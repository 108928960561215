import React, { ReactNode } from "react";
import Select from "./Select";
import { ChartData } from "../types/types";

interface ChartProps {
  data: ChartData[];
  children: ReactNode;
  title: string;
  header: string;
  id: string;
  val: { value: string; label: string };
  options: { value: string; label: string }[];
  colors: string[];
}

function ChartLegend({
  data,
  children,
  title,
  header,
  id,
  val,
  options,
  colors,
}: ChartProps) {
  return (
    <>
      <div className="my-2">
        <p className="text-base font-medium text-center">{title}</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        {children}
      </div>
      <div className="w-full flex items-center justify-between py-2">
        <p className="mr-3 font-medium text-base-black">{header}</p>
        <div>
          <Select id={id} value={val} options={options} onChange={() => {}} />
        </div>
      </div>
      <div className="text-base text-base-black font-normal space-y-4">
        {data.map((row, index, self) => {
          return (
            <div key={row.label}>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <div
                    className="h-3 w-3 rounded-full mr-3"
                    style={{
                      backgroundColor: colors[index % colors.length],
                    }}
                  />
                  <p className="font-medium">{row.label}</p>
                </div>
                <div>
                  <p>
                    {row.formattedVal ? row.formattedVal : row.value.toString()}
                  </p>
                </div>
              </div>
              {index !== self.length - 1 && (
                <hr className="text-base-gray-80 my-1" />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ChartLegend;
