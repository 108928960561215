import React, { useState, useContext } from "react";
import { GridItemSize, GridItemType } from "@rodel-futures-simulator/types";
import {
  AdjustmentsHorizontalIcon,
  WrenchScrewdriverIcon,
  Square3Stack3DIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import Toolbar from "../../../common/components/Toolbar";
import SimulatorContext from "../../context/SimulatorContext";
import ToolsModal from "./ToolsModal";
import { ToolType } from "../../../common/types/types";
import Tooltip from "../../../common/components/Tooltip";

function ToolsControls() {
  const { setBuildItem } = useContext(SimulatorContext);

  const [toolName, setToolName] = useState<ToolType>();

  const handleToolsModalChange = (selectedTool: ToolType) => {
    if (toolName === "Build Mode") {
      setBuildItem({
        state: "form",
        type: GridItemType.SCHOOL,
        size: GridItemSize.QUARTER,
      });
    }

    if (toolName === selectedTool) {
      setToolName(undefined);
    } else {
      setToolName(selectedTool);
    }
  };

  return (
    <>
      <Toolbar className="ml-auto">
        <Tooltip
          icon={<WrenchScrewdriverIcon className="w-4 h-4 m-2" />}
          text="Build Mode"
          isActive={toolName === "Build Mode"}
          onClick={() => {
            handleToolsModalChange("Build Mode");
          }}
        />
        <Tooltip
          icon={<AdjustmentsHorizontalIcon className="w-4 h-4 m-2" />}
          text="Command Center"
          isActive={toolName === "Command Center"}
          onClick={() => {
            handleToolsModalChange("Command Center");
          }}
        />
        <Tooltip
          icon={<Square3Stack3DIcon className="w-4 h-4 m-2" />}
          text="Layers"
          isActive={toolName === "Layers"}
          onClick={() => {
            handleToolsModalChange("Layers");
          }}
        />
        <Tooltip
          icon={<CurrencyDollarIcon className="w-4 h-4 m-2" />}
          text="Interventions"
          isActive={toolName === "Interventions Overview"}
          onClick={() => {
            handleToolsModalChange("Interventions Overview");
          }}
        />
      </Toolbar>
      {toolName && (
        <div className="mr-5 fixed top-[155px] right-0 items-end">
          <ToolsModal
            toolName={toolName}
            onClose={() => {
              setBuildItem({
                state: "form",
                type: GridItemType.SCHOOL,
                size: GridItemSize.QUARTER,
              });
              setToolName(undefined);
            }}
          />
        </div>
      )}
    </>
  );
}

export default ToolsControls;
