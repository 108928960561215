/* eslint-disable react/require-default-props */
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import Button from "../../common/components/Button";

// interface CardProps {
//   title?: string;
//   subtitle?: string;
//   header?: ReactNode;
//   footer?: ReactNode;
//   children?: ReactNode;
// }

// function Card({ title, subtitle, header, footer, children }: CardProps) {
//   return (
//     <div className="bg-base-gray-60 grow min-w-[200px] border-[1px] border-base-gray-70 p-3">
//       <div>{header}</div>
//       <h3>{title}</h3>
//       <p>{subtitle}</p>
//       {children}
//       <div className="flex flex-col text-xs text-gray-500">{footer}</div>
//     </div>
//   );
// }

function SimulationHistory({ simulationId }: { simulationId: string }) {
  const navigate = useNavigate();
  // const cardData = [
  //   {
  //     title: "Tempe Schools Simulation",
  //     subtitle: "Arlene McCoy",
  //     content: "Content for Sim 1",
  //     footer: "Sep 20th, 2023 -- 1 year",
  //   },
  //   {
  //     title: "Perry High School Simulation",
  //     subtitle: "Arlene McCoy",
  //     content: "Content for Sim 2",
  //     footer: "Sep 20th, 2023 -- 1 year",
  //   },
  //   {
  //     title: "Population Analysis Simulation",
  //     subtitle: "Arlene McCoy",
  //     content: "Content for Sim 3",
  //     footer: "Sep 20th, 2023 -- 1 year",
  //   },
  // ];

  return (
    <div className="pt-8 bg-base-gray-50 h-screen">
      <div className="px-8">
        <h1 className="text-[32px] pb-8">Rodel Futures Simulator</h1>
        {/* <h3 className="pb-4 font-medium">Simulation History</h3> */}
      </div>
      {/* <div className="flex flex-col pb-6 m-5">
        {cardData.map((card, index) => (
          <div key={index}>
            <Card
              key={index}
              title={card.title}
              subtitle={card.subtitle}
              footer={card.footer}
            />
            <hr className="py-2" />
            <hr className="py-2" />
          </div>
        ))}
      </div> */}
      <div className="flex flex-col pb-6 mx-5 mb-1">
        In this simulation results page, compare the performance of your
        simulation to our determined baseline. The baseline is an average of
        many simulations that do not intervene in Arizona&apos;s current
        condition.
      </div>
      <div className="flex flex-col pb-6 mx-5 mb-5">
        This page contains three reports: Education, Economic, and Energy.
        Select a report from the dropdown and click a dataset to view the
        graphed results.
      </div>
      <div className="flex justify-center flex-col gap-2 px-6">
        <Button onClick={() => navigate(`/simulation/${simulationId}`)}>
          Back to Simulation
        </Button>
        <Button
          color="secondary"
          className="w-full h-4 mr-2 py-4"
          shadow={false}
          icon={<ArrowLeftOnRectangleIcon className="h-4 w-4" />}
          onClick={() => navigate("/")}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
}
export default SimulationHistory;
