import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ObjectMap } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { GridItemSize, GridItemType } from "@rodel-futures-simulator/types";
import { BufferGeometry, Mesh } from "three";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
import GLTFMaterialsVariantsExtension from "three-gltf-extensions/loaders/KHR_materials_variants/KHR_materials_variants.js";
import { ModelTypes } from "../../common/types/types";
import { translateGLTF } from "./useRenderGridItems/utils/translationUtils";

export type ModelCatalogEntry = {
  [size: string]: {
    // material: Material;
    geometry: BufferGeometry;
    modelTypes: ModelTypes;
    textures: string[];
  };
};

export type ModelCatalog = {
  [type: string]: ModelCatalogEntry;
};

// const material = new MeshStandardMaterial({ color: "gray" });

function addModelToCatalog(
  gltf: GLTF & ObjectMap,
  model: string,
  textures: string[],
  type: GridItemType,
  size: GridItemSize,
  catalog: ModelCatalog
) {
  if (!catalog[type]) {
    // eslint-disable-next-line no-param-reassign
    catalog[type] = {};
  }

  const modelTypes: ModelTypes = {
    meshVariants: [],
    materialVariants: gltf.nodes[model].userData.variantMaterials
      ? ([
          gltf.materials[model],
          // eslint-disable-next-line
          gltf.nodes[model].userData.variantMaterials[`${model}_greyscale`]
            .material,
        ] as THREE.Material[])
      : ([gltf.materials[model]] as THREE.Material[]),
  };

  // eslint-disable-next-line no-param-reassign
  catalog[type][size] = {
    // material: gltf.materials[model] as THREE.MeshStandardMaterial,
    geometry: (gltf.nodes[model] as Mesh).geometry,
    textures,
    modelTypes,
  };
}

const useExtendedGLTF = (modelPath: string) => {
  const model = useGLTF(modelPath, true, true, (loader) => {
    loader.register((parser) => {
      // eslint-disable-next-line
      return new GLTFMaterialsVariantsExtension(parser);
    });
  });
  // eslint-disable-next-line
  const { variants } = model.userData;
  // eslint-disable-next-line
  const variantName = variants[1];
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  model.functions.selectVariant(model.scene, variantName);

  return model;
};

export default function useGridItemModelCatalog(): ModelCatalog {
  // use useExtendedGLTF for reading KHR material variants
  const schoolModel = useExtendedGLTF("/models/School/Sch025LOD1.gltf");
  const schoolModelHalf = useExtendedGLTF("/models/School/Sch050LOD1.gltf");
  const businessModel = useExtendedGLTF("/models/Business/Bus025LOD1LI.gltf");
  const businessModelHalf = useExtendedGLTF(
    "/models/Business/Bus050LOD1LI.gltf"
  );
  const businessModelFull = useExtendedGLTF(
    "/models/Business/Bus100LOD1HI.gltf"
  );
  const neighborhoodModel = useExtendedGLTF(
    "/models/Neighborhood/Nei025LOD1MI.gltf"
  );
  const neighborhoodModelHalf = useExtendedGLTF(
    "/models/Neighborhood/Nei050LOD1LI.gltf"
  );
  const neighborhoodModelFull = useExtendedGLTF(
    "/models/Neighborhood/Nei100LOD1MI.gltf"
  );
  const natureModel = useExtendedGLTF("/models/Nature/Nat025LOD1.gltf");
  const natureModelHalf = useExtendedGLTF("/models/Nature/Nat050LOD1.gltf");
  const powerPlantModel = useExtendedGLTF("/models/Power/Pow025LOD1.gltf");
  const powerPlantModelHalf = useExtendedGLTF("/models/Power/Pow050LOD1.gltf");
  const constructionModel = useExtendedGLTF(
    "/models/Construction/Con025LOD1.gltf"
  );
  const constructionModelHalf = useExtendedGLTF(
    "/models/Construction/Con050LOD1.gltf"
  );
  const constructionModelFull = useExtendedGLTF(
    "/models/Construction/Con100LOD1.gltf"
  );

  translateGLTF(schoolModel, "Sch025LOD1");
  translateGLTF(schoolModelHalf, "Sch050LOD1");
  translateGLTF(businessModel, "Bus025LOD1LI");
  translateGLTF(businessModelHalf, "Bus050LOD1LI");
  translateGLTF(businessModelFull, "Bus100LOD1HI");
  translateGLTF(neighborhoodModel, "Nei025LOD1MI");
  translateGLTF(neighborhoodModelHalf, "Nei050LOD1LI");
  translateGLTF(neighborhoodModelFull, "Nei100LOD1MI");
  translateGLTF(natureModel, "Nat025LOD1");
  translateGLTF(natureModelHalf, "Nat050LOD1");
  translateGLTF(powerPlantModel, "Pow025LOD1");
  translateGLTF(powerPlantModelHalf, "Pow050LOD1");
  translateGLTF(constructionModel, "Con025LOD1");
  translateGLTF(constructionModelHalf, "Con050LOD1");
  translateGLTF(constructionModelFull, "Con100LOD1");

  const catalog: ModelCatalog = {};
  addModelToCatalog(
    schoolModel,
    "Sch025LOD1",
    [
      "/models/School/Sch025LOD4.jpg",
      "/models/School/Sch025LOD4_greyscale.jpg",
    ],
    GridItemType.SCHOOL,
    GridItemSize.QUARTER,
    catalog
  );
  addModelToCatalog(
    schoolModelHalf,
    "Sch050LOD1",
    [
      "/models/School/Sch050LOD4.jpg",
      "/models/School/Sch050LOD4_greyscale.jpg",
    ],
    GridItemType.SCHOOL,
    GridItemSize.HALF,
    catalog
  );
  addModelToCatalog(
    businessModel,
    "Bus025LOD1LI",
    [
      "/models/Business/Bus025LOD4LI.jpg",
      "/models/Business/Bus025LOD4LI_greyscale.jpg",
    ],
    GridItemType.BUSINESS,
    GridItemSize.QUARTER,
    catalog
  );
  addModelToCatalog(
    businessModelHalf,
    "Bus050LOD1LI",
    [
      "/models/Business/Bus050LOD4LI.jpg",
      "/models/Business/Bus050LOD4LI_greyscale.jpg",
    ],
    GridItemType.BUSINESS,
    GridItemSize.HALF,
    catalog
  );
  addModelToCatalog(
    businessModelFull,
    "Bus100LOD1HI",
    [
      "/models/Business/Bus100LOD4LI.jpg",
      "/models/Business/Bus100LOD4LI_greyscale.jpg",
    ],
    GridItemType.BUSINESS,
    GridItemSize.FULL,
    catalog
  );
  addModelToCatalog(
    neighborhoodModel,
    "Nei025LOD1MI",
    [
      "/models/Neighborhood/Nei025LOD4MI.jpg",
      "/models/Neighborhood/Nei025LOD4MI_greyscale.jpg",
    ],
    GridItemType.NEIGHBORHOOD,
    GridItemSize.QUARTER,
    catalog
  );
  addModelToCatalog(
    neighborhoodModelHalf,
    "Nei050LOD1LI",
    [
      "/models/Neighborhood/Nei050LOD4LI.jpg",
      "/models/Neighborhood/Nei050LOD4LI_greyscale.jpg",
    ],
    GridItemType.NEIGHBORHOOD,
    GridItemSize.HALF,
    catalog
  );
  addModelToCatalog(
    neighborhoodModelFull,
    "Nei100LOD1MI",
    [
      "/models/Neighborhood/Nei100LOD4LI.jpg",
      "/models/Neighborhood/Nei100LOD4LI_greyscale.jpg",
    ],
    GridItemType.NEIGHBORHOOD,
    GridItemSize.FULL,
    catalog
  );
  addModelToCatalog(
    powerPlantModel,
    "Pow025LOD1",
    ["/models/Power/Pow025LOD4.jpg", "/models/Power/Pow025LOD4_greyscale.jpg"],
    GridItemType.POWER_PLANT,
    GridItemSize.QUARTER,
    catalog
  );
  addModelToCatalog(
    powerPlantModelHalf,
    "Pow050LOD1",
    ["/models/Power/Pow050LOD4.jpg", "/models/Power/Pow050LOD4_greyscale.jpg"],
    GridItemType.POWER_PLANT,
    GridItemSize.HALF,
    catalog
  );
  addModelToCatalog(
    natureModel,
    "Nat025LOD1",
    [
      "/models/Nature/Nat025LOD4.jpg",
      "/models/Nature/Nat025LOD4_greyscale.jpg",
    ],
    GridItemType.DESERT,
    GridItemSize.QUARTER,
    catalog
  );
  addModelToCatalog(
    natureModelHalf,
    "Nat050LOD1",
    [
      "/models/Nature/Nat050LOD4.jpg",
      "/models/Nature/Nat050LOD4_greyscale.jpg",
    ],
    GridItemType.DESERT,
    GridItemSize.HALF,
    catalog
  );
  // addModelToCatalog(
  //   desertModelHalf,
  //   "DesA050LOD1",
  //   [
  //     "/models/Desert/DesA050LOD4.jpg",
  //     "/models/Desert/DesA050LOD4_greyscale.jpg",
  //   ],
  //   GridItemType.CONSTRUCTION,
  //   GridItemSize.FULL,
  //   catalog
  // );
  addModelToCatalog(
    constructionModel,
    "Con025LOD1",
    [
      "/models/Construction/Con025LOD4.jpg",
      "/models/Construction/Con025LOD4_greyscale.jpg",
    ],
    GridItemType.CONSTRUCTION,
    GridItemSize.QUARTER,
    catalog
  );
  addModelToCatalog(
    constructionModelHalf,
    "Con050LOD1",
    [
      "/models/Construction/Con050LOD4.jpg",
      "/models/Construction/Con050LOD4_greyscale.jpg",
    ],
    GridItemType.CONSTRUCTION,
    GridItemSize.HALF,
    catalog
  );
  addModelToCatalog(
    constructionModelFull,
    "Con100LOD1",
    [
      "/models/Construction/Con100LOD4.jpg",
      "/models/Construction/Con100LOD4_greyscale.jpg",
    ],
    GridItemType.CONSTRUCTION,
    GridItemSize.FULL,
    catalog
  );

  return catalog;
}
