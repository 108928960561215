import { GridItemType, PowerPlant } from "@rodel-futures-simulator/types";
import React, {
  useState,
  useContext,
  FormEventHandler,
  useEffect,
} from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { getPowerType } from "../../utils/getEnum";
import { calcAvailableFunds } from "../../utils/investUtils";
import { formatDollarValue } from "../../../common/utils/formatUtility";
import SimulatorContext from "../../context/SimulatorContext";
import Button from "../../../common/components/Button";
import Input from "../../../common/components/Input";
import FormRow from "../../../common/components/FormRow";
import Select from "../../../common/components/Select";

interface PowerPlantFormProps {
  powerPlant: PowerPlant;
}

interface PowerPlantIntervention extends PowerPlant {
  investment?: number;
  allocation?: string;
}

function PowerPlantInterventionForm({ powerPlant }: PowerPlantFormProps) {
  const { interventions, setInterventions } = useContext(SimulatorContext);

  const [editInvestment, setEditInvestment] = useState<boolean>(true);
  const [powerPlantFormState, setPowerPlantFormState] =
    useState<PowerPlantIntervention>({
      ...powerPlant,
      investment: undefined,
      allocation: undefined,
    });
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const foundInvestment = interventions.investments.find(
      (investment) =>
        investment.xCoord === powerPlant.xCoord &&
        investment.yCoord === powerPlant.yCoord
    );

    if (foundInvestment) {
      setEditInvestment(false);
      setPowerPlantFormState({
        ...powerPlant,
        investment: foundInvestment.amount,
        allocation: foundInvestment.allocation,
      });
    }
  }, [
    interventions.investments,
    powerPlant,
    powerPlant.xCoord,
    powerPlant.yCoord,
  ]);

  const submitAddGridItem: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const buildCost = powerPlantFormState.investment;
    const availableFunds = calcAvailableFunds(interventions);

    if (!buildCost) {
      setMessage("You must invest more than $0.");
    } else if (buildCost <= availableFunds) {
      setInterventions((prevInterventions) => {
        let investmentFound = false;
        const updatedInvestments = prevInterventions.investments.map(
          (investment) => {
            if (
              investment.xCoord === powerPlant.xCoord &&
              investment.yCoord === powerPlant.yCoord
            ) {
              investmentFound = true;
              return {
                type: GridItemType.POWER_PLANT,
                size: powerPlant.size,
                xCoord: powerPlant.xCoord,
                yCoord: powerPlant.yCoord,
                amount: buildCost,
                allocation: powerPlantFormState.allocation
                  ? powerPlantFormState.allocation
                  : "",
              };
            }
            return investment;
          }
        );

        if (!investmentFound) {
          updatedInvestments.push({
            type: GridItemType.POWER_PLANT,
            size: powerPlant.size,
            xCoord: powerPlant.xCoord,
            yCoord: powerPlant.yCoord,
            amount: buildCost,
            allocation: powerPlantFormState.allocation
              ? powerPlantFormState.allocation
              : "",
          });
        }

        return {
          ...prevInterventions,
          investments: updatedInvestments,
        };
      });
    } else {
      setMessage("You do not have enough funds to make this investment");
    }
  };
  return (
    <div>
      {powerPlantFormState.investment && !editInvestment ? (
        <div>
          <div className="flex justify-end">
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setEditInvestment(true);
              }}
              icon={
                <PencilIcon className="h-3 w-3 mr-0.5 mt-0.5 text-blue-500" />
              }
            >
              <div className="text-xs text-blue-500">Edit</div>
            </Button>
          </div>
          <div>
            You have invested{" "}
            <span className="font-bold text-base-rodel-100">
              {formatDollarValue(powerPlantFormState.investment)}
            </span>{" "}
            in this business area
          </div>
        </div>
      ) : (
        <>
          <form onSubmit={submitAddGridItem}>
            <div className="flex flex-col gap">
              <FormRow label="Investment ($)">
                <Input
                  id="investment"
                  type="number"
                  min={0}
                  step="any"
                  name="investment"
                  value={powerPlantFormState.investment ?? 0}
                  onChange={(event) => {
                    const investment = parseFloat(event.target.value);
                    setPowerPlantFormState({
                      ...powerPlantFormState,
                      investment,
                    });
                  }}
                />
              </FormRow>
              <FormRow label="Power Type">
                <Select
                  id="powerType"
                  value={{
                    value: powerPlantFormState.powerType,
                    label: powerPlantFormState.powerType,
                  }}
                  options={[
                    { value: "Battery Storage", label: "Battery Storage" },
                    { value: "Pumped Storage", label: "Pumped Storage" },
                    { value: "Wind", label: "Wind" },
                    { value: "Solar", label: "Solar" },
                    { value: "Hydroelectric", label: "Hydroelectric" },
                    { value: "Biomass", label: "Biomass" },
                    { value: "Petroleum", label: "Petroleum" },
                    { value: "Natural Gas", label: "Natural Gas" },
                    { value: "Coal", label: "Coal" },
                    { value: "Nuclear", label: "Nuclear" },
                    { value: "Reservoir", label: "Reservoir" },
                  ]}
                  onChange={(option) => {
                    const powerType = getPowerType(option.value);
                    setPowerPlantFormState({
                      ...powerPlantFormState,
                      powerType,
                    });
                  }}
                />
              </FormRow>
              <FormRow label="Allocation">
                <Select
                  value={
                    powerPlantFormState.allocation
                      ? {
                          value: powerPlantFormState.allocation,
                          label: powerPlantFormState.allocation,
                        }
                      : null
                  }
                  options={[
                    { value: "Renewable Energy", label: "Renewable Energy" },
                    { value: "Infrastructure", label: "Infrastructure" },
                  ]}
                  onChange={(option) => {
                    setMessage("");
                    setPowerPlantFormState({
                      ...powerPlantFormState,
                      allocation: option.value,
                    });
                  }}
                />
              </FormRow>
              <Button type="submit">Submit</Button>
            </div>
          </form>
          {message && <div className="text-base-red-100 mt-3">{message}</div>}
        </>
      )}
    </div>
  );
}

export default PowerPlantInterventionForm;
